import { PoItemModel, PoResponseItemModel } from "../../model/po.model";
import { Types } from "../actions/pos.action";

interface PoState {
  isPoActionLoading: boolean;
  message?: string;
  allPOs: PoResponseItemModel;
  po: PoItemModel;
}

interface PoAction {
  type: string;
  message?: string;
  allPOs: PoResponseItemModel;
  po: PoItemModel;
}

const INITIAL_STATE: PoState = {
  isPoActionLoading: false,
  allPOs: null as any,
  po: null as any,
};

export const poReducer = (state = INITIAL_STATE, action: PoAction) => {
  switch (action.type) {
    case Types.CREATE_PO_START:
    case Types.FETCH_PO_START:
    case Types.CONFIRM_PO_START:
    case Types.FETCH_POS_START:
      return { ...state, isPoActionLoading: true };
    case Types.CREATE_PO_SUCCESS:
    case Types.CONFIRM_PO_SUCCESS:
      return {
        ...state,
        isPoActionLoading: false,
      };
    case Types.FETCH_PO_SUCCESS:
      return {
        ...state,
        isPoActionLoading: false,
        po: action.po,
      };
    case Types.FETCH_POS_SUCCESS:
      return {
        ...state,
        isPoActionLoading: false,
        allPOs: action.allPOs,
      };
    case Types.CREATE_PO_END:
    case Types.FETCH_PO_END:
    case Types.CONFIRM_PO_END:
    case Types.FETCH_POS_END:
      return {
        ...state,
        isPoActionLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};
