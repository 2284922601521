import React, { FC } from "react";
import "../../styles.scss";
import { IAddSupplierDetails } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import QuicstockSupplier from "../../../../assets/imgs/quicstock-supplier.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const AddSupplierDetails: FC<IAddSupplierDetails> = ({
  supplier,
  goBack,
  complete,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.suppliers.isSupplierActionLoading
  );
  return (
    <div className="w-full">
      <div className="w-full drawer-content-height">
        <div className="flex flex-col justify-center items-center">
          <div className="mb-6">
            <img
              src={QuicstockSupplier}
              alt={"QuicstockSupplier"}
              className={"h-[150px]"}
            />
          </div>
          <SupplierDetails label={"Supplier’s name"} value={supplier?.name} />
          <SupplierDetails
            label={"Supplier’s phone"}
            value={supplier?.phonenumber}
          />
          <SupplierDetails label={"Supplier’s email"} value={supplier?.email} />
          <SupplierDetails
            label={"Supplier’s location"}
            value={supplier?.address}
          />
          <SupplierDetails label={"Supplier’s city"} value={supplier?.city} />
          <SupplierDetails label={"Supplier’s state"} value={supplier?.state} />
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={goBack}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px] !px-0"}
              state={ButtonState.PRIMARY}
              text={"Save supplier"}
              type={"button"}
              onClick={complete}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SupplierDetails: FC<{ label: string; value?: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex justify-between w-full mb-6">
      <div className="w-1/2 text-left text-gray-300">{label}</div>
      <div className="w-1/2 text-right">{value || "-"}</div>
    </div>
  );
};

export { AddSupplierDetails };
