import React, { FC } from "react";
import "../../styles.scss";
import { IViewSupplier } from "../../model";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import QuicstockDash from "../../../../assets/imgs/quicstock-dash.png";

const SupplierItems: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="mt-5 grid grid-cols-11">
      <div className="sm:col-span-3 col-span-4 sm:text-sm-headline text-sm-regular text-gray-300">
        {label}
      </div>
      <div className="sm:col-span-4 col-span-2 flex items-center">
        <div className="w-full">
          <img src={QuicstockDash} alt={"QuicstockDash"} className={"w-full"} />
        </div>
      </div>
      <div className="sm:col-span-4 col-span-5 text-right sm:text-sm-headline text-sm-regular">
        {value}
      </div>
    </div>
  );
};

const ViewSupplier: FC<IViewSupplier> = ({ cancel, edit, supplier }) => {
  return (
    <div className="w-full">
      <div className="w-full drawer-content-height-no-step pt-10">
        <SupplierItems
          label={"Supplier’s name"}
          value={supplier?.name ?? "---"}
        />
        <SupplierItems
          label={"Supplier’s phone"}
          value={supplier?.phonenumber ?? "---"}
        />
        <SupplierItems
          label={"Supplier’s email"}
          value={supplier?.email ?? "---"}
        />
        <SupplierItems
          label={"Supplier’s address"}
          value={supplier?.address ?? "---"}
        />
        <SupplierItems
          label={"Supplier’s city"}
          value={supplier?.city ?? "---"}
        />
        <SupplierItems
          label={"Supplier’s state"}
          value={supplier?.state ?? "---"}
        />
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Cancel"}
              type={"button"}
              onClick={cancel}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px]"}
              state={ButtonState.PRIMARY}
              text={"Edit details"}
              type={"button"}
              onClick={edit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewSupplier };
