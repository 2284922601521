import React, { FC } from "react";
import "../styles.scss";
import EyeBlock from "../../assets/imgs/eye-block.png";
import EyeSee from "../../assets/imgs/eye-see.png";
import { IPasswordField } from "../model";

const PasswordField: FC<IPasswordField> = ({
  icon,
  children,
  showPassword = false,
  handleShowPassword,
}) => {
  return (
    <div className={`w-full relative ${icon ? "auth-input" : ""}`}>
      {icon ? <img src={icon} alt={"Pre"} /> : <></>}
      {children}
      <img
        src={showPassword ? EyeSee : EyeBlock}
        alt={"Post"}
        className={"pass-eye"}
        onClick={() => handleShowPassword && handleShowPassword(!showPassword)}
      />
    </div>
  );
};

export default PasswordField;
