import React, { ChangeEvent, FC, useState } from "react";
import "../../styles.scss";
import { ILocationPurchaseOrders } from "../../model";
import { LocationPurchaseOrder } from "./LocationPurchaseOrder";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { Form, Input } from "antd";
import QuicstockSearch from "../../../../assets/svgs/quicstock-search.svg";

const LocationPurchaseOrders: FC<ILocationPurchaseOrders> = ({ orderNo }) => {
  const [search] = useState<string>("");
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e);
  };

  return (
    <div className="w-full mt-16">
      <div className="search-field !w-full">
        <FieldContainer icon={QuicstockSearch} iconPlacementCss={"location-purchase-orders-search"}>
          <Form.Item name={"search"} className="page-header">
            <Input
              type={"email"}
              className={"form-field_input_2 w-full"}
              value={search || ""}
              placeholder={"Search"}
              onChange={handleSearchChange}
            />
          </Form.Item>
        </FieldContainer>
      </div>
      <div className="w-full flex flex-col gap-y-4 mt-4">
        <LocationPurchaseOrder
          orderNo={orderNo}
          dateCompleted={"12/02/24"}
          hideCheck={true}
        />
        <LocationPurchaseOrder
          orderNo={orderNo}
          dateCompleted={"12/02/24"}
          hideCheck={true}
        />
        <LocationPurchaseOrder
          orderNo={orderNo}
          dateCompleted={"12/02/24"}
          hideCheck={true}
        />
        <LocationPurchaseOrder
          orderNo={orderNo}
          dateCompleted={"12/02/24"}
          hideCheck={true}
        />
        <LocationPurchaseOrder
          orderNo={orderNo}
          dateCompleted={"12/02/24"}
          hideCheck={true}
        />
        <LocationPurchaseOrder
          orderNo={orderNo}
          dateCompleted={"12/02/24"}
          hideCheck={true}
        />
        <LocationPurchaseOrder
          orderNo={orderNo}
          dateCompleted={"12/02/24"}
          hideCheck={true}
        />
      </div>
    </div>
  );
};

export { LocationPurchaseOrders };
