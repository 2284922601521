import {
  SupplierItemModel,
  SupplierResponseItemModel,
} from "../../model/supplier.model";
import { Types } from "../actions/suppliers.action";

interface SupplierState {
  isSupplierActionLoading: boolean;
  message?: string;
  suppliers: SupplierResponseItemModel;
  supplierSearchResult: SupplierItemModel[];
}

interface SupplierAction {
  type: string;
  message?: string;
  suppliers: SupplierResponseItemModel;
  supplierSearchResult: SupplierItemModel[];
}

const INITIAL_STATE: SupplierState = {
  isSupplierActionLoading: false,
  suppliers: null as any,
  supplierSearchResult: [],
};

export const supplierReducer = (
  state = INITIAL_STATE,
  action: SupplierAction
) => {
  switch (action.type) {
    case Types.CREATE_SUPPLIER_START:
    case Types.FETCH_SUPPLIERS_START:
    case Types.UPDATE_SUPPLIER_START:
    case Types.DELETE_SUPPLIERS_START:
    case Types.SEARCH_SUPPLIERS_START:
      return { ...state, isSupplierActionLoading: true };
    case Types.CREATE_SUPPLIER_SUCCESS:
    case Types.UPDATE_SUPPLIER_SUCCESS:
    case Types.DELETE_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isSupplierActionLoading: false,
      };
    case Types.FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isSupplierActionLoading: false,
        suppliers: action.suppliers,
      };
    case Types.SEARCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isSupplierActionLoading: false,
        supplierSearchResult: action.supplierSearchResult ?? [],
      };
    case Types.CREATE_SUPPLIER_END:
    case Types.FETCH_SUPPLIERS_END:
    case Types.UPDATE_SUPPLIER_END:
    case Types.DELETE_SUPPLIERS_END:
    case Types.SEARCH_SUPPLIERS_END:
      return {
        ...state,
        isSupplierActionLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};
