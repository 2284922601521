import React, { FC } from "react";
import "../../styles.scss";
import { IConfirmProduct } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { ConfirmProductDetails, ConfirmProductForm } from ".";

const ConfirmProduct: FC<IConfirmProduct> = ({
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
  formValues,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Transfer"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <ConfirmProductForm submit={submit} cancel={cancel} />
        </div>
        <div hidden={activeStep === 0}>
          <ConfirmProductDetails goBack={goBack} complete={complete} formValues={formValues} />
        </div>
      </div>
    </div>
  );
};

export { ConfirmProduct };
