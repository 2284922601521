/* eslint-disable @typescript-eslint/no-unused-vars */
import { Popover, Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllUsers, IUser } from "../../model";
import { USERS } from "../../../../utils/misc/database";
import { itemRender, trimAll } from "../../../../utils/constants";
import TableActions from "../../../../components/TableActions";
import { ReactComponent as QuicstockMore } from "../../../../assets/svgs/quicstock-more.svg";
import { ReactComponent as QuicstockEye } from "../../../../assets/svgs/quicstock-eye.svg";
import { ReactComponent as QuicstockUploadDoc } from "../../../../assets/svgs/quicstock-upload-doc.svg";
import { ReactComponent as QuicstockTrash } from "../../../../assets/svgs/quicstock-trash.svg";
import { ReactComponent as QuicstockUserRemove } from "../../../../assets/svgs/quicstock-user-remove.svg";

const users: IUser[] = USERS;
const ACTION_STYLE =
  "text-sm-regular mb-4 p-2 gap-x-3 flex items-center cursor-pointer";

const AllUsers: FC<IAllUsers> = ({
  viewUser,
  updateUser,
  disableUser,
  deleteUser,
}) => {
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [id, setId] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | undefined>(undefined);

  const handleOpenChange = (newOpen: boolean, record: any) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setId(record?.id);
    setUser({
      ...record?.user,
    } as any);
  };

  const handleMore = (option: "VIEW" | "UPDATE" | "DISABLE" | "DELETE") => {
    if (option === "DELETE") {
      setDeleteConfirmation(!deleteConfirmation);
    } else {
      if (option === "VIEW" && viewUser) {
        viewUser(user as any);
      }
      if (option === "UPDATE" && updateUser) {
        updateUser(user as any);
      }
      if (option === "DISABLE" && disableUser) {
        disableUser(user as any);
      }
      setOpen(false);
    }
  };

  const handleSingleDelete = (e: any) => {
    e.stopPropagation();
    setDeleteConfirmation(false);
    setOpen(false);
    deleteUser && deleteUser(id);
  };

  const columns = [
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Staff ID",
      dataIndex: "staffId",
      key: "staffId",
    },
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: <QuicstockMore />,
      key: "actions",
      render: (record: any) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <QuicstockMore />
          </div>
        </Popover>
      ),
    },
  ];

  const content = (
    <div className="w-[210px] table-menu">
      <div className={ACTION_STYLE} onClick={() => handleMore("VIEW")}>
        <QuicstockEye />
        View more
      </div>
      <div className={ACTION_STYLE} onClick={() => handleMore("UPDATE")}>
        <QuicstockUploadDoc />
        Update
      </div>
      <div className={ACTION_STYLE} onClick={() => handleMore("DISABLE")}>
        <QuicstockUserRemove />
        {user?.status === "Active" ? "Disable" : "Enable"}
      </div>
      <div
        className={`${ACTION_STYLE} !mb-0 ${deleteConfirmation ? "delete-confirmation" : "delete-no-confirmation"}`}
        onClick={() => handleMore("DELETE")}
      >
        <div className="first">
          <QuicstockTrash />
        </div>
        Delete
        <div className="last" onClick={handleSingleDelete}>
          <QuicstockTrash />
        </div>
      </div>
    </div>
  );

  const data =
    users &&
    users?.length > 0 &&
    users?.map((user, index) => {
      return {
        key: index,
        name: user.name ?? "---",
        staffId: user?.staffId ?? "---",
        mobileNumber: user?.mobileNumber ?? "---",
        status: user?.status ? (
          <div className={`status ${trimAll(user?.status.toLowerCase())}`}>
            {user?.status}
          </div>
        ) : (
          "---"
        ),
        role: user?.role ?? "---",
        id: user?.id,
        user: user,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {};

  const paginationChangeHandler = (current: number, pageSize: number) => {};

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const handleExport = () => {};
  const handleFilter = () => {};
  const handleDelete = () => {};

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDelete={handleDelete}
      />
      <div className="w-full bg-white extra-filter-table pb-4 overflow-hidden mt-10">
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table add-pointer"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40"],
            showSizeChanger: false,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: users?.length,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              `Showing 1 to ${defaultCurrent * defaultPageSize} of ${total} entries`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                users?.length,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { AllUsers };
