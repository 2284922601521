import React from "react";
import "../styles.scss";

const Sales = () => {
  return (
    <div className="w-full h-full">
      <div className="w-full flex justify-between gap-x-8">Sales</div>
    </div>
  );
};

export default Sales;
