import React, { FC } from "react";
import "../../styles.scss";
import { IProductOrder } from "../../model";

const ProductOrder: FC<IProductOrder> = ({ orderNo }) => {
  return (
    <div className="text-primary-500 bg-primary-200 rounded-[32px] py-1 px-3 text-sm-regular font-medium mb-2">
      {orderNo}
    </div>
  );
};

export { ProductOrder };
