import { IUser, IUserLog } from "../../views/Main/model";

export const USERS: IUser[] = [
  {
    id: 1,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 2,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 3,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 4,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 5,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 6,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 7,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 8,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 9,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 10,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 11,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 12,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 13,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 14,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 15,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 16,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 17,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 18,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: 19,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 20,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 21,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Inactive",
    role: "User",
  },
  {
    id: 22,
    name: "Petra of laylay",
    staffId: "#23455666",
    mobileNumber: "08103035840",
    status: "Active",
    role: "User",
  },
];

export const USERS_LOGS: IUserLog[] = [
  {
    id: 1,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 2,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 3,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 4,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 5,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 6,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 7,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 8,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 9,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 10,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 11,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 12,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 13,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 14,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 15,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 16,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 17,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 18,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 19,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 20,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
];
