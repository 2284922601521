import React, { FC, useState } from "react";
import "../../styles.scss";
import { IConfirmProductDetails } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import QuicstockDash from "../../../../assets/imgs/quicstock-dash.png";
import { Checkbox, CheckboxProps } from "antd";
import { RootState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";

const PoLocation: FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <div className="mb-2 grid grid-cols-12 items-center">
      <div className="col-span-5 text-sm-regular text-gray-300">{label}</div>
      <div className="col-span-5 flex items-center">
        <div className="w-full">
          <img src={QuicstockDash} alt={"QuicstockDash"} className={"w-full"} />
        </div>
      </div>
      <div className="col-span-2 text-right text-sm-regular">{value}</div>
    </div>
  );
};

const ConfirmProductDetails: FC<IConfirmProductDetails> = ({
  goBack,
  complete,
  formValues,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.pos.isPoActionLoading
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <div className="w-full">
      <div className="drawer-content-height">
        <div className="po-confirm-details">
          <div className="px-10 mt-5">
            {formValues?.products?.map((pp) => (
              <div
                key={pp?.product?.product_id}
                className="border-[0.5px] border-dark-500 pt-8 pb-6 pl-8 pr-12 rounded-[10px] relative mb-8"
              >
                <div className="flex items-center gap-x-8 absolute top-0 left-0 transform -translate-y-1/2 z-20 bg-white pr-2">
                  <div className="flex items-center gap-x-2">
                    <div className="h-[30px] w-[30px] rounded-full bg-gray-800"></div>
                    <p>Cocacola</p>
                  </div>
                  <p>
                    {pp?.product?.quantity_expected -
                      pp?.product?.quantity_received}
                  </p>
                </div>
                {pp?.fields?.map((ff) => (
                  <PoLocation
                    key={ff?.location_id}
                    label={ff?.location_name}
                    value={ff?.quantity}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="po-addition-info w-full">
          <div className="w-full flex justify-between items-center">
            <div>
              <p className="text-sm-headline">
                Are you ready to close this Purchase order?
              </p>
              <p className="text-sm-regular mt-1">
                Once closed, you{" "}
                <span className="font-semibold text-error-dark">cannot</span>{" "}
                open it again.
              </p>
            </div>
            <div className="po-confirm-details-check">
              <Checkbox onChange={onChange}></Checkbox>
            </div>
          </div>
        </div>
        <div className="supplier-note mt-6">
          <p className="text-sm-headline">Note to supplier</p>
          <div className="w-full px-2 pt-2 pb-6 bg-gray-50 mt-2 rounded-[10px]">
            <p>{formValues?.additionalInformation ?? "---"}</p>
          </div>
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid grid-cols-3 gap-x-2"}>
          <div className={"col-1"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={goBack}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !px-0 !rounded-[6px]"}
              state={ButtonState.PRIMARY}
              text={"Confirm POs"}
              type={"button"}
              onClick={complete}
              disabled={!isChecked}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ConfirmProductDetails };
