import React, { FC } from "react";
import "../../styles.scss";
import { IUploadProducts } from "../../model";
import { DraggableUpload } from "../../../../components/DraggableUpload";

const UploadProducts: FC<IUploadProducts> = ({ submit }) => {
  return (
    <div className="w-full">
      <DraggableUpload
        maxSizeText="1 MB"
        maxSize={1000}
        supports={["csv"]}
        onUpload={submit}
      />
    </div>
  );
};

export { UploadProducts };
