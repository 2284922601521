import { Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllProducts } from "../../model";
import { itemRender, trimAll } from "../../../../utils/constants";
import TableActions from "../../../../components/TableActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const AllProducts: FC<IAllProducts> = ({
  viewProduct,
  handleImport,
  paginationData,
  setPaginationData,
  setSelectedItems,
  selectedItems,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const products = useSelector((state: RootState) => state.products.products);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const columns = [
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Retail price",
      dataIndex: "retail_price",
      key: "retail_price",
    },
    {
      title: "Quantity",
      dataIndex: "total_quantity",
      key: "total_quantity",
    },
  ];

  const data =
    products &&
    products.products &&
    products.products?.length > 0 &&
    products.products?.map((product, index) => {
      return {
        key: index,
        name: product.name ?? "---",
        upc: product?.upc ?? "---",
        brand: product?.brand ?? "---",
        weight: product?.weight ?? "---",
        status: product?.status ? (
          <div className={`status ${trimAll(product?.status.toLowerCase())}`}>
            {product?.status}
          </div>
        ) : (
          "---"
        ),
        retail_price: product?.retail_price ?? "---",
        total_quantity: product?.total_quantity ?? "---",
        id: product?.id,
        product: product,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      itemsPerPage: pageSize,
      page: current,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedItems(selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleExport = () => {};
  const handleFilter = () => {};
  const handleDelete = () => {};

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleImport={handleImport}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDelete={handleDelete}
        disableDelete={selectedItems.length === 0}
      />
      <div className="w-full bg-white filter-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table add-pointer"}
          onRow={(item) => {
            return {
              onClick: () => {
                viewProduct(item?.product);
              },
            };
          }}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: products?.total_count,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              `Showing 1 to ${defaultCurrent * defaultPageSize} of ${total} entries`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                products?.total_count,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { AllProducts };
