import React, { FC } from "react";
import "../../styles.scss";
import { IEditLocation } from "../../model";
import { LocationForm } from "./LocationForm";
import { LocationDetails } from "./LocationDetails";
import AppStepper from "../../../../components/AppStepper";

const EditLocation: FC<IEditLocation> = ({
  submit,
  goBack,
  complete,
  cancel,
  state,
  activeStep,
  location,
  type,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Update location"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <LocationForm
            submit={submit}
            cancel={cancel}
            location={location}
            type={type}
          />
        </div>
        <div hidden={activeStep === 0}>
          <LocationDetails
            location={location}
            state={state}
            goBack={goBack}
            complete={complete}
            type={type}
          />
        </div>
      </div>
    </div>
  );
};

export { EditLocation };
