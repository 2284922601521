import React, { FC } from "react";
import "../../styles.scss";
import { ITransferLocationProduct } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { TransferLocationProductDetails, TransferLocationProductForm } from ".";

const TransferLocationProduct: FC<ITransferLocationProduct> = ({
  submit,
  goBack,
  complete,
  cancel,
  type,
  activeStep,
  product,
  location_id,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Transfer"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <TransferLocationProductForm
            submit={submit}
            cancel={cancel}
            type={type}
            product={product}
            location_id={location_id}
          />
        </div>
        <div hidden={activeStep === 0}>
          <TransferLocationProductDetails
            product={product}
            goBack={goBack}
            complete={complete}
            type={type}
          />
        </div>
      </div>
    </div>
  );
};

export { TransferLocationProduct };
