import { ThunkAction } from "redux-thunk";
import {
  bulkProductUpload,
  createCustomProperty,
  createProduct,
  deleteCustomProperty,
  getCustomProperties,
  getProduct,
  getProductPOs,
  getProducts,
  getRestockLocations,
  searchProducts,
  setRetailPrice,
  updateCustomProperty,
} from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import { ApiPaginationModel } from "../../model/base.model";
import {
  CustomPropertyModel,
  DeleteCustomPropertyModel,
  SellingRetailPriceRequestModel,
} from "../../model/product.model";

export const Types = {
  CREATE_PRODUCT_START: "CREATE_PRODUCT_START",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_END: "CREATE_PRODUCT_END",

  BULK_PRODUCT_UPLOAD_START: "BULK_PRODUCT_UPLOAD_START",
  BULK_PRODUCT_UPLOAD_SUCCESS: "BULK_PRODUCT_UPLOAD_SUCCESS",
  BULK_PRODUCT_UPLOAD_END: "BULK_PRODUCT_UPLOAD_END",

  FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_END: "FETCH_PRODUCTS_END",

  SEARCH_PRODUCTS_START: "SEARCH_PRODUCTS_START",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_END: "SEARCH_PRODUCTS_END",

  FETCH_PRODUCT_START: "FETCH_PRODUCT_START",
  FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
  FETCH_PRODUCT_END: "FETCH_PRODUCT_END",

  SET_PRICE_START: "SET_PRICE_START",
  SET_PRICE_SUCCESS: "SET_PRICE_SUCCESS",
  SET_PRICE_END: "SET_PRICE_END",

  CREATE_CUSTOM_PROPERTY_START: "CREATE_CUSTOM_PROPERTY_START",
  CREATE_CUSTOM_PROPERTY_SUCCESS: "CREATE_CUSTOM_PROPERTY_SUCCESS",
  CREATE_CUSTOM_PROPERTY_END: "CREATE_CUSTOM_PROPERTY_END",

  FETCH_CUSTOM_PROPERTIES_START: "FETCH_CUSTOM_PROPERTIES_START",
  FETCH_CUSTOM_PROPERTIES_SUCCESS: "FETCH_CUSTOM_PROPERTIES_SUCCESS",
  FETCH_CUSTOM_PROPERTIES_END: "FETCH_CUSTOM_PROPERTIES_END",

  UPDATE_CUSTOM_PROPERTY_START: "UPDATE_CUSTOM_PROPERTY_START",
  UPDATE_CUSTOM_PROPERTY_SUCCESS: "UPDATE_CUSTOM_PROPERTY_SUCCESS",
  UPDATE_CUSTOM_PROPERTY_END: "UPDATE_CUSTOM_PROPERTY_END",

  DELETE_CUSTOM_PROPERTY_START: "DELETE_CUSTOM_PROPERTY_START",
  DELETE_CUSTOM_PROPERTY_SUCCESS: "DELETE_CUSTOM_PROPERTY_SUCCESS",
  DELETE_CUSTOM_PROPERTY_END: "DELETE_CUSTOM_PROPERTY_END",

  FETCH_PRODUCT_POS_START: "FETCH_PRODUCT_POS_START",
  FETCH_PRODUCT_POS_SUCCESS: "FETCH_PRODUCT_POS_SUCCESS",
  FETCH_PRODUCT_POS_END: "FETCH_PRODUCT_POS_END",

  FETCH_RESTOCK_LOCATIONS_START: "FETCH_RESTOCK_LOCATIONS_START",
  FETCH_RESTOCK_LOCATIONS_SUCCESS: "FETCH_RESTOCK_LOCATIONS_SUCCESS",
  FETCH_RESTOCK_LOCATIONS_END: "FETCH_RESTOCK_LOCATIONS_END",
};

export const CreateProduct = (
  formData: any
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_PRODUCT_START });
    return new Promise((resolve, reject) => {
      createProduct(formData)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_PRODUCT_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_PRODUCT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const BulkProductUpload = (
  formData: any
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.BULK_PRODUCT_UPLOAD_START });
    return new Promise((resolve, reject) => {
      bulkProductUpload(formData)
        .then(({ data }) => {
          dispatch({ type: Types.BULK_PRODUCT_UPLOAD_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.BULK_PRODUCT_UPLOAD_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchProducts = (
  params: ApiPaginationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PRODUCTS_START });
    return new Promise((resolve, reject) => {
      getProducts(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PRODUCTS_SUCCESS,
            products: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PRODUCTS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const SearchProducts = (
  searchText: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.SEARCH_PRODUCTS_START });
    return new Promise((resolve, reject) => {
      searchProducts(searchText)
        .then(({ data }) => {
          dispatch({
            type: Types.SEARCH_PRODUCTS_SUCCESS,
            productSearchResult: data?.data?.products ?? [],
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.SEARCH_PRODUCTS_END,
            message: response?.data?.message || "An error occured",
          });
        });
    });
  };
};

export const FetchProduct = (
  id: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PRODUCT_START });
    return new Promise((resolve, reject) => {
      getProduct(id)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_SUCCESS,
            product: data?.data?.product_details,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const SetRetailPrice = (
  id: string,
  req: SellingRetailPriceRequestModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.SET_PRICE_START });
    return new Promise((resolve, reject) => {
      setRetailPrice(id, req)
        .then(({ data }) => {
          dispatch({
            type: Types.SET_PRICE_SUCCESS,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.SET_PRICE_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const CreateCustomProperty = (
  productId: string,
  req: CustomPropertyModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_CUSTOM_PROPERTY_START });
    return new Promise((resolve, reject) => {
      createCustomProperty(productId, req)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_CUSTOM_PROPERTY_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_CUSTOM_PROPERTY_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchCustomProperties = (): ThunkAction<
  Promise<any>,
  RootState,
  unknown,
  Action
> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_CUSTOM_PROPERTIES_START });
    return new Promise((resolve, reject) => {
      getCustomProperties()
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_CUSTOM_PROPERTIES_SUCCESS,
            cp: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_CUSTOM_PROPERTIES_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const UpdateCustomProperty = (
  productId: string,
  req: CustomPropertyModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.UPDATE_CUSTOM_PROPERTY_START });
    return new Promise((resolve, reject) => {
      updateCustomProperty(productId, req)
        .then(({ data }) => {
          dispatch({ type: Types.UPDATE_CUSTOM_PROPERTY_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.UPDATE_CUSTOM_PROPERTY_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const DeleteCustomProperty = (
  productId: string,
  req: DeleteCustomPropertyModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_CUSTOM_PROPERTY_START });
    return new Promise((resolve, reject) => {
      deleteCustomProperty(productId, req)
        .then(({ data }) => {
          dispatch({ type: Types.DELETE_CUSTOM_PROPERTY_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DELETE_CUSTOM_PROPERTY_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchProductPOs = (
  productId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PRODUCT_POS_START });
    return new Promise((resolve, reject) => {
      getProductPOs(productId)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_POS_SUCCESS,
            productPos: data.data?.pos,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PRODUCT_POS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchRestockLocations = (
  productId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_RESTOCK_LOCATIONS_START });
    return new Promise((resolve, reject) => {
      getRestockLocations(productId)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_LOCATIONS_SUCCESS,
            restockLocations: data.data?.locations,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_LOCATIONS_END,
            message: response?.data?.message || "An error occured",
          });
        });
    });
  };
};
