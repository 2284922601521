import React from "react";
import "../styles.scss";
import { Outlet } from "react-router-dom";

const LocationManagement = () => {
  return (
    <div className="w-full h-full">
      <Outlet />
    </div>
  );
};

export default LocationManagement;
