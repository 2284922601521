import React, { FC } from "react";
import "../styles.scss";
import { IFieldName } from "../model";

const FieldName: FC<IFieldName> = ({ name, required }) => {
  return (
    <div className={"text-left text-gray-300 text-sm-regular mb-2"}>
      <p>
        {name} {required && <span className="text-secondary-500">**</span>}
      </p>
    </div>
  );
};

export default FieldName;
