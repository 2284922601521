import React, { FC } from "react";
import "../styles.scss";
import { IAuthHeading } from "../model";

const AuthHeading: FC<IAuthHeading> = ({ children, subHeading }) => {
  return (
    <>
      <div className="w-full text-left">
        <p className="text-[28px] font-medium text-white">{subHeading}</p>
        {children}
      </div>
    </>
  );
};

export { AuthHeading };
