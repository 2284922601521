import React, { useState } from "react";
import "../styles.scss";
import Back from "../../../components/Back";
import { AddCustomPropertyField, AddCustomPropertyProduct } from "./components";
import { ICustomPropertyProduct } from "../model";
import {
  CustomPropertyModel,
  ProductResponseModel,
} from "../../../model/product.model";
import moment from "moment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { CreateCustomProperty } from "../../../redux/actions/products.action";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";

const NewCustomProperty = () => {
  const dispatch: AppDispatch = useDispatch();
  const [propertyProductItem, setPropertyProductItem] =
    useState<ICustomPropertyProduct>({
      productId: "",
      isGlobal: false,
      poIds: [],
    });

  const handleSubmit = (values: CustomPropertyModel) => {
    const payload: CustomPropertyModel = {
      is_global: propertyProductItem.isGlobal,
      property_name: values.property_name,
      property_type: values.property_type,
      property_value:
        values.property_value === "date"
          ? moment(values.property_value).format("YYYY-MM-DD")
          : values.property_value,
      po_numbers: propertyProductItem.isGlobal ? [] : propertyProductItem.poIds,
    };
    createCustomProperty(payload);
  };

  const createCustomProperty = (values: CustomPropertyModel) => {
    dispatch(CreateCustomProperty(propertyProductItem.productId, values))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New custom property added"
            message={res?.message}
          />,
          true
        );
        window.history.back();
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  return (
    <div className="w-full h-full">
      <div className="flex w-full gap-x-4">
        <Back isPrevious={true} />
        <div>
          <p className="text-x-sm-headline font-medium">
            Create custom property
          </p>
          <p className="text-sm-regular text-gray-300">
            Fill the details below to create a new custom property
          </p>
        </div>
      </div>
      <div className="default-page-height w-full xl:pt-20 pt-10">
        <div className="grid sm:grid-cols-2 grid-cols-1 h-full">
          <div className="xl:px-10 sm:pr-10 border-r border-r-dark-500 h-full">
            <AddCustomPropertyProduct setItem={setPropertyProductItem} />
          </div>
          <div className="xl:px-10 sm:pl-10 h-full">
            <AddCustomPropertyField
              submit={handleSubmit}
              propertyProductItem={propertyProductItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCustomProperty;
