import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ITransferLocationProductForm, ILocationProduct } from "../../model";
import { Field, Formik } from "formik";
import { Form, Input, Collapse, CollapseProps, AutoComplete, Spin } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import { RightOutlined } from "@ant-design/icons";
import { LocationPurchaseOrder } from "./LocationPurchaseOrder";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ApiDropdownModel } from "../../../../model/base.model";
import { RootState } from "../../../../redux/reducers";
import { SearchLocations } from "../../../../redux/actions/locations.action";
import { debounce } from "lodash";

const TransferLocationProductForm: FC<ITransferLocationProductForm> = ({
  submit,
  cancel,
  type,
  product,
  location_id,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [values] = useState<ILocationProduct>({
    total_quantity: "",
    location: null,
    location_id: "",
  });
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const [form] = Form.useForm();
  const isLoading = useSelector(
    (state: RootState) => state.locations.isLocationActionLoading
  );
  const locationSearchResult = useSelector(
    (state: RootState) => state.locations.locationSearchResult
  );

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    submit({
      ...values,
      location: locationSearchResult.find((l) => l.id === values.location_id),
    });
  };

  const debouncedSearch = debounce(async (value, setOptions) => {
    if (value) {
      dispatch(SearchLocations(value));
    } else {
      setOptions([]);
      setSelectedLocationName("");
    }
  }, 300);

  const handleSearch = async (value: string) => {
    debouncedSearch(value, setOptions);
  };

  useEffect(() => {
    if (locationSearchResult && locationSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = locationSearchResult
        ?.filter((l) => l?.id !== location_id)
        .map((location) => ({
          value: location.id,
          label: location.name,
          name: location.name,
        }));
      setOptions(suggestions);
    }
  }, [locationSearchResult, location_id]);

  const getItems: CollapseProps["items"] = [
    {
      key: "1",
      label: "Purchase orders",
      children: (
        <div className="w-full flex flex-col gap-y-4 mt-4">
          <LocationPurchaseOrder orderNo={"#23434"} />
          <LocationPurchaseOrder orderNo={"#23434"} />
        </div>
      ),
    },
  ];

  const validator = yup.object().shape({
    total_quantity: yup
      .number()
      .min(1, "Quantity should be more than 0")
      .max(
        product.quantity ?? 1,
        `Quantity should not be more than ${product.quantity}`
      )
      .required("Quantity˝ is required"),
    location_id: yup.string().required("Location is required"),
  });

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleBlur, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={"w-full drawer-content-height"}>
            <div className={"mb-5 w-full flex items-center"}>
              <div className="w-1/2">
                <div className="w-full">
                  <p>Product</p>
                  <div className="flex items-center gap-x-2 mt-3">
                    <div className="h-[30px] w-[30px] bg-gray-800 rounded-full"></div>
                    {product?.product_name}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="w-full">
                  <p>Quantity</p>
                  <div className="mt-3">{product?.quantity}</div>
                </div>
              </div>
            </div>
            {type === "BULK" ? (
              <div className={"mb-5"}>
                <Collapse
                  className="custom-collpse-sm"
                  expandIcon={({ isActive }) => (
                    <RightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  expandIconPosition="right"
                  items={getItems}
                />
              </div>
            ) : (
              <></>
            )}
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Quantity to transfer"} required />
                <Form.Item
                  name={"total_quantity"}
                  help={
                    touched.total_quantity && errors.total_quantity
                      ? errors.total_quantity
                      : ""
                  }
                  validateStatus={
                    touched.total_quantity && errors.total_quantity
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"number"}
                        className={"form-field_input_2"}
                        value={values?.total_quantity}
                        onChange={(e) => {
                          setFieldValue("total_quantity", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("total_quantity", e.target.value);
                        }}
                        placeholder="Enter quantity to transfer"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Location to be transferred to"} required />
                <Form.Item
                  name={"location_id"}
                  help={
                    touched.location_id && errors.location_id
                      ? errors.location_id
                      : ""
                  }
                  validateStatus={
                    touched.location_id && errors.location_id
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <AutoComplete
                        options={options}
                        onSearch={handleSearch}
                        onSelect={(value, option) => {
                          setFieldValue("location_id", value);
                          setSelectedLocationName(option.name ?? "");
                        }}
                        onChange={(value) => {
                          setSelectedLocationName(value);
                          setFieldValue("location_id", "");
                          if (!value) {
                            setOptions([]);
                          }
                        }}
                        value={selectedLocationName || values.location_id}
                        notFoundContent={
                          isLoading ? <Spin size="small" /> : null
                        }
                        allowClear
                        style={{ width: "100%", height: "48px" }}
                      >
                        <input
                          type="text"
                          className="form-field_input_2"
                          placeholder="Search location"
                        />
                      </AutoComplete>
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Cancel"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Preview"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { TransferLocationProductForm };
