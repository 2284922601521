import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import UserManagementTab from "./UserManagementTab";
import UserLogsTab from "./UserLogsTab";

const UserManagement = () => {
  const [openNewUserDrawer, setOpenNewUserDrawer] = useState(false);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    console.log(search);
  }, [search]);

  const handleClick = () => {
    toggleNewUserDrawer();
  };

  const toggleNewUserDrawer = () => {
    setOpenNewUserDrawer(!openNewUserDrawer);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "User management",
      children: <UserManagementTab />,
    },
    {
      key: "2",
      label: "User logs",
      children: <UserLogsTab />,
    },
  ];

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={true}
        title={"Users"}
        subtitle={"Products in"}
        placeholder={"Search"}
        buttonText={"Create"}
        getSearchValue={setSearch}
        handleClick={handleClick}
      />
      <div className="mt-5">
        <Tabs className="user-management-tab" defaultActiveKey="1" items={items} />;
      </div>
    </div>
  );
};

export default UserManagement;
