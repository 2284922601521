import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  AllLocationProducts,
  LocationPurchaseOrders,
  RemoveLocationProduct,
  TransferLocationProduct,
  TransferLocationProductData,
} from "./components";
import AppDrawer from "../../../components/AppDrawer";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import {
  FetchLocationProducts,
  RemoveProduct,
  TransferProduct,
} from "../../../redux/actions/locations.action";
import { goBack } from "../../../utils/constants";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../redux/reducers";
import { ProductItemModel } from "../../../model/product.model";
import {
  LocationResponseModel,
  RemoveProductFromLocationModel,
  TransferProductToAnotherLocationModel,
} from "../../../model/location.model";

const SingleLMBulk = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const locationProducts = useSelector(
    (state: RootState) => state.locations.locationProducts
  );
  const [openLocationProductDrawer, setOpenLocationProductDrawer] =
    useState(false);
  const [
    openTransferLocationProductDrawer,
    setOpenTransferLocationProductDrawer,
  ] = useState(false);
  const [openRemoveLocationProductDrawer, setOpenRemoveLocationProductDrawer] =
    useState(false);
  const [openLocationPurchaseOrders, setOpenLocationPurchaseOrders] =
    useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [product, setProduct] = useState<ProductItemModel>({});
  const [selectedItems, setSelectedItems] = useState<ProductItemModel[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [id, setId] = useState<string>("");

  const toggleLocationProductDrawer = () => {
    setOpenLocationProductDrawer(!openLocationProductDrawer);
  };

  const toggleTransferLocationProductDrawer = () => {
    setOpenTransferLocationProductDrawer(!openTransferLocationProductDrawer);
  };

  const toggleRemoveLocationProductDrawer = () => {
    setOpenRemoveLocationProductDrawer(!openRemoveLocationProductDrawer);
  };

  const toggleLocationPurchaseOrders = () => {
    setOpenLocationPurchaseOrders(!openLocationPurchaseOrders);
  };

  const handleSubmit = (values: ProductItemModel) => {
    setProduct({ ...product, ...values });
    setActiveStep(1);
  };

  const handleTransferProduct = (product: ProductItemModel) => {
    setProduct(product);
    setActiveStep(0);
    toggleTransferLocationProductDrawer();
  };

  const handleRemoveProduct = (product: ProductItemModel) => {
    setProduct(product);
    setActiveStep(0);
    toggleRemoveLocationProductDrawer();
  };

  const handleViewProduct = (product: ProductItemModel) => {
    setProduct(product);
    setActiveStep(0);
    toggleLocationProductDrawer();
  };

  const handleTransfer = () => {
    toggleLocationProductDrawer();
    toggleTransferLocationProductDrawer();
  };

  const handleRemove = () => {
    toggleLocationProductDrawer();
    toggleRemoveLocationProductDrawer();
  };

  const handleViewAllPurchaseOrders = () => {
    toggleLocationProductDrawer();
    toggleLocationPurchaseOrders();
  };

  const handleComplete = (state: "TRANSFER" | "REMOVE") => {
    if (state === "TRANSFER") {
      transferProduct();
    } else {
      removeProduct();
    }
  };

  const transferProduct = () => {
    const req: TransferProductToAnotherLocationModel = {
      product: {
        id: product.product_id ?? "",
        quantity: parseInt(product?.total_quantity ?? ""),
        po_id: product.po_number ?? "",
      },
      source_location_id: id,
      destination_location_id: product.location_id ?? "",
    };
    dispatch(TransferProduct(req))
      .then(async (res: LocationResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Transferred product"
            message={res?.message}
          />,
          true
        );
        toggleTransferLocationProductDrawer();
        setActiveStep(0);
        dispatch(FetchLocationProducts(id));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const removeProduct = () => {
    const req: RemoveProductFromLocationModel = {
      product: {
        id: product.product_id ?? "",
        quantity: parseInt(product?.total_quantity ?? ""),
        po_id: product.po_number ?? "",
      },
      source_location_id: id,
    };
    dispatch(RemoveProduct(req))
      .then(async (res: LocationResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Product removed"
            message={res?.message}
          />,
          true
        );
        toggleRemoveLocationProductDrawer();
        setActiveStep(0);
        dispatch(FetchLocationProducts(id));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    if (params?.id) {
      setId(params?.id);
      dispatch(FetchLocationProducts(params?.id));
    } else {
      goBack();
    }
  }, [dispatch, params]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.COMPONENT}
        component={
          <Breadcrumb
            names={["Location", "Bulk", locationProducts?.location_name]}
            isPrevious={true}
          />
        }
        showSearchField={false}
      />
      <div className="default-page-height w-full">
        <AllLocationProducts
          transferProduct={handleTransferProduct}
          removeProduct={handleRemoveProduct}
          viewProduct={handleViewProduct}
          type="BULK"
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>

      {/* Location product */}
      <AppDrawer
        open={openLocationProductDrawer}
        toggle={toggleLocationProductDrawer}
        title={"Product"}
        subtitle={"View your product"}
      >
        <TransferLocationProductData
          transfer={handleTransfer}
          remove={handleRemove}
          viewAllPurchaseOrders={handleViewAllPurchaseOrders}
          product={product}
        />
      </AppDrawer>
      {/* Location product */}

      {/* Transfer location product */}
      <AppDrawer
        open={openTransferLocationProductDrawer}
        toggle={toggleTransferLocationProductDrawer}
        title={`Transfer ${product?.product_name}`}
        subtitle={`Where do you want to transfer ${product?.product_name?.toLowerCase()} to?`}
      >
        <TransferLocationProduct
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={() => handleComplete("TRANSFER")}
          cancel={toggleTransferLocationProductDrawer}
          activeStep={activeStep}
          product={product}
          type={"BULK"}
          location_id={id}
        />
      </AppDrawer>
      {/* Transfer location product */}

      {/* Remove location product */}
      <AppDrawer
        open={openRemoveLocationProductDrawer}
        toggle={toggleRemoveLocationProductDrawer}
        title={`Remove ${product?.product_name}`}
        subtitle={`Where do you want to transfer ${product?.product_name?.toLowerCase()} to?`}
      >
        <RemoveLocationProduct
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={() => handleComplete("REMOVE")}
          cancel={toggleRemoveLocationProductDrawer}
          activeStep={activeStep}
          product={product}
          type={"BULK"}
          location_id={id}
        />
      </AppDrawer>
      {/* Remove location product */}

      {/* Location purchase orders */}
      <AppDrawer
        open={openLocationPurchaseOrders}
        toggle={toggleLocationPurchaseOrders}
        title={`${product?.product_name} Purchase Orders`}
        subtitle={`View the purchase orders for ${product?.product_name?.toLowerCase()} here.`}
      >
        <LocationPurchaseOrders orderNo="" />
      </AppDrawer>
      {/* Location purchase orders */}
    </div>
  );
};

export default SingleLMBulk;
