import {
  CustomPropertiesModel,
  ProductDetailsModel,
  ProductItemModel,
  ProductResponseItemModel,
} from "../../model/product.model";
import { Types } from "../actions/products.action";

interface ProductState {
  isProductActionLoading: boolean;
  isProductPosLoading: boolean;
  isRestockLocationLoading: boolean;
  message?: string;
  products: ProductResponseItemModel;
  productSearchResult: ProductItemModel[];
  product: ProductDetailsModel;
  cp: CustomPropertiesModel;
  productPos: ProductItemModel[];
  restockLocations: ProductItemModel[];
}

interface ProductAction {
  type: string;
  message?: string;
  products: ProductResponseItemModel;
  productSearchResult: ProductItemModel[];
  product: ProductDetailsModel;
  cp: CustomPropertiesModel;
  productPos: ProductItemModel[];
  restockLocations: ProductItemModel[];
}

const INITIAL_STATE: ProductState = {
  isProductActionLoading: false,
  isProductPosLoading: false,
  isRestockLocationLoading: false,
  products: null as any,
  productSearchResult: [],
  product: null as any,
  cp: null as any,
  productPos: [],
  restockLocations: [],
};

export const productReducer = (
  state = INITIAL_STATE,
  action: ProductAction
) => {
  switch (action.type) {
    case Types.CREATE_PRODUCT_START:
    case Types.BULK_PRODUCT_UPLOAD_START:
    case Types.FETCH_PRODUCTS_START:
    case Types.SEARCH_PRODUCTS_START:
    case Types.FETCH_PRODUCT_START:
    case Types.SET_PRICE_START:
    case Types.CREATE_CUSTOM_PROPERTY_START:
    case Types.FETCH_CUSTOM_PROPERTIES_START:
    case Types.UPDATE_CUSTOM_PROPERTY_START:
    case Types.DELETE_CUSTOM_PROPERTY_START:
      return { ...state, isProductActionLoading: true };
    case Types.FETCH_PRODUCT_POS_START:
      return { ...state, isProductPosLoading: true };
    case Types.FETCH_RESTOCK_LOCATIONS_START:
      return { ...state, isRestockLocationLoading: true };
    case Types.CREATE_PRODUCT_SUCCESS:
    case Types.BULK_PRODUCT_UPLOAD_SUCCESS:
    case Types.SET_PRICE_SUCCESS:
    case Types.CREATE_CUSTOM_PROPERTY_SUCCESS:
    case Types.UPDATE_CUSTOM_PROPERTY_SUCCESS:
    case Types.DELETE_CUSTOM_PROPERTY_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
      };
    case Types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        products: action.products,
      };
    case Types.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        productSearchResult: action.productSearchResult ?? [],
      };
    case Types.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        product: action.product,
      };
    case Types.FETCH_CUSTOM_PROPERTIES_SUCCESS:
      return {
        ...state,
        isProductActionLoading: false,
        cp: action.cp,
      };
    case Types.FETCH_PRODUCT_POS_SUCCESS:
      return {
        ...state,
        isProductPosLoading: false,
        productPos: action.productPos ?? [],
      };
    case Types.FETCH_RESTOCK_LOCATIONS_SUCCESS:
      return {
        ...state,
        isRestockLocationLoading: false,
        restockLocations: action.restockLocations ?? [],
      };
    case Types.CREATE_PRODUCT_END:
    case Types.BULK_PRODUCT_UPLOAD_END:
    case Types.FETCH_PRODUCTS_END:
    case Types.SEARCH_PRODUCTS_END:
    case Types.FETCH_PRODUCT_END:
    case Types.SET_PRICE_END:
    case Types.CREATE_CUSTOM_PROPERTY_END:
    case Types.FETCH_CUSTOM_PROPERTIES_END:
    case Types.UPDATE_CUSTOM_PROPERTY_END:
    case Types.DELETE_CUSTOM_PROPERTY_END:
      return {
        ...state,
        isProductActionLoading: false,
        message: action.message,
      };
    case Types.FETCH_PRODUCT_POS_END:
      return {
        ...state,
        isProductPosLoading: false,
        message: action.message,
      };
    case Types.FETCH_RESTOCK_LOCATIONS_END:
      return {
        ...state,
        isRestockLocationLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};
