import React, { FC } from "react";
import "../../styles.scss";
import { IRestockAlerts } from "../../model";
import { RestockAlert } from "./RestockAlert";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const RestockAlerts: FC<IRestockAlerts> = ({ editAlert, deleteAlert }) => {
  const alerts = useSelector((state: RootState) => state.alerts.restockAlerts);
  return (
    <div className="w-full mt-5">
      {alerts.map((a) => (
        <React.Fragment key={a.id + "restock" + a.id}>
          <RestockAlert
            alert={a}
            editAlert={editAlert}
            deleteAlert={deleteAlert}
          />
          <div className="w-full border-b border-b-dark-300 my-4"></div>
        </React.Fragment>
      ))}
    </div>
  );
};

export { RestockAlerts };
