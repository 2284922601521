import { ReactComponent as QuicstockOverview } from "../../assets/svgs/quicstock-overview.svg";
import { ReactComponent as QuicstockPurchases } from "../../assets/svgs/quicstock-purchases.svg";
import { ReactComponent as QuicstockProducts } from "../../assets/svgs/quicstock-products.svg";
import { ReactComponent as QuicstockSuppliers } from "../../assets/svgs/quicstock-suppliers.svg";
import { ReactComponent as QuicstockLocationManagement } from "../../assets/svgs/quicstock-location-management.svg";
import { ReactComponent as QuicstockUserManagement } from "../../assets/svgs/quicstock-user-management.svg";
import { ReactComponent as QuicstockSales } from "../../assets/svgs/quicstock-sales.svg";
import { ReactComponent as QuicstockReports } from "../../assets/svgs/quicstock-reports.svg";
import { ReactComponent as QuicstockAlerts } from "../../assets/svgs/quicstock-alerts.svg";
import { ReactComponent as QuicstockSettings } from "../../assets/svgs/quicstock-settings.svg";
import { IRoute } from "../../layout/model";

export const routes: IRoute[] = [
  {
    id: 1,
    path: "/dashboard/overview",
    name: "Overview",
    icon: <QuicstockOverview />,
  },
  {
    id: 2,
    path: "/dashboard/purchases",
    name: "Purchases",
    icon: <QuicstockPurchases />,
  },
  {
    id: 3,
    path: "/dashboard/product",
    name: "Products",
    icon: <QuicstockProducts />,
    children: [
      {
        id: 1,
        path: "/dashboard/product/products",
        name: "Products",
      },
      {
        id: 2,
        path: "/dashboard/product/custom-properties",
        name: "Custom properties",
      },
    ],
  },
  {
    id: 4,
    path: "/dashboard/suppliers",
    name: "Suppliers",
    icon: <QuicstockSuppliers />,
  },
  {
    id: 5,
    path: "/dashboard/location-mamagement",
    name: "Location management",
    icon: <QuicstockLocationManagement />,
    children: [
      {
        id: 1,
        path: "/dashboard/location-mamagement/bulk",
        name: "Bulk",
      },
      {
        id: 2,
        path: "/dashboard/location-mamagement/sales",
        name: "Sales",
      },
      {
        id: 3,
        path: "/dashboard/location-mamagement/online-sales",
        name: "Online sales",
      },
    ],
  },
  {
    id: 6,
    path: "/dashboard/user-management",
    name: "User management",
    icon: <QuicstockUserManagement />,
  },
  {
    id: 7,
    path: "/dashboard/sales",
    name: "Sales",
    icon: <QuicstockSales />,
  },
  {
    id: 8,
    path: "/dashboard/reports",
    name: "Reports",
    icon: <QuicstockReports />,
  },
  {
    id: 9,
    path: "/dashboard/alerts",
    name: "Alerts",
    icon: <QuicstockAlerts />,
  },
  {
    id: 10,
    path: "/dashboard/settings",
    name: "Settings",
    icon: <QuicstockSettings />,
  },
];
