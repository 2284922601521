import { Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllSuppliers } from "../../model";
import { itemRender, paginationFooter } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { SupplierItemModel } from "../../../../model/supplier.model";
import TableActions from "../../../../components/TableActions";

const AllSuppliers: FC<IAllSuppliers> = ({
  viewSupplier,
  deleteSuppliers,
  paginationData,
  setPaginationData,
  setSelectedItems,
  selectedItems,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.suppliers.isSupplierActionLoading
  );
  const suppliers = useSelector(
    (state: RootState) => state.suppliers.suppliers
  );
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const columns = [
    {
      title: "Suplier name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "POs completed",
      dataIndex: "orders_completed",
      key: "orders_completed",
    },
  ];

  const data =
    suppliers &&
    suppliers.suppliers?.length > 0 &&
    suppliers.suppliers?.map((supplier, index) => {
      return {
        key: index,
        name: supplier.name ?? "---",
        phonenumber: supplier?.phonenumber ?? "---",
        orders_completed: supplier?.orders_completed ?? "---",
        id: supplier?.id,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      itemsPerPage: pageSize,
      page: current,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedItems(selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleExport = () => {};

  const handleDelete = () => {
    deleteSuppliers();
  };

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleExport={handleExport}
        handleDelete={handleDelete}
        disableDelete={selectedItems.length === 0}
      />
      <div className="w-full bg-white filter-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table add-pointer"}
          onRow={(item) => {
            return {
              onClick: () => {
                const supplier: SupplierItemModel | undefined =
                  suppliers.suppliers.find((s) => s.id === item.id);
                viewSupplier(supplier as any);
              },
            };
          }}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: suppliers?.total_count,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              paginationFooter(paginationData, suppliers?.total_count, total),
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                suppliers?.total_count,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { AllSuppliers };
