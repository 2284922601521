export enum ButtonState {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum RainbowBorderState {
  DARK = "DARK",
  LIGHT = "LIGHT",
}

export enum LeftHeaderType {
  COMPONENT = "COMPONENT",
  CUSTOM = "CUSTOM",
}
