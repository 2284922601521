import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import Breadcrumb from "../../../components/Breadcrumb";
import QuicstockPackage from "../../../assets/imgs/quicstock-package.png";
import EmptyContent from "../../../components/EmptyContent";
import { ILocationProduct } from "../model";
import {
  AddLocation,
  AddLocationProduct,
  AllLocations,
  EditLocation,
  LocationBarcode,
} from "./components";
import AppDrawer from "../../../components/AppDrawer";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import {
  LocationItemModel,
  LocationResponseModel,
} from "../../../model/location.model";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  AddProductToLocation,
  CreateLocation,
  DeleteLocations,
  FetchLocations,
  UpdateLocation,
} from "../../../redux/actions/locations.action";
import { ApiPaginationModel } from "../../../model/base.model";
import Confirm from "../../../components/Confirm";
import { RootState } from "../../../redux/reducers";

const LMSales = () => {
  const [openNewLocationDrawer, setOpenNewLocationDrawer] = useState(false);
  const [openEditLocationDrawer, setOpenEditLocationDrawer] = useState(false);
  const [openLocationBarcodeDrawer, setOpenLocationBarcodeDrawer] =
    useState(false);
  const [openNewLocationProductDrawer, setOpenNewLocationProductDrawer] =
    useState(false);
  const [search, setSearch] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const [location, setLocation] = useState<LocationItemModel>({
    name: "",
    location_code: "",
    description: "",
    location_type: "",
  });
  const locations = useSelector(
    (state: RootState) => state.locations.locations
  );
  const [product, setProduct] = useState<ILocationProduct>({});
  const dispatch: AppDispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<LocationItemModel[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [paginationData, setPaginationData] = useState<ApiPaginationModel>({
    itemsPerPage: 10,
    page: 1,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [ids, setIds] = useState<string>("");

  const handleClick = () => {
    toggleNewLocationDrawer();
  };

  useEffect(() => {
    console.log(search);
  }, [search]);

  const toggleNewLocationDrawer = () => {
    setOpenNewLocationDrawer(!openNewLocationDrawer);
  };

  const toggleEditLocationDrawer = () => {
    setOpenEditLocationDrawer(!openEditLocationDrawer);
  };

  const toggleLocationBarcodeDrawer = () => {
    setOpenLocationBarcodeDrawer(!openLocationBarcodeDrawer);
  };

  const toggleLocationProductDrawer = () => {
    setOpenNewLocationProductDrawer(!openNewLocationProductDrawer);
  };

  const handleSubmit = (values: LocationItemModel) => {
    setLocation(values);
    setActiveStep(1);
  };

  const handleComplete = (state: "ADD" | "UPDATE") => {
    if (state === "ADD") {
      createLocation();
    } else {
      updateLocation();
    }
  };

  const handleUpdateLocation = (_location: LocationItemModel) => {
    setLocation(_location);
    setActiveStep(0);
    toggleEditLocationDrawer();
  };

  const handleAddProduct = (_location: LocationItemModel) => {
    setLocation(_location);
    setActiveStep(0);
    toggleLocationProductDrawer();
  };

  const handlePrintBarcode = () => {
    openNotification(
      <NotificationMessage
        type="success"
        title="Barcode printed"
        message="Barcode printed successfully"
      />,
      true
    );
    toggleLocationBarcodeDrawer();
  };

  const handleLocationDelete = (id: string) => {
    deleteLocations(id);
  };

  const handleBulkLocationDelete = () => {
    setOpen(true);
    const _ids: string = selectedItems.map((s) => s.id?.toString()).join(",");
    setIds(_ids);
  };

  const handleProductSubmit = (values: ILocationProduct) => {
    setProduct(values);
    setActiveStep(1);
  };

  const handleProductComplete = () => {
    addProductToLocation();
  };

  const createLocation = () => {
    dispatch(CreateLocation({ ...location, location_type: "sales" }))
      .then(async (res: LocationResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New location added"
            message={res?.message}
          />,
          true
        );
        toggleNewLocationDrawer();
        setActiveStep(0);
        dispatch(FetchLocations({ ...paginationData, location_type: "sales" }));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const updateLocation = () => {
    dispatch(UpdateLocation({ ...location, location_type: "sales" }))
      .then(async (res: LocationResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Location updated"
            message={res?.message}
          />,
          true
        );
        toggleEditLocationDrawer();
        setActiveStep(0);
        dispatch(FetchLocations({ ...paginationData, location_type: "sales" }));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const deleteLocations = (_ids?: string) => {
    dispatch(DeleteLocations(_ids ? _ids : ids))
      .then(async (res: LocationResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Delete location"
            message={res?.message}
          />,
          true
        );
        dispatch(FetchLocations({ ...paginationData, location_type: "sales" }));
        setSelectedRowKeys([]);
        setSelectedItems([]);
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const addProductToLocation = () => {
    dispatch(
      AddProductToLocation(location.id ?? "", {
        product_id: product.id,
        quantity: parseInt(product.total_quantity ?? "0"),
      })
    )
      .then(async (res: LocationResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New product added"
            message={res?.message}
          />,
          true
        );
        toggleLocationProductDrawer();
        setActiveStep(0);
        dispatch(FetchLocations({ ...paginationData, location_type: "sales" }));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchLocations({ ...paginationData, location_type: "sales" }));
  }, [dispatch, paginationData]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.COMPONENT}
        component={
          <Breadcrumb names={["Location", "Sales"]} isPrevious={true} />
        }
        showSearchField={true}
        placeholder={"Search"}
        buttonText={"New sales location"}
        getSearchValue={setSearch}
        handleClick={handleClick}
      />
      <div className="default-page-height w-full">
        {locations && locations.locations && locations.locations.length ? (
          <AllLocations
            deleteBulkLocation={handleBulkLocationDelete}
            updateLocation={handleUpdateLocation}
            printBarcode={toggleLocationBarcodeDrawer}
            deleteLocation={handleLocationDelete}
            addProduct={handleAddProduct}
            type="OFFLINE"
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) : (
          <EmptyContent
            label={"No locations in sales yet"}
            component={
              <img
                src={QuicstockPackage}
                alt={"QuicstockPackage"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Add new location"}
            handlePrimaryButtonClick={() => {
              setActiveStep(0);
              toggleNewLocationDrawer();
            }}
          />
        )}
      </div>

      {/* Add new location */}
      <AppDrawer
        open={openNewLocationDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleNewLocationDrawer();
        }}
        title={"Add new sales location"}
        subtitle={"Update location"}
      >
        <AddLocation
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={() => handleComplete("ADD")}
          cancel={toggleNewLocationDrawer}
          state={"ADD"}
          activeStep={activeStep}
          location={location}
          type="OFFLINE"
        />
      </AppDrawer>
      {/* Add new location */}

      {/* Update location */}
      <AppDrawer
        open={openEditLocationDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleEditLocationDrawer();
        }}
        title={"Update sales location"}
        subtitle={"Add a new location"}
      >
        <EditLocation
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={() => handleComplete("UPDATE")}
          cancel={toggleEditLocationDrawer}
          state={"UPDATE"}
          activeStep={activeStep}
          location={location}
          type="OFFLINE"
        />
      </AppDrawer>
      {/* Update location */}

      {/* Print barcode */}
      <AppDrawer
        open={openLocationBarcodeDrawer}
        toggle={toggleLocationBarcodeDrawer}
        title={"Barcode"}
        subtitle={"You can print this bar code"}
      >
        <LocationBarcode
          cancel={toggleLocationBarcodeDrawer}
          submit={handlePrintBarcode}
        />
      </AppDrawer>
      {/* Print barcode */}

      {/* Add product */}
      <AppDrawer
        open={openNewLocationProductDrawer}
        toggle={toggleLocationProductDrawer}
        title={"Add product"}
        subtitle={"Add a new product"}
      >
        <AddLocationProduct
          submit={handleProductSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleProductComplete}
          cancel={toggleLocationProductDrawer}
          activeStep={activeStep}
          product={product}
        />
      </AppDrawer>
      {/* Add product */}

      <Confirm
        title="Delete Sales Locations"
        confirmButtonText="Delete"
        open={open}
        cancel={() => setOpen(false)}
        confirm={deleteLocations}
      />
    </div>
  );
};

export default LMSales;
