import React, { FC } from "react";
import "../styles.scss";
import { ReactComponent as QuicstockFolderOpen } from "../../assets/svgs/quicstock-folder-open.svg";
import { ReactComponent as QuicstockDefaultCheck } from "../../assets/svgs/quicstock-default-check.svg";
import { IUploadProgressIndicator } from "../model";
import { Progress } from "antd";

const UploadProgressIndicator: FC<IUploadProgressIndicator> = ({
  download,
  cancel,
  state,
  uploadType,
  defaultStateText,
  uploadingStateText,
  size,
  percent,
}) => {
  return (
    <>
      {state === "DEFAULT" ? (
        <div className="bg-primary-100 p-3 rounded-[10px] w-full flex justify-between">
          <div className="flex items-center">
            <QuicstockFolderOpen />
            <div className="ml-3">
              <p className="text-sm-regular">{defaultStateText}</p>
              <p className="text-x-small text-gray-300">{uploadType}</p>
            </div>
          </div>
          <button
            type="button"
            className="border-[1px] border-dark-200 px-3 py-1 rounded-[6px] text-x-small"
            onClick={download}
          >
            Download
          </button>
        </div>
      ) : (
        <div className="p-3 rounded-[10px] border-[1px] border-dashed">
          <div className="w-full flex justify-between ">
            <div className="flex items-center">
              <QuicstockFolderOpen />
              <div className="ml-3">
                <p className="text-sm-regular">{uploadingStateText}</p>
                <p className="text-x-small text-gray-300">{size}</p>
              </div>
            </div>
            {percent < 100 ? (
              <button
                type="button"
                className="border-[1px] border-dark-200 px-3 py-1 rounded-[6px] text-x-small"
                onClick={cancel}
              >
                Cancel
              </button>
            ) : (
              <QuicstockDefaultCheck className="default-check" />
            )}
          </div>
          <Progress
            percent={percent}
            status={percent < 100 ? "active" : "normal"}
            size={["70%", 4]}
            strokeColor={percent < 100 ? "#2763E9" : "#FC622F"}
            showInfo={false}
          />
        </div>
      )}
    </>
  );
};

export { UploadProgressIndicator };
