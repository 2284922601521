import React, { FC } from "react";
import "../../styles.scss";
import { IProductLocation } from "../../model";

const ProductLocation: FC<IProductLocation> = ({ title, locations }) => {
  return (
    <div className="p-4 border-[0.5px] border-[#EFEFEF] rounded-[10px] mb-4">
      <p className="xl:text-sm-headline text-sm-regular mb-2">{title}</p>
      {locations?.map((l) => (
        <p
          key={l?.id}
          className="xl:text-sm-regular text-labels mb-2 text-gray-300"
        >
          {l?.name}: <span className="text-black ml-1">{l?.quantity}</span>
        </p>
      ))}
    </div>
  );
};

export { ProductLocation };
