import React, { FC } from "react";
import "../../styles.scss";
import { IPurchaseBreakdown } from "../../model";
import { formatCurrency, NAIRA } from "../../../../utils/constants";

const PurchaseBreakdown: FC<IPurchaseBreakdown> = ({
  sub_total,
  tax,
  shipping,
  total,
}) => {
  return (
    <div className="w-full mt-4 flex flex-col gap-y-4">
      <div className="w-full flex justify-between sm:text-sm-regular text-labels">
        <p>Subtotal:</p>
        <p className="sm:text-sm-headline text-sm-regular">
          {NAIRA}
          {formatCurrency(sub_total.toString())}
        </p>
      </div>
      <div className="w-full flex justify-between sm:text-sm-regular text-labels">
        <p>Tax (8%):</p>
        <p className="sm:text-sm-headline text-sm-regular">
          {NAIRA}
          {formatCurrency(tax.toString())}
        </p>
      </div>
      <div className="w-full flex justify-between sm:text-sm-regular text-labels">
        <p>Shipping:</p>
        <p className="sm:text-sm-headline text-sm-regular">
          {NAIRA}
          {formatCurrency(shipping.toString())}
        </p>
      </div>
      <div className="w-full flex justify-between sm:text-sm-regular text-labels">
        <p>Total:</p>
        <p className="sm:text-sm-headline text-sm-regular">
          {NAIRA}
          {formatCurrency(total.toString())}
        </p>
      </div>
    </div>
  );
};

export { PurchaseBreakdown };
