import React, { FC } from "react";
import "../styles.scss";
import { IBreadcrumb } from "../model";
import { ReactComponent as QuicstockArrowLeft } from "../../assets/svgs/quicstock-arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as QuicstockModalClose } from "../../assets/svgs/quicstock-modal-close.svg";

const Breadcrumb: FC<IBreadcrumb> = ({
  names,
  isPrevious,
  showBackButton = false,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    isPrevious ? window.history.back() : navigate("/dashboard");
  };
  return (
    <>
      {names.length ? (
        <div className="flex gap-x-2 items-center">
          {(names.length > 2 || showBackButton) && (
            <button onClick={handleClick}>
              <QuicstockModalClose />
            </button>
          )}
          <p className="text-sm-regular text-gray-300">{names[0]}</p>
          <QuicstockArrowLeft />
          <p
            className={`text-sm-regular ${names.length > 2 ? "text-gray-300" : "text-black"}`}
          >
            {names[1]}
          </p>
          {names.length > 2 && (
            <>
              <QuicstockArrowLeft />
              <p className="text-sm-regular text-black">{names[2]}</p>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Breadcrumb;
