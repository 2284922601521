import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IPurchaseFormField } from "../../model";
import { Field } from "formik";
import { AutoComplete, Form, Input, Select, Spin } from "antd";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import FieldName from "../../../../components/FieldName";
import QuicstockNaira from "../../../../assets/svgs/quicstock-naira.svg";
import { formatCurrency } from "../../../../utils/constants";
import { ReactComponent as QuicstockMinus } from "../../../../assets/svgs/quicstock-minus.svg";
import { useDispatch, useSelector } from "react-redux";
import { ApiDropdownModel } from "../../../../model/base.model";
import { SearchProducts } from "../../../../redux/actions/products.action";
import { RootState } from "../../../../redux/reducers";
import { AppDispatch } from "../../../../redux/store";
import { debounce } from "lodash";

const PurchaseFormField: FC<IPurchaseFormField> = ({
  index,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  remove,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedProductName, setSelectedProductName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );

  const debouncedSearch = debounce(async (value, setOptions) => {
    if (value) {
      dispatch(SearchProducts(value));
    } else {
      setOptions([]);
      setSelectedProductName("");
    }
  }, 300);

  const handleSearch = async (value: string) => {
    debouncedSearch(value, setOptions);
  };

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
        })
      );
      setOptions(suggestions);
    }
  }, [productSearchResult]);

  return (
    <div className={"grid sm:grid-cols-12 grid-cols-1 gap-x-4"}>
      <div className="mb-5 xl:col-span-5 sm:col-span-3 col">
        <FieldContainer iconPlacementCss={"customFieldIcon"}>
          <FieldName name={"Item"} required />
          <Form.Item
            name={`items[${index}].item`}
            validateStatus={
              touched.items?.[index]?.item &&
              (errors as any).items?.[index]?.item
                ? "error"
                : "success"
            }
            help={
              touched.items?.[index]?.item &&
              (errors as any).items?.[index]?.item
                ? (errors as any).items?.[index]?.item
                : ""
            }
          >
            <Field>
              {({ form: { setFieldValue } }: any) => (
                <AutoComplete
                  options={options}
                  onSearch={handleSearch}
                  onSelect={(value, option) => {
                    setFieldValue(`items[${index}].item`, value);
                    setSelectedProductName(option.name ?? "");
                  }}
                  onChange={(value) => {
                    setSelectedProductName(value);
                    setFieldValue(`items[${index}].item`, "");
                    if (!value) {
                      setOptions([]);
                    }
                  }}
                  value={selectedProductName || values?.items[index].item}
                  notFoundContent={isLoading ? <Spin size="small" /> : null}
                  allowClear
                  style={{ width: "100%", height: "48px" }}
                >
                  <input
                    type="text"
                    className="form-field_input_2"
                    placeholder="Search product"
                  />
                </AutoComplete>
              )}
            </Field>
          </Form.Item>
        </FieldContainer>
      </div>
      <div className="xl:col-span-6 sm:col-span-8 col">
        <div className="w-full grid grid-cols-12 gap-x-4">
          <div className={"mb-5 sm:col-span-3 col-span-6"}>
            <FieldContainer
              iconPlacementCss={"customFieldIcon"}
              icon={QuicstockNaira}
            >
              <FieldName name={"Unit"} required />
              <Form.Item
                name={`items[${index}].unit_price`}
                validateStatus={
                  touched.items?.[index]?.unit_price &&
                  (errors as any).items?.[index]?.unit_price
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.item &&
                  (errors as any).items?.[index]?.unit_price
                    ? (errors as any).items?.[index]?.unit_price
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.items[index].unit_price || ""}
                      onChange={(e) => {
                        handleChange(e);
                        const inputValue = e.target.value;
                        const formattedValue = formatCurrency(inputValue);
                        setFieldValue(
                          `items[${index}].unit_price`,
                          formattedValue
                        );
                      }}
                      onBlur={handleBlur}
                      placeholder="0.00"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5 sm:col-span-3 col-span-6"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <FieldName name={"Type"} />
              <Form.Item
                name={`items[${index}].type`}
                validateStatus={
                  touched.items?.[index]?.type &&
                  (errors as any).items?.[index]?.type
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.item &&
                  (errors as any).items?.[index]?.type
                    ? (errors as any).items?.[index]?.type
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Select
                      placeholder={"Select type"}
                      value={values?.items[index].type || null}
                      onBlur={handleBlur}
                      className={"form-field_input_3"}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue(`items[${index}].type`, e);
                      }}
                      options={[
                        { value: "1", label: "Type 1" },
                        { value: "2", label: "Type 2" },
                      ]}
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5 sm:col-span-3 col-span-6"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <FieldName name={"Quantity"} required />
              <Form.Item
                name={`items[${index}].quantity`}
                validateStatus={
                  touched.items?.[index]?.quantity &&
                  (errors as any).items?.[index]?.quantity
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.quantity &&
                  (errors as any).items?.[index]?.quantity
                    ? (errors as any).items?.[index]?.quantity
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"number"}
                      className={"form-field_input_2"}
                      value={values?.items[index].quantity || ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(
                          `items[${index}].quantity`,
                          e.target.value
                        );

                        // Automatically calculate and set the 'total' value
                        const unitPrice = parseFloat(
                          values.items[index].unit_price.split(",").join("")
                        );
                        const quantity = parseFloat(e.target.value);
                        const total = unitPrice * quantity;

                        if (!isNaN(total)) {
                          const formattedValue = formatCurrency(
                            total.toString()
                          );
                          setFieldValue(
                            `items[${index}].total`,
                            formattedValue
                          );
                        } else {
                          setFieldValue(`items[${index}].total`, "");
                        }
                      }}
                      onBlur={handleBlur}
                      placeholder="0"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5 sm:col-span-3 col-span-6"}>
            <FieldContainer
              iconPlacementCss={"customFieldIcon"}
              icon={QuicstockNaira}
            >
              <FieldName name={"Total"} required />
              <Form.Item
                name={`items[${index}].total`}
                validateStatus={
                  touched.items?.[index]?.total &&
                  (errors as any).items?.[index]?.total
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.total &&
                  (errors as any).items?.[index]?.total
                    ? (errors as any).items?.[index]?.total
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.items[index].total || ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(`items[${index}].total`, e.target.value);
                      }}
                      onBlur={handleBlur}
                      placeholder="0.00"
                      disabled
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
        </div>
      </div>
      <div className="sm:col-span-1 col">
        <div className="w-full flex justify-end items-center h-full">
          <button
            type="button"
            className="h-[40px] sm:w-[40px] w-full border border-dark-200 rounded-full px-2 py-3 flex justify-center items-center mb-5"
            onClick={remove}
          >
            <span className="sm:block hidden">
              <QuicstockMinus />
            </span>
            <span className="sm:hidden block text-error-main">
              Remove
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export { PurchaseFormField };
