import React, { FC } from "react";
import "../../styles.scss";
import { IManageCustomProperties } from "../../model";
import { Form, Input, Select } from "antd";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { ReactComponent as QuicstockAdd } from "../../../../assets/svgs/quicstock-add.svg";
import { ReactComponent as QuicstockInfoCircle } from "../../../../assets/svgs/quicstock-info-circle.svg";

const ManageCustomProperties: FC<IManageCustomProperties> = ({
  cancel,
  addNewProperty,
  save,
}) => {
  const values = {
    name: "",
    type: "",
  };
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    save(values);
  };

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit, handleBlur }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={"mt-8"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <div className={"text-left text-sm-regular mb-2"}>
                New property
              </div>
              <Form.Item
                name={"name"}
                help={touched.name && errors.name ? errors.name : ""}
                validateStatus={
                  touched.name && errors.name ? "error" : "success"
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.name}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                      placeholder="Property name"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-10"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <Form.Item
                name={"type"}
                help={touched.type && errors.type ? errors.type : ""}
                validateStatus={
                  touched.type && errors.type ? "error" : "success"
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Select
                      placeholder={"Value type"}
                      value={values?.type || null}
                      onBlur={handleBlur}
                      className={"form-field_input_3"}
                      onChange={(e: any) => {
                        setFieldValue("type", e);
                      }}
                      options={[
                        { value: "A", label: "Type A" },
                        { value: "B", label: "Type B" },
                      ]}
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-10"}>
            <p className={"text-left text-sm-regular mb-2"}>
              Click on the tile below to add new item
            </p>
            <button
              onClick={addNewProperty}
              type="button"
              className="flex items-center justify-between p-3 border-[1px] border-[#EFEFEF] rounded-[10px] w-full"
            >
              <p>Add new property</p>
              <QuicstockAdd />
            </button>
          </div>
          <div className={"mb-5"}>
            <p className={"text-left text-sm-regular mb-2"}>
              Select a particular purchase orer
            </p>
            <MPurchaseOrder />
            <MPurchaseOrder />
            <MPurchaseOrder />
          </div>

          <div className="text-secondary-500 bg-[#FFF7F5] px-4 py-3 rounded-[10px] flex justify-center items-center gap-x-4 mt-20">
            <QuicstockInfoCircle />
            You can only add five custom properties
          </div>
          <div className="my-5 border-[0.5px] border-[#EFEFEF]"></div>
          <div className={"grid grid-cols-3 gap-x-2"}>
            <div className={"col-1"}></div>
            <div className={"col-1"}>
              <Button
                css={
                  "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                }
                state={ButtonState.PRIMARY}
                text={"Go back"}
                type={"button"}
                onClick={cancel}
              />
            </div>
            <div className={"col-1"}>
              <Button
                css={"w-full !rounded-[6px]"}
                state={ButtonState.PRIMARY}
                text={"Add new"}
                type={"submit"}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  name: yup.string().required("Cost price is required"),
  type: yup.string().required("Selling price is required"),
});

const MPurchaseOrder = () => {
  return (
    <button
      type="button"
      className="flex items-center justify-between p-3 border-[1px] border-[#EFEFEF] rounded-[10px] w-full mb-5"
    >
      <p>Add new property</p>
      <QuicstockAdd />
    </button>
  );
};

export { ManageCustomProperties };
