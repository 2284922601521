import {
  LocationItemModel,
  LocationProductResponseItemModel,
  LocationResponseItemModel,
} from "../../model/location.model";
import { Types } from "../actions/locations.action";

interface LocationState {
  isLocationActionLoading: boolean;
  message?: string;
  locations: LocationResponseItemModel;
  locationProducts: LocationProductResponseItemModel;
  locationSearchResult: LocationItemModel[];
}

interface LocationAction {
  type: string;
  message?: string;
  locations: LocationResponseItemModel;
  locationProducts: LocationProductResponseItemModel;
  locationSearchResult: LocationItemModel[];
}

const INITIAL_STATE: LocationState = {
  isLocationActionLoading: false,
  locations: null as any,
  locationProducts: null as any,
  locationSearchResult: [],
};

export const locationReducer = (
  state = INITIAL_STATE,
  action: LocationAction
) => {
  switch (action.type) {
    case Types.CREATE_LOCATION_START:
    case Types.FETCH_LOCATIONS_START:
    case Types.UPDATE_LOCATION_START:
    case Types.DELETE_LOCATIONS_START:
    case Types.FETCH_LOCATION_PRODUCTS_START:
    case Types.SEARCH_LOCATIONS_START:
    case Types.TRANSFER_PRODUCT_START:
    case Types.REMOVE_PRODUCT_START:
      return { ...state, isLocationActionLoading: true };
    case Types.CREATE_LOCATION_SUCCESS:
    case Types.UPDATE_LOCATION_SUCCESS:
    case Types.DELETE_LOCATIONS_SUCCESS:
    case Types.TRANSFER_PRODUCT_SUCCESS:
    case Types.REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLocationActionLoading: false,
      };
    case Types.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        isLocationActionLoading: false,
        locations: action.locations,
      };
    case Types.FETCH_LOCATION_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLocationActionLoading: false,
        locationProducts: action.locationProducts,
      };
    case Types.SEARCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        isLocationActionLoading: false,
        locationSearchResult: action.locationSearchResult ?? [],
      };
    case Types.CREATE_LOCATION_END:
    case Types.FETCH_LOCATIONS_END:
    case Types.UPDATE_LOCATION_END:
    case Types.DELETE_LOCATIONS_END:
    case Types.FETCH_LOCATION_PRODUCTS_END:
    case Types.SEARCH_LOCATIONS_END:
    case Types.TRANSFER_PRODUCT_END:
    case Types.REMOVE_PRODUCT_END:
      return {
        ...state,
        isLocationActionLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};
