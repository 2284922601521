import React, { FC } from "react";
import "../../styles.scss";
import { IAddProduct } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { ProductDetails, ProductForm } from ".";

const AddProduct: FC<IAddProduct> = ({
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
  product,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Add product"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <ProductForm submit={submit} cancel={cancel} />
        </div>
        <div hidden={activeStep === 0}>
          <ProductDetails
            product={product}
            goBack={goBack}
            complete={complete}
          />
        </div>
      </div>
    </div>
  );
};

export { AddProduct };
