import React, { FC } from "react";
import "../../styles.scss";
import { IUserDetails } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import QuicstockUserAvatar from "../../../../assets/imgs/quicstock-user-avatar.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import QuicstockDash from "../../../../assets/imgs/quicstock-dash.png";

const UserDetails: FC<IUserDetails> = ({ state, user, goBack, complete }) => {
  const isLoading = useSelector(
    (state: RootState) => state.suppliers.isSupplierActionLoading
  );
  return (
    <div className="w-full">
      <div className="w-full drawer-content-height">
        <div className="flex flex-col justify-center items-center">
          <div className="mb-6">
            <img
              src={QuicstockUserAvatar}
              alt={"QuicstockSupplier"}
              className={"h-[150px]"}
            />
          </div>
          <UserInfo label={"Full name"} value={user?.name} />
          <UserInfo label={"Staff ID"} value={user?.staffId} />
          <UserInfo label={"Phone number"} value={user?.mobileNumber} />
          <UserInfo label={"Email"} value={user?.email} />
          <UserInfo label={"Department"} value={user?.department} />
          <UserInfo label={"Level"} value={user?.level} />
          <UserInfo label={"Role"} value={user?.role} />
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={goBack}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px] !px-0"}
              state={ButtonState.PRIMARY}
              text={"Confirm details"}
              type={"button"}
              onClick={complete}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const UserInfo: FC<{ label: string; value?: string }> = ({ label, value }) => {
  return (
    <div className="mb-6 grid grid-cols-11">
      <div className="sm:col-span-3 col-span-4 sm:text-sm-regular text-labels text-gray-300">
        {label}
      </div>
      <div className="sm:col-span-4 col-span-2 flex items-center">
        <div className="w-full">
          <img src={QuicstockDash} alt={"QuicstockDash"} className={"w-full"} />
        </div>
      </div>
      <div className="sm:col-span-4 col-span-5 text-right sm:text-sm-regular text-labels">
        {value}
      </div>
    </div>
  );
};

export { UserDetails };
