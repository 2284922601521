import {
  applyMiddleware,
  legacy_createStore as createStore,
  compose,
} from "redux";
import { thunk, ThunkAction, ThunkDispatch } from "redux-thunk";
import promise from "redux-promise-middleware";
import reducer from "./reducers/index";
import { RootState } from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Declare types for Thunk actions and dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  any
>;

export type AppDispatch = ThunkDispatch<RootState, unknown, any>;

const middlewares: any = [promise, thunk];
if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore<any, any>(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
