import { ThunkAction } from "redux-thunk";
import {
  addProductToLocation,
  createLocation,
  deleteLocations,
  getLocationProducts,
  getLocations,
  removeProduct,
  searchLocations,
  transferProduct,
  updateLocation,
} from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import {
  FetchLocationParamsModel,
  LocationItemModel,
  LocationProductItemModel,
  RemoveProductFromLocationModel,
  TransferProductToAnotherLocationModel,
} from "../../model/location.model";

export const Types = {
  CREATE_LOCATION_START: "CREATE_LOCATION_START",
  CREATE_LOCATION_SUCCESS: "CREATE_LOCATION_SUCCESS",
  CREATE_LOCATION_END: "CREATE_LOCATION_END",

  FETCH_LOCATIONS_START: "FETCH_LOCATIONS_START",
  FETCH_LOCATIONS_SUCCESS: "FETCH_LOCATIONS_SUCCESS",
  FETCH_LOCATIONS_END: "FETCH_LOCATIONS_END",

  UPDATE_LOCATION_START: "UPDATE_LOCATION_START",
  UPDATE_LOCATION_SUCCESS: "UPDATE_LOCATION_SUCCESS",
  UPDATE_LOCATION_END: "UPDATE_LOCATION_END",

  DELETE_LOCATIONS_START: "DELETE_LOCATIONS_START",
  DELETE_LOCATIONS_SUCCESS: "DELETE_LOCATIONS_SUCCESS",
  DELETE_LOCATIONS_END: "DELETE_LOCATIONS_END",

  FETCH_LOCATION_PRODUCTS_START: "FETCH_LOCATION_PRODUCTS_START",
  FETCH_LOCATION_PRODUCTS_SUCCESS: "FETCH_LOCATION_PRODUCTS_SUCCESS",
  FETCH_LOCATION_PRODUCTS_END: "FETCH_LOCATION_PRODUCTS_END",

  SEARCH_LOCATIONS_START: "SEARCH_LOCATIONS_START",
  SEARCH_LOCATIONS_SUCCESS: "SEARCH_LOCATIONS_SUCCESS",
  SEARCH_LOCATIONS_END: "SEARCH_LOCATIONS_END",

  TRANSFER_PRODUCT_START: "TRANSFER_PRODUCT_START",
  TRANSFER_PRODUCT_SUCCESS: "TRANSFER_PRODUCT_SUCCESS",
  TRANSFER_PRODUCT_END: "TRANSFER_PRODUCT_END",

  REMOVE_PRODUCT_START: "REMOVE_PRODUCT_START",
  REMOVE_PRODUCT_SUCCESS: "REMOVE_PRODUCT_SUCCESS",
  REMOVE_PRODUCT_END: "REMOVE_PRODUCT_END",
};

export const CreateLocation = (
  req: LocationItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_LOCATION_START });
    return new Promise((resolve, reject) => {
      createLocation(req)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_LOCATION_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_LOCATION_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchLocations = (
  params: FetchLocationParamsModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_LOCATIONS_START });
    return new Promise((resolve, reject) => {
      getLocations(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_LOCATIONS_SUCCESS,
            locations: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_LOCATIONS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const UpdateLocation = (
  req: LocationItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.UPDATE_LOCATION_START });
    return new Promise((resolve, reject) => {
      updateLocation(req)
        .then(({ data }) => {
          dispatch({ type: Types.UPDATE_LOCATION_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.UPDATE_LOCATION_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const DeleteLocations = (
  ids: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_LOCATIONS_START });
    return new Promise((resolve, reject) => {
      deleteLocations(ids)
        .then(({ data }) => {
          dispatch({ type: Types.DELETE_LOCATIONS_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DELETE_LOCATIONS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const AddProductToLocation = (
  id: string,
  req: LocationProductItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_LOCATION_START });
    return new Promise((resolve, reject) => {
      addProductToLocation(id, req)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_LOCATION_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_LOCATION_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchLocationProducts = (
  id: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_LOCATION_PRODUCTS_START });
    return new Promise((resolve, reject) => {
      getLocationProducts(id)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_LOCATION_PRODUCTS_SUCCESS,
            locationProducts: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_LOCATION_PRODUCTS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const SearchLocations = (
  searchText: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.SEARCH_LOCATIONS_START });
    return new Promise((resolve, reject) => {
      searchLocations(searchText)
        .then(({ data }) => {
          dispatch({
            type: Types.SEARCH_LOCATIONS_SUCCESS,
            locationSearchResult: data?.data?.locations ?? [],
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.SEARCH_LOCATIONS_END,
            message: response?.data?.message || "An error occured",
          });
        });
    });
  };
};

export const TransferProduct = (
  req: TransferProductToAnotherLocationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.TRANSFER_PRODUCT_START });
    return new Promise((resolve, reject) => {
      transferProduct(req)
        .then(({ data }) => {
          dispatch({
            type: Types.TRANSFER_PRODUCT_SUCCESS,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.TRANSFER_PRODUCT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const RemoveProduct = (
  req: RemoveProductFromLocationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.REMOVE_PRODUCT_START });
    return new Promise((resolve, reject) => {
      removeProduct(req)
        .then(({ data }) => {
          dispatch({
            type: Types.REMOVE_PRODUCT_SUCCESS,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.REMOVE_PRODUCT_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};
