import React, { useEffect, useState } from "react";
import "../styles.scss";
import PageHeader from "../../../components/PageHeader";
import { LeftHeaderType } from "../../../components/enum";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockSupplier from "../../../assets/imgs/quicstock-supplier.png";
import AppDrawer from "../../../components/AppDrawer";
import {
  AddSupplier,
  AllSuppliers,
  EditSupplier,
  ViewSupplier,
} from "./components";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import {
  SupplierItemModel,
  SupplierResponseModel,
} from "../../../model/supplier.model";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  CreateSupplier,
  DeleteSuppliers,
  FetchSuppliers,
  UpdateSupplier,
} from "../../../redux/actions/suppliers.action";
import { normalizePhoneNumber } from "../../../utils/constants";
import { ApiPaginationModel } from "../../../model/base.model";
import Confirm from "../../../components/Confirm";

const Suppliers = () => {
  const [openNewSupplierDrawer, setOpenNewSupplierDrawer] = useState(false);
  const [openViewSupplierDrawer, setOpenViewSupplierDrawer] = useState(false);
  const [openEditSupplierDrawer, setOpenEditSupplierDrawer] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [ids, setIds] = useState<string>("");
  const [supplier, setSupplier] = useState<SupplierItemModel>({
    id: "",
    name: "",
    phonenumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
  });
  const [suppliers] = useState<SupplierItemModel[]>([supplier]);
  const dispatch: AppDispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<SupplierItemModel[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [paginationData, setPaginationData] = useState<ApiPaginationModel>({
    itemsPerPage: 10,
    page: 1,
  });

  const handleClick = () => {
    toggleNewSupplierDrawer();
  };

  useEffect(() => {
    console.log(search);
  }, [search]);

  const toggleNewSupplierDrawer = () => {
    setOpenNewSupplierDrawer(!openNewSupplierDrawer);
  };

  const toggleViewSupplierDrawer = () => {
    setOpenViewSupplierDrawer(!openViewSupplierDrawer);
  };

  const toggleEditSupplierDrawer = () => {
    setOpenEditSupplierDrawer(!openEditSupplierDrawer);
  };

  const handleViewSupplier = (supplier: SupplierItemModel) => {
    setSupplier(supplier);
    toggleViewSupplierDrawer();
  };

  const handleSubmit = (values: SupplierItemModel, state: "ADD" | "UPDATE") => {
    if (state === "ADD") {
      setSupplier(values);
      setActiveStep(1);
    } else {
      setSupplier(values);
      updateSupplier(values);
    }
  };

  const handleOpenEditModal = () => {
    toggleViewSupplierDrawer();
    toggleEditSupplierDrawer();
  };

  const handleComplete = () => {
    createSupplier();
  };

  const handleDeleteSuppliers = () => {
    setOpen(true);
    const _ids: string = selectedItems.map((s) => s.id?.toString()).join(",");
    setIds(_ids);
  };

  const createSupplier = () => {
    dispatch(
      CreateSupplier({
        ...supplier,
        phonenumber: normalizePhoneNumber(supplier.phonenumber),
      })
    )
      .then(async (res: SupplierResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New supplier added"
            message={res?.message}
          />,
          true
        );
        toggleNewSupplierDrawer();
        setActiveStep(0);
        dispatch(FetchSuppliers(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const updateSupplier = (values: SupplierItemModel) => {
    dispatch(
      UpdateSupplier({
        ...values,
        phonenumber: normalizePhoneNumber(values.phonenumber),
      })
    )
      .then(async (res: SupplierResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Supplier updated"
            message={res?.message}
          />,
          true
        );
        toggleEditSupplierDrawer();
        setActiveStep(0);
        dispatch(FetchSuppliers(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const deleteSuppliers = () => {
    dispatch(DeleteSuppliers(ids))
      .then(async (res: SupplierResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Supplier successfully deleted"
            message={res?.message}
          />,
          true
        );
        dispatch(FetchSuppliers(paginationData));
        setSelectedRowKeys([]);
        setSelectedItems([]);
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchSuppliers(paginationData));
  }, [dispatch, paginationData]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={true}
        title={"Suppliers"}
        subtitle={"All suppliers"}
        placeholder={"Search"}
        buttonText={"New supplier"}
        getSearchValue={setSearch}
        handleClick={handleClick}
      />
      <div className="default-page-height w-full">
        {suppliers.length ? (
          <AllSuppliers
            viewSupplier={handleViewSupplier}
            deleteSuppliers={handleDeleteSuppliers}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) : (
          <EmptyContent
            label={"Seems you haven’t any supplier yet. Want to add one?"}
            component={
              <img
                src={QuicstockSupplier}
                alt={"QuicstockSupplier"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Add new supplier"}
            handlePrimaryButtonClick={() => {
              setActiveStep(0);
              toggleNewSupplierDrawer();
            }}
          />
        )}
      </div>

      {/* Add supplier */}
      <AppDrawer
        open={openNewSupplierDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleNewSupplierDrawer();
        }}
        title={"Add supplier"}
        subtitle={"Add a new supplier"}
      >
        <AddSupplier
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleNewSupplierDrawer}
          activeStep={activeStep}
          supplier={supplier}
        />
      </AppDrawer>
      {/* Add supplier */}

      {/* View supplier */}
      <AppDrawer
        open={openViewSupplierDrawer}
        toggle={toggleViewSupplierDrawer}
        title={"View supplier’s details"}
        subtitle={supplier.name}
      >
        <ViewSupplier
          cancel={toggleViewSupplierDrawer}
          edit={handleOpenEditModal}
          supplier={supplier}
        />
      </AppDrawer>
      {/* View supplier */}

      {/* Edit supplier */}
      <AppDrawer
        open={openEditSupplierDrawer}
        toggle={toggleEditSupplierDrawer}
        title={"Edit supplier’s details"}
        subtitle={supplier.name}
      >
        <EditSupplier
          submit={handleSubmit}
          cancel={toggleEditSupplierDrawer}
          supplier={supplier}
        />
      </AppDrawer>
      {/* Edit supplier */}

      <Confirm
        title="Delete Suppliers"
        confirmButtonText="Delete"
        open={open}
        cancel={() => setOpen(false)}
        confirm={deleteSuppliers}
      />
    </div>
  );
};

export default Suppliers;
