import React, { FC } from "react";
import "../styles.scss";
import { IAppStepper } from "../model";
import { ReactComponent as QuicstockActiveStepOne } from "../../assets/svgs/quicstock-active-step-one.svg";
import { ReactComponent as QuicstockInactiveStepTwo } from "../../assets/svgs/quicstock-inactive-step-two.svg";
import { ReactComponent as QuicstockActiveStepTwo } from "../../assets/svgs/quicstock-active-step-two.svg";

const AppStepper: FC<IAppStepper> = ({
  activeStep,
  stepOneLabel,
  stepTwoLabel,
}) => {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="app-stepper">
        <div className="app-steppers">
          <div className="mr-3">
            <QuicstockActiveStepOne />
          </div>
          <div
            className={`w-full ${activeStep === 0 ? "app-step-one-link" : "app-step-two-link"}`}
          ></div>
          <div className="ml-3">
            {activeStep === 0 ? (
              <QuicstockInactiveStepTwo />
            ) : (
              <QuicstockActiveStepTwo />
            )}
          </div>
        </div>
        <div className="app-steppers-label">
          <div className="text-left">
            <p className="text-gray-300 text-x-small">STEP 1</p>
            <p className="text-sm-regular mt-2 font-medium">{stepOneLabel}</p>
          </div>
          <div className="text-right">
            <p className="text-gray-300 text-x-small">STEP 2</p>
            <p className="text-sm-regular mt-2 font-medium">{stepTwoLabel}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStepper;
