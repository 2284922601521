import React, { FC, useEffect, useState } from "react";
import "../styles.scss";
import { ICustomCheckbox } from "../model";
import { ReactComponent as QuicstockCustomChecked } from "../../assets/svgs/quicstock-custom-checked.svg";
import { ReactComponent as QuicstockCustomNoCheck } from "../../assets/svgs/quicstock-custom-no-check.svg";

const CustomCheckbox: FC<ICustomCheckbox> = ({
  onChange,
  children,
  placeBefore = false,
  checked = false,
  useParentValue = false,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleClick = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  useEffect(() => {
    if (useParentValue) {
      setIsChecked(checked);
    }
  }, [useParentValue, checked]);

  return (
    <div
      className="flex items-center gap-x-2 cursor-pointer"
      onClick={handleClick}
    >
      {placeBefore ? children : <></>}
      {(useParentValue && checked) ||
      (!useParentValue && (checked || isChecked)) ? (
        <QuicstockCustomChecked />
      ) : (
        <QuicstockCustomNoCheck />
      )}
      {placeBefore ? <></> : children}
    </div>
  );
};

export default CustomCheckbox;
