import React, { FC } from "react";
import "../../styles.scss";
import { IAllCustomProperties } from "../../model";
import QuickGoldenMorn from "../../../../assets/imgs/quicstock-golden-morn.png";
import { ReactComponent as QuicstockEdit } from "../../../../assets/svgs/quicstock-edit.svg";
import { ReactComponent as QuicstockDelete } from "../../../../assets/svgs/quicstock-delete.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { CustomPropertyModel } from "../../../../model/product.model";
import Loader from "../../../../components/Loader";

const AllCustomProperties: FC<IAllCustomProperties> = ({
  editCustomProperty,
  deleteCustomProperty,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const cp = useSelector((state: RootState) => state.products.cp);

  return (
    <div className="w-full bg-white app-table pb-4 overflow-y-auto mt-10">
      <Loader isLoading={isLoading} />
      <div className="border border-dark-200 rounded-[10px] pb-10 xl:px-16 sm:px-8 px-2 w-full">
        {(cp?.number?.length || 0) > 0 && (
          <PropertyPerType
            type="Number"
            customProperties={cp?.number ?? []}
            editCustomProperty={editCustomProperty}
            deleteCustomProperty={deleteCustomProperty}
          />
        )}
        <div className="px-4">
          <div className="border-t border-t-gray-800 w-full mt-2"></div>
        </div>
        {(cp?.string?.length || 0) > 0 && (
          <PropertyPerType
            type="Text"
            customProperties={cp?.string ?? []}
            editCustomProperty={editCustomProperty}
            deleteCustomProperty={deleteCustomProperty}
          />
        )}
        <div className="px-4">
          <div className="border-t border-t-gray-800 w-full mt-2"></div>
        </div>
        {(cp?.date?.length || 0) > 0 && (
          <PropertyPerType
            type="Date"
            customProperties={cp?.date ?? []}
            editCustomProperty={editCustomProperty}
            deleteCustomProperty={deleteCustomProperty}
          />
        )}
      </div>
    </div>
  );
};

const PropertyPerType: FC<{
  type: string;
  customProperties: CustomPropertyModel[];
  editCustomProperty: (cp: CustomPropertyModel) => void;
  deleteCustomProperty: (cp: CustomPropertyModel) => void;
}> = ({ type, customProperties, editCustomProperty, deleteCustomProperty }) => {
  return (
    <div className="border border-dark-200 rounded-[10px] p-4 relative xl:mt-14 sm:mt-12 mt-8">
      <div className="absolute right-0 left-0 px-2 top-[-16px] w-fit">
        <div className="bg-white flex justify-between items-center px-2 xl:text-sm-headline sm:text-sm-regular text-labels">
          <div className="flex gap-x-6 items-center">
            <p className="text-gray-300">Type</p>
            <p className="font-medium">{type}</p>
          </div>
        </div>
      </div>

      {customProperties.map((c, index) => (
        <React.Fragment key={(c.product_name ?? "") + index}>
          <SingleProperty
            customProperty={c}
            editCustomProperty={editCustomProperty}
            deleteCustomProperty={deleteCustomProperty}
          />
          {index < customProperties.length - 1 && (
            <div className="border-t border-t-gray-800 w-full mt-4"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const SingleProperty: FC<{
  customProperty: CustomPropertyModel;
  editCustomProperty: (cp: CustomPropertyModel) => void;
  deleteCustomProperty: (cp: CustomPropertyModel) => void;
}> = ({ customProperty, editCustomProperty, deleteCustomProperty }) => {
  return (
    <div className="flex flex-wrap">
      <div className="w-10/12 flex flex-col gap-y-4 xl:mt-8 mt-4 xl:text-sm-headline sm:text-sm-regular text-labels">
        <div className="flex items-center gap-x-8">
          <p className="font-semibold">
            <span className="font-normal text-gray-300 mr-2">Name:</span>
            {customProperty.property_name}
          </p>
          <p className="font-semibold">
            <span className="font-normal text-gray-300 mr-2">Value:</span>
            {customProperty.property_value}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <img
            src={QuickGoldenMorn}
            alt="QuickGoldenMorn"
            className="h-[42px]"
          />
          <p className="font-medium xl:text-sm-headline sm:text-sm-regular text-labels">
            {customProperty.product_name}
          </p>
        </div>
        {!customProperty.is_global ? (
          <div className="flex flex-wrap gap-4">
            {customProperty.po_numbers &&
              customProperty.po_numbers.map((pn, index) => (
                <p
                  key={index + (pn ?? "")}
                  className="text-labels font-medium px-4 py-2 bg-dark-300 rounded-[24px]"
                >
                  {pn}
                </p>
              ))}
          </div>
        ) : (
          <div className="text-secondary-500 bg-secondary-100 rounded-[32px] py-1 px-3 xl:text-sm-regular sm:text-labels text-x-small font-medium w-fit">
            Global
          </div>
        )}
      </div>
      <div className="w-2/12 flex justify-end">
        <div className="flex gap-x-2 items-center">
          <button
            onClick={() => {
              editCustomProperty(customProperty);
            }}
          >
            <QuicstockEdit className="sm:h-auto sm:w-auto h-[30px] w-[30px]" />
          </button>
          <button
            onClick={() => {
              deleteCustomProperty(customProperty);
            }}
          >
            <QuicstockDelete className="sm:h-auto sm:w-auto h-[30px] w-[30px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export { AllCustomProperties };
