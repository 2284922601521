import { Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllPurchases } from "../../model";
import {
  formatCurrency,
  itemRender,
  NAIRA,
  paginationFooter,
  trimAll,
} from "../../../../utils/constants";
import TableActions from "../../../../components/TableActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import moment from "moment";

const AllPurchases: FC<IAllPurchases> = ({
  viewProduct,
  handleImport,
  paginationData,
  setPaginationData,
  setSelectedItems,
  selectedItems,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.pos.isPoActionLoading
  );
  const allPOs = useSelector((state: RootState) => state.pos.allPOs);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const columns = [
    {
      title: "Purchase order number",
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "Delivery date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
    },
    {
      title: `Total amount (${NAIRA})`,
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const data =
    allPOs &&
    allPOs?.allPOs?.length > 0 &&
    allPOs?.allPOs?.map((po, index) => {
      return {
        key: index,
        orderNumber: po.po_number ?? "---",
        supplier: po?.supplier ?? "---",
        deliveryDate: po?.edd ? moment(po?.edd).format("DD/MM/YYYY") : "---",
        totalAmount: po?.total_amount
          ? `${formatCurrency(po?.total_amount?.toString())}`
          : "---",
        status: po?.status ? (
          <div className={`status ${trimAll(po?.status.toLowerCase())}`}>
            {po?.status}
          </div>
        ) : (
          "---"
        ),
        id: po.id,
        po: po,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      itemsPerPage: pageSize,
      page: current,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedItems(selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleExport = () => {};
  const handleFilter = () => {};
  const handleDelete = () => {};

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleImport={handleImport}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDelete={handleDelete}
        disableDelete={selectedItems.length === 0}
      />
      <div className="w-full bg-white filter-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table add-pointer"}
          onRow={(item) => {
            return {
              onClick: () => {
                viewProduct && viewProduct(item);
              },
            };
          }}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: allPOs?.total_count,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              paginationFooter(paginationData, allPOs?.total_count, total),
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                allPOs?.total_count,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { AllPurchases };
