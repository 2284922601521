import React from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";

const Settings = () => {
  return (
    <div className="w-full h-full">
      <div className="w-full flex justify-between gap-x-8">
        <PageHeader
          leftHeaderType={LeftHeaderType.CUSTOM}
          showSearchField={false}
          title={"Settings"}
          subtitle={"Here are your alert types"}
        />
      </div>
    </div>
  );
};

export default Settings;
