/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ICustomPropertyPO, IEditCustomPropertyForm } from "../../model";
import { Field, Formik } from "formik";
import { DatePicker, Form, Input, Select, Spin } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import { VALUE_TYPES } from "../../../../utils/constants";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import { ReactComponent as QuicstockDot } from "../../../../assets/svgs/quicstock-dot.svg";
import { CustomPropertyModel } from "../../../../model/product.model";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { AppDispatch } from "../../../../redux/store";
import { FetchProductPOs } from "../../../../redux/actions/products.action";

const EditCustomPropertyForm: FC<IEditCustomPropertyForm> = ({
  submit,
  cancel,
  customProperty,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const isProductPosLoading = useSelector(
    (state: RootState) => state.products.isProductPosLoading
  );
  const productPos = useSelector(
    (state: RootState) => state.products.productPos
  );
  const [isGlobal, setIsGlobal] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [customProductPos, setCustomProductPos] = useState<ICustomPropertyPO[]>(
    []
  );

  useEffect(() => {
    setValues({
      property_name: customProperty.property_name,
      property_type: customProperty.property_type,
      property_value:
        customProperty.property_type === "date"
          ? (dayjs(new Date(customProperty.property_value)) as any)
          : customProperty.property_value,
    });
  }, [customProperty]);

  const [values, setValues] = useState<CustomPropertyModel>({
    property_name: "",
    property_type: "",
    property_value: "",
  });

  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    const poIds = customProductPos
      .filter((po) => po.is_checked)
      .map((p) => p.po_number);
    if (poIds.length === 0 && !isGlobal) {
      setError("Set property as global or select at least one PO");
      return;
    }
    submit({
      ...values,
      isGlobal,
      poIds,
    });
  };

  const onChange = (is_checked: boolean) => {
    if (is_checked) {
      const pos = customProductPos.map((po) => {
        return { ...po, is_checked: false };
      });
      setCustomProductPos(pos);
    }
    setError("");
    setIsGlobal(is_checked);
  };

  const handleSelectedPo = (is_checked: boolean, poId: number) => {
    setIsGlobal(false);
    const updatedPos = customProductPos.map((po) =>
      po.id === poId ? { ...po, is_checked } : po
    );
    setError("");
    setCustomProductPos(updatedPos);
  };

  useEffect(() => {
    dispatch(FetchProductPOs(customProperty?.product_id ?? ""));
    setIsGlobal(customProperty?.is_global ?? false);
  }, [customProperty]);

  useEffect(() => {
    const ppo: ICustomPropertyPO[] = productPos.map((p, index) => {
      return {
        id: index + 1,
        po_number: p?.po_number ?? "0",
        quantity: p?.quantity ?? 0,
        is_checked:
          customProperty.po_numbers?.includes(p?.po_number ?? "") ?? false,
      };
    });
    setCustomProductPos(ppo);
  }, [productPos, customProperty]);

  return (
    <div className="w-full h-full">
      <Formik
        className="w-full"
        initialValues={values}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
        validationSchema={validator}
      >
        {({ values, errors, touched, handleSubmit, handleBlur }) => (
          <Form
            onFinish={handleSubmit}
            form={form}
            className="h-full flex flex-col justify-between"
          >
            <div className={`w-full drawer-content-height-no-step`}>
              <h2 className="text-sm-headline mb-2">Custom property</h2>
              <div className={"mb-5"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"property_name"}
                    help={
                      touched.property_name && errors.property_name
                        ? errors.property_name
                        : ""
                    }
                    validateStatus={
                      touched.property_name && errors.property_name
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          value={values?.property_name}
                          onChange={(e) => {
                            setFieldValue("property_name", e.target.value);
                          }}
                          onBlur={(e) => {
                            setFieldValue("property_name", e.target.value);
                          }}
                          placeholder="Property name"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-5 col-span-3"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"property_type"}
                    help={
                      touched.property_type && errors.property_type
                        ? errors.property_type
                        : ""
                    }
                    validateStatus={
                      touched.property_type && errors.property_type
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Select
                          placeholder={"Value type"}
                          value={values?.property_type || null}
                          onBlur={handleBlur}
                          className={"form-field_input_3"}
                          onChange={(e: any) => {
                            setFieldValue("property_value", "");
                            setFieldValue("property_type", e);
                          }}
                          options={VALUE_TYPES}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              {values.property_type && (
                <div className={"mb-5"}>
                  {values.property_type === "string" && (
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"property_value"}
                        help={
                          touched.property_value && errors.property_value
                            ? errors.property_value
                            : ""
                        }
                        validateStatus={
                          touched.property_value && errors.property_value
                            ? "error"
                            : "success"
                        }
                      >
                        <Field>
                          {({ form: { setFieldValue } }: any) => (
                            <Input
                              type={"text"}
                              className={"form-field_input_2"}
                              value={values?.property_value as string}
                              onChange={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              onBlur={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              placeholder="Property value"
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </FieldContainer>
                  )}
                  {values.property_type === "number" && (
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"property_value"}
                        help={
                          touched.property_value && errors.property_value
                            ? errors.property_value
                            : ""
                        }
                        validateStatus={
                          touched.property_value && errors.property_value
                            ? "error"
                            : "success"
                        }
                      >
                        <Field>
                          {({ form: { setFieldValue } }: any) => (
                            <Input
                              type={"number"}
                              className={"form-field_input_2"}
                              value={values?.property_value as string}
                              onChange={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              onBlur={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              placeholder="Property value"
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </FieldContainer>
                  )}
                  {values.property_type === "date" && (
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"property_value"}
                        help={
                          touched.property_value && errors.property_value
                            ? errors.property_value
                            : ""
                        }
                        validateStatus={
                          touched.property_value && errors.property_value
                            ? "error"
                            : "success"
                        }
                      >
                        <Field>
                          {({ form: { setFieldValue } }: any) => (
                            <DatePicker
                              className={"form-field_input_2"}
                              placeholder={"Property value"}
                              value={values?.property_value || null}
                              format="YYYY-MM-DD"
                              onChange={(e) => {
                                setFieldValue("property_value", e);
                              }}
                              onOk={(e) => {
                                setFieldValue("property_value", e);
                              }}
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </FieldContainer>
                  )}
                </div>
              )}
              <h3 className="font-medium text-md-headline">
                Selected purchase orders
              </h3>
              <p className="font-medium text-sm-regular text-gray-300 mt-1">
                You can remove a purchase order that you previously added
              </p>
              <div className="p-3 bg-dark-700 rounded-[8px] mt-4">
                <CustomCheckbox
                  onChange={onChange}
                  useParentValue={true}
                  checked={isGlobal}
                >
                  <p className="font-medium">
                    Make this property a global property
                  </p>
                </CustomCheckbox>
              </div>
              {isProductPosLoading ? (
                <div className="h-[100px] w-full justify-center items-center flex">
                  <Spin size="small" />
                </div>
              ) : customProductPos.length > 0 ? (
                <div className="mt-3 flex flex-wrap gap-3">
                  {customProductPos.map((po) => (
                    <PropertyPO
                      key={po.id}
                      checked={po.is_checked}
                      onChange={handleSelectedPo}
                      po={po}
                    />
                  ))}
                </div>
              ) : (
                <></>
              )}
              <p className="text-labels mt-2 text-error-common">{error}</p>
            </div>
            <div className="w-full left-0 right-0 bottom-0 bg-white">
              <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
              <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
                <div className={"col-1 sm:block hidden"}></div>
                <div className={"col-1"}>
                  <Button
                    css={
                      "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                    }
                    state={ButtonState.PRIMARY}
                    text={"Cancel"}
                    type={"button"}
                    onClick={cancel}
                  />
                </div>
                <div className={"col-1"}>
                  <Button
                    css={"w-full !px-0 !rounded-[6px]"}
                    state={ButtonState.PRIMARY}
                    text={"Save changes"}
                    type={"submit"}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const PropertyPO: FC<{
  onChange: (checked: boolean, poId: number) => void;
  checked: boolean;
  po: ICustomPropertyPO;
}> = ({ onChange, checked, po }) => {
  return (
    <div className="p-3 bg-dark-200 rounded-[8px]">
      <CustomCheckbox
        checked={checked}
        onChange={(is_checked) => onChange(is_checked, po.id)}
        placeBefore={true}
        useParentValue={true}
      >
        <div className="flex items-center gap-x-2">
          <p className="font-medium">{po.po_number}</p>
          <QuicstockDot className="product-separator-secondary" />
          <p className="text-labels text-link-green font-medium">
            {po.quantity}
          </p>
        </div>
      </CustomCheckbox>
    </div>
  );
};

const validator = yup.object().shape({
  property_name: yup
    .string()
    .min(3, "Property name should be minimum of 6 characters")
    .required("Property name is required"),
  property_type: yup.string().required("Value type is required"),
  property_value: yup.string().required("Value is required"),
});

export { EditCustomPropertyForm };
