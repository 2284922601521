import { Types } from "../actions/auths.action";

interface AuthState {
  isAuthActionLoading: boolean;
  message?: string;
}

interface AuthAction {
  type: string;
  message?: string;
}

const INITIAL_STATE: AuthState = {
  isAuthActionLoading: false,
};

export const authReducer = (state = INITIAL_STATE, action: AuthAction) => {
  switch (action.type) {
    case Types.AUTH_START:
      return { ...state, isAuthActionLoading: true };
    case Types.AUTH_SUCCESS:
      return {
        ...state,
        isAuthActionLoading: false,
      };
    case Types.AUTH_END:
      return { ...state, isAuthActionLoading: false, message: action.message };
    default:
      return state;
  }
};
