import React, { useState } from "react";
import "../styles.scss";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockPackage from "../../../assets/imgs/quicstock-package.png";
import { IUser } from "../model";
import { AllLogs } from "./components";

const UserLogsTab = () => {
  const [users] = useState<IUser[]>([
    { id: 1, name: "", staffId: "", mobileNumber: "", status: "", role: "" },
  ]);

  return (
    <div className="w-full h-full">
      <div className="default-page-height w-full">
        {users.length ? (
          <AllLogs />
        ) : (
          <EmptyContent
            label={"You have no logs yet"}
            component={
              <img
                src={QuicstockPackage}
                alt={"QuicstockPackage"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={false}
          />
        )}
      </div>
    </div>
  );
};

export default UserLogsTab;
