import React, { useState } from "react";
import { Form, Input } from "antd";
import { AuthHeading } from "../components/AuthHeading";
import { Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Input/FIeldContainer";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { ButtonState } from "../../../components/enum";
import { AuthBottomNode } from "../components/AuthBottomNode";

const Signup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [values] = useState({
    username: "",
  });

  const signup = (values: any) => {
    navigate(`/otp?mode=EMAIL&email=${values?.username}`);
  };

  return (
    <div className="px-4 sm:px-32 md:px-48 lg:px-16 xl:px-32 xl:max-w-2xl w-full">
      <div className="text-left gap-y-6 flex flex-col">
        <AuthHeading subHeading={"Create Account"} />
        <Formik
          initialValues={values}
          onSubmit={signup}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"username"}
                    help={
                      touched.username && errors.username ? errors.username : ""
                    }
                    validateStatus={
                      touched.username && errors.username ? "error" : "success"
                    }
                  >
                    <Input
                      type={"email"}
                      className={"form-field_input_2"}
                      value={values?.username || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Email Address"
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={ButtonState.PRIMARY}
                  text={"Create Account"}
                  type={"submit"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <AuthBottomNode
        label={"Already have an account?"}
        route={"/login"}
        routeLabel={"Sign In"}
      />
    </div>
  );
};

const validator = yup.object().shape({
  username: yup.string().required("Email is required"),
});

export default Signup;
