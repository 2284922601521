import React, { FC } from "react";
import "../../styles.scss";
import { IAddLocation } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { LocationForm } from "./LocationForm";
import { LocationDetails } from "./LocationDetails";

const AddLocation: FC<IAddLocation> = ({
  submit,
  goBack,
  complete,
  cancel,
  state,
  activeStep,
  location,
  type,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Create new"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <LocationForm submit={submit} cancel={cancel} type={type} />
        </div>
        <div hidden={activeStep === 0}>
          <LocationDetails
            location={location}
            state={state}
            goBack={goBack}
            complete={complete}
            type={type}
          />
        </div>
      </div>
    </div>
  );
};

export { AddLocation };
