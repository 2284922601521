import React, { FC } from "react";
import "../styles.scss";
import { IBack } from "../model";
import { useNavigate } from "react-router-dom";
import { ReactComponent as QuicstockModalClose } from "../../assets/svgs/quicstock-modal-close.svg";

const Back: FC<IBack> = ({ isPrevious }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    isPrevious ? window.history.back() : navigate("/dashboard");
  };
  return (
    <button onClick={handleClick}>
      <QuicstockModalClose />
    </button>
  );
};

export default Back;
