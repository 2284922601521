/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AuthHeading } from "../components/AuthHeading";
import { Formik } from "formik";
import * as yup from "yup";
import PasswordField from "../../../components/Input/PasswordField";
import FieldContainer from "../../../components/Input/FIeldContainer";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { ButtonState } from "../../../components/enum";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../../redux/actions/auths.action";
import { AppDispatch } from "../../../redux/store";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import { RootState } from "../../../redux/reducers";
import { LoginModel, LoginResponseModel } from "../../../model/auth.model";

const Login = () => {
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isLoading = useSelector(
    (state: RootState) => state.auths.isAuthActionLoading
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [values, setValues] = useState<LoginModel>({
    email: "",
    password: "",
    isStore: true,
  });

  const login = (values: LoginModel) => {
    dispatch(LoginUser(values))
      .then(async (res: LoginResponseModel) => {
        const accessToken = res.data.token;
        const refreshToken = res.data.refreshToken;
        localStorage.setItem("quicstock_app_token", accessToken);
        localStorage.setItem("quicstock_app_refresh_token", refreshToken);
        localStorage.setItem("quicstock_app_login", values?.email);
        openNotification(
          <NotificationMessage
            type="success"
            title="Login successful"
            message="You are logged in successfully"
          />,
          true
        );
        // await dispatch(FetchCurrentUser()).then((res) => {
        //   localStorage.setItem("quicstock_app_user", JSON.stringify(res.data.object));
        // });
        navigate("/dashboard");
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message="Invalid username or password"
          />,
          true
        );
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const email = localStorage.getItem("quicstock_app_login");
    if (email !== null && email !== "") {
      form.setFieldsValue({ ...values, email });
      setValues({ ...values, email });
    }
  }, []);

  return (
    <div className="px-4 sm:px-32 md:px-48 lg:px-16 xl:px-32 lg:max-w-2xl w-full">
      <div className="text-left gap-y-6 flex flex-col">
        <AuthHeading subHeading={"Sign In"} />
        <Formik
          initialValues={values}
          onSubmit={login}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"email"}
                    help={touched.email && errors.email ? errors.email : ""}
                    validateStatus={
                      touched.email && errors.email ? "error" : "success"
                    }
                  >
                    <Input
                      type={"email"}
                      className={"form-field_input_2"}
                      value={values?.email || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter Address"
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <PasswordField
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}
                >
                  <Form.Item
                    name={"password"}
                    help={
                      touched.password && errors.password ? errors.password : ""
                    }
                    validateStatus={
                      touched.password && errors.password ? "error" : "success"
                    }
                  >
                    <Input
                      type={showPassword ? "text" : "password"}
                      className={"form-field_input_2"}
                      value={values?.password || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Password"
                    />
                  </Form.Item>
                </PasswordField>
              </div>
              <div className={"flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={ButtonState.PRIMARY}
                  text={"Log In"}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default Login;
