import React, { FC } from "react";
import "../../styles.scss";
import { IProductPurchaseOrder } from "../../model";
import { formatCurrency, NAIRA } from "../../../../utils/constants";

const ProductPurchaseOrder: FC<IProductPurchaseOrder> = ({ po }) => {
  return (
    <div className="w-full border-[1px] border-[#F9F9F9] rounded-[8px] p-3 mb-4">
      <p className="xl:text-sm-regular text-labels">
        Purchase Order <span className="ml-2">{po?.po_number}</span>
      </p>

      <div className="flex items-center justify-between mt-2 flex-wrap">
        <p className="text-gray-300 text-labels">
          Quantity:{" "}
          <span className="text-black text-labels">{po?.quantity}</span>
        </p>
        <p className="text-gray-300 text-labels">
          Unit price:{" "}
          <span className="text-black text-labels">{`${NAIRA}${formatCurrency(po?.unit_price?.toString())}`}</span>
        </p>
      </div>
    </div>
  );
};

export { ProductPurchaseOrder };
