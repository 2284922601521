import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAddCustomPropertyField } from "../../model";
import { Field, Formik } from "formik";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { DatePicker, Form, Input, Select } from "antd";
import * as yup from "yup";
import { VALUE_TYPES } from "../../../../utils/constants";
import { ReactComponent as QuicstockAdd } from "../../../../assets/svgs/quicstock-add.svg";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { RootState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";
import { CustomPropertyModel } from "../../../../model/product.model";

const AddCustomPropertyField: FC<IAddCustomPropertyField> = ({
  submit,
  propertyProductItem,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const [values] = useState<CustomPropertyModel>({
    property_name: "",
    property_type: "",
    property_value: "",
  });

  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    submit(values);
  };

  return (
    <div className="w-full h-full">
      <h2 className="text-sm-headline mb-2">New property</h2>
      <Formik
        className="w-full"
        initialValues={values}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
        validationSchema={validator}
      >
        {({ values, errors, touched, handleSubmit, handleBlur }) => (
          <Form
            onFinish={handleSubmit}
            form={form}
            className="h-full flex flex-col justify-between"
          >
            <div className={`w-full`}>
              <div className={"mb-5"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"property_name"}
                    help={
                      touched.property_name && errors.property_name
                        ? errors.property_name
                        : ""
                    }
                    validateStatus={
                      touched.property_name && errors.property_name
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          value={values?.property_name}
                          onChange={(e) => {
                            setFieldValue("property_name", e.target.value);
                          }}
                          onBlur={(e) => {
                            setFieldValue("property_name", e.target.value);
                          }}
                          placeholder="Property name"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-5 col-span-3"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"property_type"}
                    help={
                      touched.property_type && errors.property_type
                        ? errors.property_type
                        : ""
                    }
                    validateStatus={
                      touched.property_type && errors.property_type
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Select
                          placeholder={"Value type"}
                          value={values?.property_type || null}
                          onBlur={handleBlur}
                          className={"form-field_input_3"}
                          onChange={(e: any) => {
                            setFieldValue("property_value", "");
                            setFieldValue("property_type", e);
                          }}
                          options={VALUE_TYPES}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              {values.property_type && (
                <div className={"mb-5"}>
                  {values.property_type === "string" && (
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"property_value"}
                        help={
                          touched.property_value && errors.property_value
                            ? errors.property_value
                            : ""
                        }
                        validateStatus={
                          touched.property_value && errors.property_value
                            ? "error"
                            : "success"
                        }
                      >
                        <Field>
                          {({ form: { setFieldValue } }: any) => (
                            <Input
                              type={"text"}
                              className={"form-field_input_2"}
                              value={values?.property_value as string}
                              onChange={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              onBlur={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              placeholder="Property value"
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </FieldContainer>
                  )}
                  {values.property_type === "number" && (
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"property_value"}
                        help={
                          touched.property_value && errors.property_value
                            ? errors.property_value
                            : ""
                        }
                        validateStatus={
                          touched.property_value && errors.property_value
                            ? "error"
                            : "success"
                        }
                      >
                        <Field>
                          {({ form: { setFieldValue } }: any) => (
                            <Input
                              type={"number"}
                              className={"form-field_input_2"}
                              value={values?.property_value as string}
                              onChange={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              onBlur={(e) => {
                                setFieldValue("property_value", e.target.value);
                              }}
                              placeholder="Property value"
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </FieldContainer>
                  )}
                  {values.property_type === "date" && (
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"property_value"}
                        help={
                          touched.property_value && errors.property_value
                            ? errors.property_value
                            : ""
                        }
                        validateStatus={
                          touched.property_value && errors.property_value
                            ? "error"
                            : "success"
                        }
                      >
                        <Field>
                          {({ form: { setFieldValue } }: any) => (
                            <DatePicker
                              className={"form-field_input_2"}
                              placeholder={"Property value"}
                              value={values?.property_value || ""}
                              onChange={(e) => {
                                setFieldValue("property_value", e);
                              }}
                              onOk={(e) => {
                                setFieldValue("property_value", e);
                              }}
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </FieldContainer>
                  )}
                </div>
              )}
            </div>
            <div className="w-full pb-10">
              <div className="w-full">
                <p>Click on the tile below to add new property</p>
                <button
                  type="button"
                  className="w-full border border-dark-200 rounded-[10px] px-2 py-3 flex justify-between mt-2 items-center"
                >
                  <p>Add item</p>
                  <QuicstockAdd />
                </button>
              </div>
              <Button
                css={"w-full h-[42px] !px-10 !py-6 !rounded-[6px] mt-8"}
                state={ButtonState.SECONDARY}
                text={"Save new properties"}
                type={"submit"}
                disabled={
                  !propertyProductItem.productId ||
                  (!propertyProductItem.isGlobal &&
                    propertyProductItem.poIds.length === 0)
                }
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const validator = yup.object().shape({
  property_name: yup
    .string()
    .min(3, "Property name should be minimum of 6 characters")
    .required("Property name is required"),
  property_type: yup.string().required("Property value type is required"),
  property_value: yup.string().required("Property value is required"),
});

export { AddCustomPropertyField };
