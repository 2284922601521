import { AlertItemModel } from "../../model/alerts.model";
import { Types } from "../actions/alerts.action";

interface AlertsState {
  isAlertsActionLoading: boolean;
  restockAlerts: AlertItemModel[];
  reorderAlerts: AlertItemModel[];
}

interface AlertsAction {
  type: string;
  message?: string;
  restockAlerts: AlertItemModel[];
  reorderAlerts: AlertItemModel[];
}

const INITIAL_STATE: AlertsState = {
  isAlertsActionLoading: false,
  restockAlerts: [],
  reorderAlerts: [],
};

export const alertsReducer = (state = INITIAL_STATE, action: AlertsAction) => {
  switch (action.type) {
    case Types.CREATE_ALERT_START:
    case Types.FETCH_RESTOCK_ALERT_START:
    case Types.FETCH_REORDER_ALERT_START:
    case Types.UPDATE_ALERT_START:
    case Types.DELETE_ALERT_START:
      return { ...state, isAlertsActionLoading: true };
    case Types.CREATE_ALERT_SUCCESS:
    case Types.UPDATE_ALERT_SUCCESS:
    case Types.DELETE_ALERT_SUCCESS:
      return {
        ...state,
        isAlertsActionLoading: false,
      };
    case Types.FETCH_RESTOCK_ALERT_SUCCESS:
      return {
        ...state,
        isAlertsActionLoading: false,
        restockAlerts: action.restockAlerts ?? [],
      };
    case Types.FETCH_REORDER_ALERT_SUCCESS:
      return {
        ...state,
        isAlertsActionLoading: false,
        reorderAlerts: action.reorderAlerts ?? [],
      };
    case Types.CREATE_ALERT_END:
    case Types.FETCH_RESTOCK_ALERT_END:
    case Types.FETCH_REORDER_ALERT_END:
    case Types.UPDATE_ALERT_END:
    case Types.DELETE_ALERT_END:
      return {
        ...state,
        isAlertsActionLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};
