/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IEditSellingDetails } from "../../model";
import { Form, Input, Radio } from "antd";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import FieldName from "../../../../components/FieldName";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { formatCurrency, NAIRA } from "../../../../utils/constants";
import QuicstockNaira from "../../../../assets/svgs/quicstock-naira.svg";
import openNotification from "../../../../components/Notification/notification";
import NotificationMessage from "../../../../components/Notification";
import { SellingRetailPriceModel } from "../../../../model/product.model";

const EditSellingDetails: FC<IEditSellingDetails> = ({ cancel, save }) => {
  const product = useSelector((state: RootState) => state.products.product);
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const [values, setValues] = useState<SellingRetailPriceModel>({
    costPrice: "",
    sellingPrice: "",
    mode: "",
    percentage: "",
    amount: "",
  });
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    if (values.mode === "0") {
      if (
        !values.percentage ||
        parseFloat(values.percentage.toString()) === 0
      ) {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message="Enter a valid percentage"
          />,
          true
        );
        return;
      }
    }
    if (values.mode === "1") {
      if (
        !values.amount ||
        parseFloat(values.amount.split(",").join("").toString()) === 0
      ) {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message="Enter a valid amount"
          />,
          true
        );
        return;
      }
    }
    save(values);
  };

  useEffect(() => {
    if (product) {
      setValues({
        ...values,
        costPrice: formatCurrency(product.pricing?.retail_price?.toString()),
        sellingPrice:
          parseFloat(product?.pricing?.average_cost_price?.toString() ?? "") ===
          0
            ? formatCurrency(product.pricing?.retail_price?.toString())
            : formatCurrency(product.pricing?.average_cost_price?.toString()),
      });
    }
  }, [product]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={"w-full drawer-content-height-no-step"}>
            <div className={"mb-5 mt-10"}>
              <FieldName name={"Average cost price"} />
              <p>
                {NAIRA}
                {formatCurrency(
                  product.pricing?.average_cost_price?.toString()
                )}
              </p>
            </div>
            <div className={"mb-5 mt-10"}>
              <FieldContainer
                iconPlacementCss={"pricingFieldIcon"}
                icon={QuicstockNaira}
              >
                <FieldName name={"Cost price"} required />
                <Form.Item
                  name={"costPrice"}
                  help={
                    touched.costPrice && errors.costPrice
                      ? errors.costPrice
                      : ""
                  }
                  validateStatus={
                    touched.costPrice && errors.costPrice ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.costPrice}
                        onChange={(e) => {
                          setFieldValue("costPrice", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("costPrice", e.target.value);
                        }}
                        placeholder="0.00"
                        disabled
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer
                iconPlacementCss={"pricingFieldIcon"}
                icon={QuicstockNaira}
              >
                <FieldName name={"Selling price"} required />
                <Form.Item
                  name={"sellingPrice"}
                  help={
                    touched.sellingPrice && errors.sellingPrice
                      ? errors.sellingPrice
                      : ""
                  }
                  validateStatus={
                    touched.sellingPrice && errors.sellingPrice
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.sellingPrice || ""}
                        onChange={(e) => {
                          setFieldValue("sellingPrice", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("sellingPrice", e.target.value);
                        }}
                        placeholder="0.00"
                        disabled
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <Form.Item
                  name={"mode"}
                  help={touched.mode && errors.mode ? errors.mode : ""}
                  validateStatus={
                    touched.mode && errors.mode ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Radio.Group
                        style={{
                          fontSize: "16px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        value={values?.mode || ""}
                        onChange={(e) => {
                          setFieldValue("mode", e.target.value);
                          setFieldValue("sellingPrice", values.costPrice);
                          setFieldValue("amount", "");
                          setFieldValue("percentage", "");
                        }}
                      >
                        <div className="w-full">
                          <Radio style={{ padding: "8px 0px" }} value="0">
                            <div className="flex items-center gap-x-10">
                              <p>Percentage:</p>
                              <Form.Item
                                name={"percentage"}
                                help={
                                  touched.percentage && errors.percentage
                                    ? errors.percentage
                                    : ""
                                }
                                validateStatus={
                                  touched.percentage && errors.percentage
                                    ? "error"
                                    : "success"
                                }
                                className="mb-0"
                              >
                                <Field>
                                  {({ form: { setFieldValue } }: any) => (
                                    <Input
                                      type={"number"}
                                      className={"form-field_input_2"}
                                      value={values?.percentage || ""}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "percentage",
                                          e.target.value
                                        );
                                        if (e.target.value) {
                                          const newValue =
                                            (parseFloat(
                                              e.target.value.toString()
                                            ) /
                                              100) *
                                              parseFloat(
                                                values.costPrice
                                                  .split(",")
                                                  .join("")
                                              ) +
                                            parseFloat(
                                              values.costPrice
                                                .split(",")
                                                .join("")
                                            );
                                          setFieldValue(
                                            "sellingPrice",
                                            formatCurrency(newValue.toString())
                                          );
                                        } else {
                                          setFieldValue(
                                            "sellingPrice",
                                            values.costPrice
                                          );
                                        }
                                      }}
                                      onBlur={(e) => {
                                        setFieldValue(
                                          "percentage",
                                          e.target.value
                                        );
                                      }}
                                      placeholder="0%"
                                      disabled={values.mode !== "0"}
                                    />
                                  )}
                                </Field>
                              </Form.Item>
                            </div>
                          </Radio>
                        </div>
                        <div className="w-full">
                          <Radio style={{ padding: "8px 0px" }} value="1">
                            <div className="flex items-center gap-x-10">
                              <p>Amount:</p>
                              <FieldContainer
                                iconPlacementCss={"smallPricingFieldIcon"}
                                icon={QuicstockNaira}
                              >
                                <Form.Item
                                  name={"amount"}
                                  help={
                                    touched.amount && errors.amount
                                      ? errors.amount
                                      : ""
                                  }
                                  validateStatus={
                                    touched.amount && errors.amount
                                      ? "error"
                                      : "success"
                                  }
                                  className="mb-0"
                                >
                                  <Field>
                                    {({ form: { setFieldValue } }: any) => (
                                      <Input
                                        type={"text"}
                                        className={"form-field_input_2"}
                                        value={values?.amount || ""}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          const formattedValue =
                                            formatCurrency(inputValue);
                                          setFieldValue(
                                            "amount",
                                            formattedValue
                                          );

                                          if (formattedValue) {
                                            const newValue =
                                              parseFloat(
                                                formattedValue
                                                  .split(",")
                                                  .join("")
                                              ) +
                                              parseFloat(
                                                values.costPrice
                                                  .split(",")
                                                  .join("")
                                              );
                                            setFieldValue(
                                              "sellingPrice",
                                              formatCurrency(
                                                newValue.toString()
                                              )
                                            );
                                          } else {
                                            setFieldValue(
                                              "sellingPrice",
                                              values.costPrice
                                            );
                                          }
                                        }}
                                        onBlur={(e) => {
                                          setFieldValue(
                                            "amount",
                                            e.target.value
                                          );
                                        }}
                                        placeholder="0.00"
                                        disabled={values.mode !== "1"}
                                      />
                                    )}
                                  </Field>
                                </Form.Item>
                              </FieldContainer>
                            </div>
                          </Radio>
                        </div>
                      </Radio.Group>
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>

          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Go back"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !px-0 !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Save"}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  costPrice: yup.string().required("Cost price is required"),
  sellingPrice: yup.string().required("Selling price is required"),
  mode: yup.string().required("Mode is required"),
});

export { EditSellingDetails };
