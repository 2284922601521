import React, { FC } from "react";
import "../../styles.scss";
import { ISelectedAlertProduct } from "../../model";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";

const SelectedAlertProduct: FC<ISelectedAlertProduct> = ({ product }) => {
  return (
    <div className="w-full bg-white border-[0.5px] border-[#EFEFEF] p-3 flex justify-between">
      <div className="flex items-center gap-x-4">
        {product.image_url && (
          <img
            src={product.image_url}
            alt="QuicstockNoImage"
            className={"xl:h-[36px] h-[28px] rounded-full"}
          />
        )}
        {!product.image_url && (
          <img
            src={QuicstockNoImage}
            alt="QuicstockNoImage"
            className={"xl:h-[42px] h-[36px] rounded-full"}
          />
        )}
        <p className="font-medium text-sm-regular">{product.name}</p>
      </div>
      <div className="hidden items-center gap-x-4 text-sm-regular font-medium">
        <p className="">{product.location_name}</p>
        <p className="text-primary-500">{product.quantity}</p>
      </div>
    </div>
  );
};

export { SelectedAlertProduct };
