import React, { FC } from "react";
import { ILoader } from "../model";
import { Spin } from "antd";

const Loader: FC<ILoader> = ({ isLoading = false }) => {
  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default Loader;
