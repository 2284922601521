/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { AuthHeading } from "../components/AuthHeading";
import { Formik } from "formik";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { ButtonState } from "../../../components/enum";
import OtpInput from "react18-input-otp";
import QuicstockBack from "../../../assets/svgs/quicstock-back.svg";
import { ReactComponent as QuicstockTickTock } from "../../../assets/svgs/quicstock-tick-tock.svg";
import { AuthBottomNode } from "../components/AuthBottomNode";
import { maskEmail } from "../../../utils/constants";

const Otp = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [otpError, setOtpError] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const phone = urlParams.get("phone");
  const mode = urlParams.get("mode");

  const handleOtp = (enteredOtp: string) => {
    setOtp(enteredOtp);
    if (!enteredOtp) {
      setOtpError("OTP is required");
    } else if (enteredOtp?.length < 4) {
      setOtpError("Invalid OTP");
    } else {
      setOtpError("");
    }
  };

  const validateOTP = () => {
    if (!otp) {
      setOtpError("OTP is required");
    } else if (otp?.length < 4) {
      setOtpError("Invalid OTP");
    } else {
      setOtpError("");
    }
  };

  const validate = (values: any) => {
    if (mode === "EMAIL") {
      navigate("/create-account");
    } else {
      navigate("/complete-account-setup");
    }
  };

  useEffect(() => {
    if (!mode && (!email || !phone)) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="mx-auto mb-6">
        <AuthHeading subHeading={"Enter OTP"}>
          {mode === "EMAIL" ? (
            <p className="text-sm-headline text-gray-600 mb-6">
              Please enter the OTP sent to your email:{" "}
              <span className="font-bold text-gray-700">
                {maskEmail(email ?? "")}
              </span>
            </p>
          ) : (
            <p className="text-sm-headline text-gray-600 mb-6">
              Please enter the OTP sent to your phone number.
            </p>
          )}
        </AuthHeading>
      </div>
      <div className="px-4 sm:px-32 md:px-48 lg:px-16 xl:px-32 xl:max-w-2xl w-full">
        <div className="text-left gap-y-6 flex flex-col">
          <Formik
            initialValues={{}}
            onSubmit={validate}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className={"mb-12 flex items-center justify-center"}>
                  <div className="otp-container">
                    <OtpInput
                      className={"otp-input"}
                      value={otp}
                      onChange={handleOtp}
                      numInputs={4}
                      placeholder="-"
                    />
                    {otpError && (
                      <div
                        className={"opt-error text-labels"}
                        style={{ color: "#ff4d4f" }}
                      >
                        {otpError}
                      </div>
                    )}
                  </div>
                </div>
                <div className={"flex justify-center gap-x-3 mb-12"}>
                  <Button
                    css={"w-[72px] !p-0 !bg-black"}
                    type={"submit"}
                    state={ButtonState.PRIMARY}
                    text={""}
                    onClick={validateOTP}
                    icon={QuicstockBack}
                  />
                  <Button
                    css={"w-full"}
                    type={"submit"}
                    state={ButtonState.PRIMARY}
                    text={"Proceed"}
                    onClick={validateOTP}
                  />
                </div>
                <div
                  className={
                    "flex justify-center gap-x-2 text-labels text-gray-600 font-semibold items-center"
                  }
                >
                  <p>Your 4 digit code expires in</p>
                  <p className="text-error-common flex items-center gap-x-1">
                    <QuicstockTickTock />
                    05:00
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AuthBottomNode
        label={"Already have an account?"}
        route={"/login"}
        routeLabel={"Sign In"}
      />
    </>
  );
};

export default Otp;
