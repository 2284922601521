import React, { FC } from "react";
import "../../styles.scss";
import { IPurchaseInfo } from "../../model";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";

const PurchaseInfo: FC<IPurchaseInfo> = ({ name, address, sent }) => {
  return (
    <div className="grid sm:grid-cols-12 grid-cols-1 items-center">
      <div className="sm:col-span-8 col">
        <div className="w-full flex gap-x-4 items-center">
          <img
            src={QuicstockNoImage}
            alt="QuicstockDummyCompany"
            className="h-[60px] rounded-full"
          />
          <div>
            <p className="font-medium">{name}</p>
            <p className="text-labels text-gray-300">{address}</p>
          </div>
        </div>
      </div>
      <div className="sm:col-span-4 col">
        <div className="w-full flex justify-between text-labels items-center sm:mt-0 mt-4">
          <p>Sent email</p>
          {sent ? (
            <div className="status active">Sent</div>
          ) : (
            <div className="status failed">Not sent</div>
          )}
        </div>
      </div>
    </div>
  );
};

export { PurchaseInfo };
