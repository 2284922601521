import React from "react";
import "../styles.scss";
import Back from "../../../components/Back";
import { PurchaseForm } from "./components";
import { IPurchaseModel } from "../model";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { CreatePo } from "../../../redux/actions/pos.action";
import { CreatePoRequestModel, PoResponseModel } from "../../../model/po.model";
import NotificationMessage from "../../../components/Notification";
import openNotification from "../../../components/Notification/notification";
import moment from "moment";

const NewPurchaseOrder = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const handleSubmit = (values: IPurchaseModel) => {
    const payload: CreatePoRequestModel = {
      supplier_id: values.supplier,
      edd: moment(values.edd).format("YYYY-MM-DD"),
      products: [],
    };
    for (let i = 0; i < values.items.length; i++) {
      payload.products.push({
        product_id: values.items[i].item,
        quantity_expected: parseInt(values.items[i].quantity),
        unit_price: parseFloat(values.items[i].unit_price.split(",").join("")),
      });
    }
    createPo(payload);
  };

  const createPo = (payload: CreatePoRequestModel) => {
    dispatch(CreatePo(payload))
      .then(async (res: PoResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Success"
            message={res?.message}
          />,
          true
        );
        navigate(`/dashboard/purchases/confirm/${res?.id}`);
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  return (
    <div className="w-full h-full">
      <div className="flex w-full gap-x-4">
        <Back isPrevious={true} />
        <div>
          <p className="sm:text-x-sm-headline text-sm-headline font-medium">
            Generate purchase orders
          </p>
          <p className="sm:text-sm-regular text-labels text-gray-300">
            Fill the details below to generate a new purchase order
          </p>
        </div>
      </div>
      <div className="default-page-height w-full xl:pt-20 pt-10 xl:px-20">
        <PurchaseForm submit={handleSubmit} />
      </div>
    </div>
  );
};

export default NewPurchaseOrder;
