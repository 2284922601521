import React, { FC } from "react";
import "../../styles.scss";
import { IAddSupplier } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { SupplierForm } from "./SupplierForm";
import { AddSupplierDetails } from "./AddSupplierDetails";

const AddSupplier: FC<IAddSupplier> = ({
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
  supplier,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Add"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <SupplierForm submit={submit} cancel={cancel} state={"ADD"} />
        </div>
        <div hidden={activeStep === 0}>
          <AddSupplierDetails
            supplier={supplier}
            goBack={goBack}
            complete={complete}
          />
        </div>
      </div>
    </div>
  );
};

export { AddSupplier };
