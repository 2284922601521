import React, { FC } from "react";
import QuicstockDeleteBasket from "../../assets/imgs/quicstock-delete-basket.png";
import { IConfirm } from "../model";
import { Modal } from "antd";
import { ButtonState } from "../enum";
import Button from "../Button";

const Confirm: FC<IConfirm> = ({
  open = false,
  confirm,
  cancel,
  title = "Confirm",
  message = 'Click "Confirm" to continue or "Cancel" to close',
  icon = QuicstockDeleteBasket,
  cancelButtonText = "Cancel",
  confirmButtonText = "Confirm",
}) => {
  const handleConfirm = () => {
    cancel();
    confirm();
  };
  return (
    <Modal
      open={open}
      closable={false}
      footer={null}
      onCancel={cancel}
      className={"confirm-modal"}
      maskClosable={false}
      centered
    >
      <div className="flex justify-center">
        <img src={icon} alt="action-icon" className="h-[60px]" />
      </div>
      <div className="w-full justify-center items-center flex flex-col mt-4">
        <p className={"text-black text-x-sm-headline mb-2 font-semibold"}>
          {title}
        </p>
        <p className="text-dark-800 text-sm-regular">{message}</p>
      </div>
      <div className="flex justify-end gap-x-4 items-center mt-10">
        <Button
          css={
            "w-full !bg-white !border !border-dark-600 !text-black !font-medium !font-normal !rounded-[6px]"
          }
          state={ButtonState.PRIMARY}
          text={cancelButtonText}
          type={"button"}
          onClick={cancel}
        />
        <Button
          css={"w-full !px-0 !rounded-[6px] !bg-error-button !rounded-[6px]"}
          state={ButtonState.PRIMARY}
          text={confirmButtonText}
          type={"button"}
          onClick={handleConfirm}
        />
      </div>
    </Modal>
  );
};

export default Confirm;
