import React, { FC } from "react";
import "../../styles.scss";
import { ITransferLocationProductDetails } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import QuicstockPackage from "../../../../assets/imgs/quicstock-package.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const TransferLocationProductDetails: FC<ITransferLocationProductDetails> = ({
  product,
  goBack,
  complete,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.locations.isLocationActionLoading
  );
  return (
    <div className="w-full">
      <div className="w-full drawer-content-height">
        <div className="flex flex-col justify-center items-center">
          <p className="font-semibold text-x-sm-headline mb-6">
            {product?.product_name}
          </p>
          <div className="mb-10">
            <img
              src={QuicstockPackage}
              alt={"QuicstockPackage"}
              className={"h-[150px]"}
            />
          </div>
          <ProductDetails
            label={"Quantity"}
            value={product?.quantity?.toString() ?? ""}
          />
          <ProductDetails
            label={"Transfer"}
            value={product?.total_quantity ?? ""}
          />
          <ProductDetails
            label={"Location"}
            value={product?.location?.name ?? ""}
          />
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={goBack}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !px-0 !rounded-[6px]"}
              state={ButtonState.PRIMARY}
              text={"Transfer product"}
              type={"button"}
              onClick={complete}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductDetails: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex justify-between w-full mb-6">
      <div className="w-1/2 text-left text-gray-300">{label}</div>
      <div className="w-1/2 text-right">{value || "-"}</div>
    </div>
  );
};

export { TransferLocationProductDetails };
