import React, { FC } from "react";
import "../../styles.scss";
import { IReorderAlerts } from "../../model";
import { ReorderAlert } from "./ReorderAlert";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const ReorderAlerts: FC<IReorderAlerts> = ({ editAlert, deleteAlert }) => {
  const alerts = useSelector((state: RootState) => state.alerts.reorderAlerts);
  return (
    <div className="w-full mt-5">
      {alerts.map((a) => (
        <React.Fragment key={a.id + "reorder"}>
          <ReorderAlert
            alert={a}
            editAlert={editAlert}
            deleteAlert={deleteAlert}
          />
          <div className="w-full border-b border-b-dark-300 my-4"></div>
        </React.Fragment>
      ))}
    </div>
  );
};

export { ReorderAlerts };
