import React, { FC, useState } from "react";
import "../../styles.scss";
import { IRemoveLocationProductForm, ILocationProduct } from "../../model";
import { Field, Formik } from "formik";
import { Collapse, CollapseProps, Form, Input } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import { ReactComponent as QuicstockInfoCircle } from "../../../../assets/svgs/quicstock-info-circle.svg";
import { LocationPurchaseOrder } from "./LocationPurchaseOrder";
import { RightOutlined } from "@ant-design/icons";

const RemoveLocationProductForm: FC<IRemoveLocationProductForm> = ({
  submit,
  cancel,
  type,
  product,
}) => {
  const [values] = useState<ILocationProduct>({
    total_quantity: "",
  });
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    submit(values);
  };

  const getItems: CollapseProps["items"] = [
    {
      key: "1",
      label: "Purchase orders",
      children: (
        <div className="w-full flex flex-col gap-y-4 mt-4">
          <LocationPurchaseOrder orderNo={"#23434"} />
          <LocationPurchaseOrder orderNo={"#23434"} />
        </div>
      ),
    },
  ];

  const validator = yup.object().shape({
    total_quantity: yup
      .number()
      .min(1, "Quantity should be more than 0")
      .max(
        product.quantity ?? 1,
        `Quantity should not be more than ${product.quantity}`
      )
      .required("Quantity is required"),
  });

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={"w-full drawer-content-height"}>
            <div className={"mb-5 w-full flex items-center"}>
              <div className="w-1/2">
                <div className="w-full">
                  <p>Product</p>
                  <div className="flex items-center gap-x-2 mt-3">
                    <div className="h-[30px] w-[30px] bg-gray-800 rounded-full"></div>
                    {product?.product_name}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="w-full">
                  <p>Quantity</p>
                  <div className="mt-3">{product?.quantity}</div>
                </div>
              </div>
            </div>
            {type === "BULK" ? (
              <div className={"mb-5"}>
                <Collapse
                  className="custom-collpse-sm"
                  expandIcon={({ isActive }) => (
                    <RightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  expandIconPosition="right"
                  items={getItems}
                />
              </div>
            ) : (
              <></>
            )}
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Quantity to remove"} required />
                <Form.Item
                  name={"total_quantity"}
                  help={
                    touched.total_quantity && errors.total_quantity
                      ? errors.total_quantity
                      : ""
                  }
                  validateStatus={
                    touched.total_quantity && errors.total_quantity
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"number"}
                        className={"form-field_input_2"}
                        value={values?.total_quantity || ""}
                        onChange={(e) => {
                          setFieldValue("total_quantity", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("total_quantity", e.target.value);
                        }}
                        placeholder="Enter quantity to remove"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className="my-10 border-[0.5px] border-[#EFEFEF]"></div>
            <div className="text-secondary-500 bg-[#FFF7F5] px-4 py-3 rounded-[10px] flex items-center gap-x-4">
              <QuicstockInfoCircle />
              Removal of a product will lead to a complete deletion of the
              product
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Cancel"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Preview"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { RemoveLocationProductForm };
