import React, { FC } from "react";
import "../styles.scss";
import { IAuthBottomNode } from "../model";
import { NavLink } from "react-router-dom";

const AuthBottomNode: FC<IAuthBottomNode> = ({ label, route, routeLabel }) => {
  return (
    <div
      className={
        "absolute left-0 right-0 mx-auto bottom-20 flex items-center gap-x-2 justify-center"
      }
    >
      <p className="text-sm-headline font-medium text-white">{label}</p>
      <NavLink to={route}>
        <span className="text-sm-headline font-bold text-primary-500">
          {routeLabel}
        </span>
      </NavLink>
    </div>
  );
};

export { AuthBottomNode };
