import React from "react";
import "../styles.scss";

const Reports = () => {
  return (
    <div className="w-full h-full">
      <div className="w-full flex justify-between gap-x-8">Reports</div>
    </div>
  );
};

export default Reports;
