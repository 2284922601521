import React, { FC } from "react";
import "../../styles.scss";
import { IRemoveLocationProduct } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { RemoveLocationProductDetails, RemoveLocationProductForm } from ".";

const RemoveLocationProduct: FC<IRemoveLocationProduct> = ({
  submit,
  goBack,
  complete,
  cancel,
  type,
  activeStep,
  product,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Remove"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <RemoveLocationProductForm
            submit={submit}
            cancel={cancel}
            type={type}
            product={product}
          />
        </div>
        <div hidden={activeStep === 0}>
          <RemoveLocationProductDetails
            product={product}
            goBack={goBack}
            complete={complete}
            type={type}
          />
        </div>
      </div>
    </div>
  );
};

export { RemoveLocationProduct };
