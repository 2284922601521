import React, { useEffect, useState } from "react";
import "../styles.scss";
import PageHeader from "../../../components/PageHeader";
import { LeftHeaderType } from "../../../components/enum";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockPackage from "../../../assets/imgs/quicstock-package.png";
import { AllPurchases } from "./components";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ApiPaginationModel } from "../../../model/base.model";
import { FetchPos } from "../../../redux/actions/pos.action";
import { PoItemModel } from "../../../model/po.model";
import { RootState } from "../../../redux/reducers";

const Purchases = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const dispatch: AppDispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<PoItemModel[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [paginationData, setPaginationData] = useState<ApiPaginationModel>({
    itemsPerPage: 10,
    page: 1,
  });
  const allPOs = useSelector((state: RootState) => state.pos.allPOs);

  useEffect(() => {
    console.log(search);
  }, [search]);

  const addNewPurchaseOrder = () => {
    navigate("/dashboard/purchases/new");
  };

  const viewProduct = (product: any) => {
    navigate(`/dashboard/purchases/confirm/${product?.id}`);
  };

  useEffect(() => {
    dispatch(FetchPos(paginationData));
  }, [dispatch, paginationData]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={true}
        title={"Purchase orders"}
        subtitle={"Purchases"}
        placeholder={"Search"}
        buttonText={"New purchase order"}
        getSearchValue={setSearch}
        handleClick={addNewPurchaseOrder}
      />
      <div className="default-page-height w-full">
        {allPOs && allPOs?.allPOs && allPOs?.allPOs.length ? (
          <AllPurchases
            viewProduct={viewProduct}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) : (
          <EmptyContent
            label={"You have no purchase order information yet"}
            component={
              <img
                src={QuicstockPackage}
                alt={"QuicstockPackage"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Add new purchase order"}
            handlePrimaryButtonClick={addNewPurchaseOrder}
          />
        )}
      </div>
    </div>
  );
};

export default Purchases;
