import React, { FC } from "react";
import "../../styles.scss";
import { IAllAlerts } from "../../model";
import { RestockAlerts } from "./RestockAlerts";
import { ReorderAlerts } from "./ReorderAlerts";
import { AlertItemModel } from "../../../../model/alerts.model";

const AllAlerts: FC<IAllAlerts> = ({ active, setActive, edit, del }) => {
  const handleEdit = (alert: AlertItemModel) => {
    edit(alert);
  };
  const handleDelete = (alert: AlertItemModel) => {
    del(alert);
  };

  return (
    <div className="w-full bg-white app-table pb-4 overflow-y-auto mt-10">
      <div className="border border-dark-500 rounded-[10px] p-4 w-full">
        <div className="flex gap-x-2 alerts-btn">
          <button
            className={`${active === 0 ? "" : "inactive"}`}
            onClick={() => setActive(0)}
          >
            Restock
          </button>
          <button
            className={`${active === 1 ? "" : "inactive"}`}
            onClick={() => setActive(1)}
          >
            Reorder
          </button>
        </div>
        {active === 0 ? (
          <RestockAlerts editAlert={handleEdit} deleteAlert={handleDelete} />
        ) : (
          <ReorderAlerts editAlert={handleEdit} deleteAlert={handleDelete} />
        )}
      </div>
    </div>
  );
};

export { AllAlerts };
