import React, { ChangeEvent, FC, useState } from "react";
import "../styles.scss";
import { IPageHeader } from "../model";
import { ButtonState, LeftHeaderType } from "../enum";
import FieldContainer from "../Input/FIeldContainer";
import QuicstockSearch from "../../assets/svgs/quicstock-search.svg";
import { Form, Input } from "antd";
import Button from "../Button";

const PageHeader: FC<IPageHeader> = ({
  leftHeaderType,
  showSearchField,
  component,
  title,
  subtitle,
  placeholder,
  buttonText,
  getSearchValue,
  handleClick,
}) => {
  const [search] = useState<string>("");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    getSearchValue && getSearchValue(e.target.value);
  };

  return (
    <div className="flex flex-wrap w-full items-center">
      <div className="xl:w-1/2 md:w-5/12 w-1/2">
        {leftHeaderType === LeftHeaderType.COMPONENT && component ? (
          component
        ) : title && subtitle ? (
          <div>
            <p className="sm:text-x-sm-headline text-sm-headline text-black">{title}</p>
            <p className="sm:text-labels text-x-small text-gray-300">{subtitle}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
      {showSearchField ? (
        <div className="xl:w-1/2 md:w-7/12 w-1/2">
          <div className="flex w-full gap-x-2 justify-end">
            <div className="hidden md:flex items-center search-field">
              <FieldContainer
                icon={QuicstockSearch}
                iconPlacementCss={"iconCss"}
              >
                <Form.Item name={"search"} className="page-header">
                  <Input
                    type={"email"}
                    className={"form-field_input_2 w-full"}
                    value={search || ""}
                    placeholder={placeholder ?? "Search"}
                    onChange={handleSearchChange}
                  />
                </Form.Item>
              </FieldContainer>
            </div>
            <Button
              css={"h-[42px] xl:!px-6 !rounded-[10px]"}
              state={ButtonState.SECONDARY}
              text={buttonText ?? "Button"}
              type={"button"}
              onClick={() => handleClick && handleClick()}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {!showSearchField && handleClick ? (
        <div className="xl:w-1/2 md:w-7/12 w-1/2">
          <div className="flex w-full gap-x-2 justify-end">
            <Button
              css={"h-[42px] !px-6 !rounded-[10px]"}
              state={ButtonState.SECONDARY}
              text={buttonText ?? "Button"}
              type={"button"}
              onClick={() => handleClick && handleClick()}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PageHeader;
