import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IPurchaseForm, IPurchaseModel } from "../../model";
import { Field, Formik, FieldArray } from "formik";
import { AutoComplete, DatePicker, Form, Spin } from "antd";
import * as yup from "yup";
import { PurchaseFormField, PurchaseSummary } from "./";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import FieldName from "../../../../components/FieldName";
import { ReactComponent as QuicstockAdd } from "../../../../assets/svgs/quicstock-add.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { ApiDropdownModel } from "../../../../model/base.model";
import { AppDispatch } from "../../../../redux/store";
import { SearchSuppliers } from "../../../../redux/actions/suppliers.action";
import { debounce } from "lodash";

const PurchaseForm: FC<IPurchaseForm> = ({ submit }) => {
  const dispatch: AppDispatch = useDispatch();
  const [form] = Form.useForm();
  const [values] = useState<IPurchaseModel>({
    supplier: "",
    edd: "",
    items: [],
  });
  const isLoading = useSelector(
    (state: RootState) => state.suppliers.isSupplierActionLoading
  );
  const supplierSearchResult = useSelector(
    (state: RootState) => state.suppliers.supplierSearchResult
  );
  const [selectedSupplierName, setSelectedSupplierName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);

  const debouncedSearch = debounce(async (value, setOptions) => {
    if (value) {
      dispatch(SearchSuppliers(value));
    } else {
      setOptions([]);
      setSelectedSupplierName("");
    }
  }, 300);

  const handleSearch = async (value: string) => {
    debouncedSearch(value, setOptions);
  };

  useEffect(() => {
    if (supplierSearchResult && supplierSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = supplierSearchResult.map(
        (supplier) => ({
          value: supplier.id,
          label: supplier.name,
          name: supplier.name,
        })
      );
      setOptions(suggestions);
    }
  }, [supplierSearchResult]);

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    submit(values);
  };

  return (
    <Formik
      className="w-full h-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => {
        const addItem = () => {
          const newItem = {
            item: "",
            unit_price: "",
            type: "",
            quantity: "",
            total: "",
          };
          const updatedItems = [...values.items, newItem];
          setFieldValue("items", updatedItems);
        };

        const removeItem = (index: number) => {
          const updatedItems = values.items.filter((_, i) => i !== index);
          setFieldValue("items", updatedItems);
        };
        return (
          <Form
            className="h-full flex flex-col justify-between"
            onFinish={handleSubmit}
            form={form}
          >
            <div className="w-full">
              <div className="grid sm:grid-cols-5 grid-cols-1 gap-x-4">
                <div className={"sm:col-span-3 col mb-5"}>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <FieldName name={"Supplier"} required={true} />
                    <Form.Item
                      name={"supplier"}
                      help={
                        touched.supplier && errors.supplier
                          ? errors.supplier
                          : ""
                      }
                      validateStatus={
                        touched.supplier && errors.supplier
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }: any) => (
                          <AutoComplete
                            options={options}
                            onSearch={handleSearch}
                            onSelect={(value, option) => {
                              setFieldValue("supplier", value);
                              setSelectedSupplierName(option.name ?? "");
                            }}
                            onChange={(value) => {
                              setSelectedSupplierName(value);
                              setFieldValue("supplier", "");
                              if (!value) {
                                setOptions([]);
                              }
                            }}
                            value={selectedSupplierName || values.supplier}
                            notFoundContent={
                              isLoading ? <Spin size="small" /> : null
                            }
                            allowClear
                            style={{ width: "100%", height: "48px" }}
                          >
                            <input
                              type="text"
                              className="form-field_input_2"
                              placeholder="Search supplier"
                            />
                          </AutoComplete>
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"sm:col-span-2 col mb-5"}>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <FieldName
                      name={"Expected delivery date"}
                      required={true}
                    />
                    <Form.Item
                      name={"edd"}
                      help={touched.edd && errors.edd ? errors.edd : ""}
                      validateStatus={
                        touched.edd && errors.edd ? "error" : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }: any) => (
                          <DatePicker
                            className={"form-field_input_2"}
                            placeholder={"Choose date"}
                            value={values?.edd || ""}
                            onChange={(e) => {
                              setFieldValue("edd", e);
                            }}
                            onOk={(e) => {
                              setFieldValue("edd", e);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                </div>
              </div>
              <FieldArray name="items">
                {({ push, remove }) => (
                  <>
                    {values.items.map((item, index) => (
                      <PurchaseFormField
                        key={index}
                        index={index}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        remove={() => removeItem(index)}
                      />
                    ))}
                  </>
                )}
              </FieldArray>
              <div className="w-full">
                <div className="sm:w-5/12 w-full">
                  <p>Click on the tile below to add new item</p>
                  <button
                    type="button"
                    className="w-full border border-dark-200 rounded-[10px] px-2 py-3 flex justify-between mt-2 items-center"
                    onClick={addItem}
                  >
                    <p>Add item</p>
                    <QuicstockAdd />
                  </button>
                </div>
              </div>
            </div>
            <PurchaseSummary values={values} />
          </Form>
        );
      }}
    </Formik>
  );
};

const validator = yup.object().shape({
  supplier: yup.string().required("Supplier is required"),
  edd: yup.string().required("Expected delivery date is required"),
  items: yup.array().of(
    yup.object({
      item: yup.string().required("Item is required"),
      unit_price: yup.string().required("required"),
      quantity: yup.number().min(1, "required").required("required"),
      total: yup.string().required("required"),
    })
  ),
});

export { PurchaseForm };
