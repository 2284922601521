import React, { FC } from "react";
import "../../styles.scss";
import { IAddCustomProperties } from "../../model";
import { Form, Input } from "antd";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import FieldName from "../../../../components/FieldName";
import { ReactComponent as QuicstockInfoCircle } from "../../../../assets/svgs/quicstock-info-circle.svg";

const AddCustomProperties: FC<IAddCustomProperties> = ({ goBack, save }) => {
  const values = {
    weight: "",
    date: "",
    dOne: "",
    dTwo: "",
  };
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    save(values);
  };

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit, handleBlur }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={"mt-8 mb-5"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <FieldName name={"Weight"} />
              <Form.Item
                name={"weight"}
                help={touched.weight && errors.weight ? errors.weight : ""}
                validateStatus={
                  touched.weight && errors.weight ? "error" : "success"
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.weight}
                      onChange={(e) => {
                        setFieldValue("weight", e.target.value);
                      }}
                      onBlur={(e) => {
                        setFieldValue("weight", e.target.value);
                      }}
                      placeholder="Enter weight"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <FieldName name={"Date"} />
              <Form.Item
                name={"date"}
                help={touched.date && errors.date ? errors.date : ""}
                validateStatus={
                  touched.date && errors.date ? "error" : "success"
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.date}
                      onChange={(e) => {
                        setFieldValue("date", e.target.value);
                      }}
                      onBlur={(e) => {
                        setFieldValue("date", e.target.value);
                      }}
                      placeholder="Choose date"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <FieldName name={"D"} />
              <Form.Item
                name={"dOne"}
                help={touched.dOne && errors.dOne ? errors.dOne : ""}
                validateStatus={
                  touched.dOne && errors.dOne ? "error" : "success"
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.dOne}
                      onChange={(e) => {
                        setFieldValue("dOne", e.target.value);
                      }}
                      onBlur={(e) => {
                        setFieldValue("dOne", e.target.value);
                      }}
                      placeholder="Enter D"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <FieldName name={"D"} />
              <Form.Item
                name={"dTwo"}
                help={touched.dTwo && errors.dTwo ? errors.dTwo : ""}
                validateStatus={
                  touched.dTwo && errors.dTwo ? "error" : "success"
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.dTwo}
                      onChange={(e) => {
                        setFieldValue("dTwo", e.target.value);
                      }}
                      onBlur={(e) => {
                        setFieldValue("dTwo", e.target.value);
                      }}
                      placeholder="Enter D"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className="text-secondary-500 bg-[#FFF7F5] px-4 py-3 rounded-[10px] flex justify-center items-center gap-x-4 mt-20">
            <QuicstockInfoCircle />
            You can only add five custom properties
          </div>
          <div className="my-5 border-[0.5px] border-[#EFEFEF]"></div>
          <div className={"grid grid-cols-3 gap-x-2"}>
            <div className={"col-1"}></div>
            <div className={"col-1"}>
              <Button
                css={
                  "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                }
                state={ButtonState.PRIMARY}
                text={"Go back"}
                type={"button"}
                onClick={goBack}
              />
            </div>
            <div className={"col-1"}>
              <Button
                css={"w-full !rounded-[6px]"}
                state={ButtonState.PRIMARY}
                text={"Save"}
                type={"submit"}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  name: yup.string().required("Cost price is required"),
  type: yup.string().required("Selling price is required"),
});

export { AddCustomProperties };
