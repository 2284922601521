import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IRestockAlert } from "../../model";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";
import { ReactComponent as QuicstockDot } from "../../../../assets/svgs/quicstock-dot.svg";
import { ReactComponent as QuicstockEdit } from "../../../../assets/svgs/quicstock-edit.svg";
import { ReactComponent as QuicstockDelete } from "../../../../assets/svgs/quicstock-delete.svg";
import { AlertItemLocationModel } from "../../../../model/alerts.model";

const RestockAlert: FC<IRestockAlert> = ({ alert, editAlert, deleteAlert }) => {
  const [groupedLocations, setGroupedLocations] = useState<any>({});
  const [totalQuantity, setTotalQuantity] = useState<number>(0);

  const groupByLocationType = (locations: AlertItemLocationModel[]) => {
    return locations.reduce((acc: any, item) => {
      const { location_type }: any = item;
      if (!acc[location_type]) {
        acc[location_type] = [];
      }
      acc[location_type].push(item);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (alert.location_quantities) {
      setGroupedLocations(groupByLocationType(alert.location_quantities));
      const total = alert?.location_quantities.reduce(
        (sum, item) => sum + (item.quantity ?? 0),
        0
      );
      setTotalQuantity(total);
    }
  }, [alert.location_quantities]);

  return (
    <div className="w-full bg-white border border-dark-200 rounded-[16px] p-4">
      <div className="sm:flex grid flex-wrap">
        <div className="xl:w-9/12 lg:w-8/12 sm:w-7/12 w-full">
          <div className="w-full flex flex-col gap-y-4">
            <div className="flex gap-x-2 items-center">
              {alert?.product_image && (
                <img
                  src={alert?.product_image}
                  alt={alert.product_name}
                  className="sm:h-[42px] h-[32px] rounded-full"
                />
              )}
              {!alert?.product_image && (
                <img
                  src={QuicstockNoImage}
                  alt="QuicstockNoImage"
                  className="sm:h-[42px] h-[32px] rounded-full"
                />
              )}
              <p className="font-medium md:text-sm-headline text-sm-regular">
                {alert.product_name}
              </p>
            </div>
            <p className="md:text-sm-regular text-labels font-medium">
              <span className="mr-1 text-gray-300 font-normal">UPC:</span>
              {alert?.product_upc}
            </p>
            {groupedLocations["sales"] && groupedLocations["sales"]?.length ? (
              <div className="sm:flex grid items-center gap-3 md:text-sm-regular text-labels">
                <p className="mr-1 text-gray-300 font-normal">Sales:</p>
                {groupedLocations["sales"]?.map(
                  (p: AlertItemLocationModel, i: number) => (
                    <div key={i + "res"} className="flex items-center gap-x-3">
                      <div className="flex items-center gap-x-2 font-medium">
                        <p>{p.location_name}</p>
                        <QuicstockDot className="product-properties-separator" />
                        <p>{p.quantity}</p>
                      </div>
                      {i < (groupedLocations["sales"]?.length || 0) - 1 ? (
                        <QuicstockDot className="product-separator sm:block hidden" />
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                )}
              </div>
            ) : (
              <></>
            )}

            {groupedLocations["online-sales"] &&
            groupedLocations["online-sales"]?.length ? (
              <div className="sm:flex grid items-center gap-3 md:text-sm-regular text-labels">
                <p className="mr-1 text-gray-300 font-normal">Online Sales:</p>
                {groupedLocations["online-sales"]?.map(
                  (p: AlertItemLocationModel, i: number) => (
                    <div key={i + "res"} className="flex items-center gap-x-3">
                      <div className="flex items-center gap-x-2 font-medium">
                        <p>{p.location_name}</p>
                        <QuicstockDot className="product-properties-separator" />
                        <p>{p.quantity}</p>
                      </div>
                      {i <
                      (groupedLocations["online-sales"]?.length || 0) - 1 ? (
                        <QuicstockDot className="product-separator sm:block hidden" />
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="xl:w-3/12 lg:w-4/12 sm:w-5/12 w-full sm:mt-0 mt-4 sm:block hidden">
          <div className="grid grid-cols-2">
            <div className="sm:flex flex-col gap-y-4 col">
              <div className="flex items-center gap-x-2 triggers justify-between">
                <p className="text-gray-300 text-sm-regular">Current:</p>
                <input
                  className="md:text-sm-regular text-labels"
                  value={totalQuantity}
                  disabled
                />
              </div>
              <div className="flex items-center gap-x-2 triggers justify-between">
                <p className="text-gray-300 text-sm-regular">Trigger:</p>
                <input
                  className="md:text-sm-regular text-labels"
                  value={alert.threshold_quantity}
                  disabled
                />
              </div>
            </div>
            <div className="flex items-center gap-x-2 ml-4">
              <button onClick={() => editAlert(alert)}>
                <QuicstockEdit />
              </button>
              <button onClick={() => deleteAlert(alert)}>
                <QuicstockDelete />
              </button>
            </div>
          </div>
        </div>

        <div className="xl:w-3/12 lg:w-4/12 sm:w-5/12 w-full sm:mt-0 mt-4 sm:hidden block">
          <div className="flex grid-cols-3">
            <div className="flex flex-col gap-y-4 col">
              <div className="flex items-center gap-x-2 triggers justify-between">
                <p className="text-gray-300 md:text-sm-regular text-labels">
                  Current:
                </p>
                <input
                  value={10}
                  className="md:text-sm-regular text-labels"
                  disabled
                />
              </div>
              <div className="flex items-center gap-x-2 triggers justify-between">
                <p className="text-gray-300 md:text-sm-regular text-labels">
                  Trigger:
                </p>
                <input
                  value={alert.threshold_quantity}
                  className="md:text-sm-regular text-labels"
                  disabled
                />
              </div>
            </div>
            <div className="flex items-center gap-x-2 ml-4 col-span-2">
              <button onClick={() => editAlert(alert)}>
                <QuicstockEdit />
              </button>
              <button onClick={() => deleteAlert(alert)}>
                <QuicstockDelete />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { RestockAlert };
