import React, { FC } from "react";
import "../../styles.scss";
import { ITransferLocationProductData } from "../../model";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import QuicstockProductImage from "../../../../assets/imgs/quicstock-product-image.png";
import { formatCurrency, NAIRA } from "../../../../utils/constants";

const TransferLocationProductData: FC<ITransferLocationProductData> = ({
  transfer,
  remove,
  viewAllPurchaseOrders,
  product,
}) => {
  return (
    <div className="w-full mt-10">
      <div className="w-full drawer-content-height-no-step">
        <div className="flex w-full flex-col items-center justify-center">
          <img
            src={QuicstockProductImage}
            alt={"QuicstockProductImage"}
            className={"h-[64px]"}
          />
          <p className="text-sm-headline mt-4">{product?.product_name}</p>
        </div>
        <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
        <TransferLocationProductDetails
          label={"Quantity"}
          value={product?.quantity?.toString() ?? ""}
        />
        <TransferLocationProductDetails label={"Color"} value={"---"} />
        <TransferLocationProductDetails label={"Brand"} value={"---"} />
        <TransferLocationProductDetails
          label={"Retail price"}
          value={`${NAIRA}${formatCurrency(product?.retail_price?.toString() ?? "")}`}
        />
        <p className="font-medium text-sm-headline">Custom properties</p>
        <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
        <TransferLocationProductDetails label={"Quantity"} value={"5,657"} />
        <div className="flex flex-wrap gap-x-2">
          <Order orderNo={"Order #1234"} />
          <Order orderNo={"Order #1234"} />
          <Order orderNo={"Order #1234"} />
          <Order orderNo={"Order #1234"} />
          <Order orderNo={"Order #1234"} />
        </div>
        <div className="mt-2 mb-4 border-[0.5px] border-[#EFEFEF]"></div>
        <TransferLocationProductDetails
          label={"Color"}
          value={"Blue"}
          badge={"Global"}
        />
        <TransferLocationProductDetails
          label={"Brand"}
          value={"Coke"}
          badge={"Global"}
        />
        <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
        <div className="flex w-full items-center justify-between">
          <p className="font-medium text-sm-headline">Purchase orders</p>
          <button
            onClick={viewAllPurchaseOrders}
            className="text-primary-500 bg-primary-200 rounded-[24px] py-1 px-3 text-labels"
          >
            View all
          </button>
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Remove"}
              type={"button"}
              onClick={remove}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px]"}
              state={ButtonState.PRIMARY}
              text={"Transfer"}
              type={"button"}
              onClick={transfer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const TransferLocationProductDetails: FC<{
  label: string;
  value: string;
  badge?: string;
}> = ({ label, value, badge }) => {
  return (
    <div className="flex justify-between w-full mb-6">
      <div className="w-1/2 flex items-center gap-x-4">
        <div className="text-left">{label}</div>
        {badge && (
          <div className="text-secondary-500 bg-secondary-100 rounded-[32px] py-1 px-3 text-sm-regular font-medium">
            {badge}
          </div>
        )}
      </div>
      <div className="w-1/2 text-right">{value || "-"}</div>
    </div>
  );
};

const Order: FC<{
  orderNo: string;
}> = ({ orderNo }) => {
  return (
    <div className="text-primary-500 bg-primary-200 rounded-[32px] py-1 px-3 text-sm-regular font-medium mb-2">
      {orderNo}
    </div>
  );
};

export { TransferLocationProductData };
