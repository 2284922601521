import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockSupplier from "../../../assets/imgs/quicstock-supplier.png";
import { AlertForm, AllAlerts } from "./components";
import AppDrawer from "../../../components/AppDrawer";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import {
  AlertItemModel,
  AlertsResponseModel,
} from "../../../model/alerts.model";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateAlert,
  DeleteAlert,
  FetchReorderAlerts,
  FetchRestockAlerts,
  UpdateAlert,
} from "../../../redux/actions/alerts.action";
import Loader from "../../../components/Loader";
import { RootState } from "../../../redux/reducers";
import Confirm from "../../../components/Confirm";

const Alerts = () => {
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.alerts.isAlertsActionLoading
  );
  const restockAlerts = useSelector(
    (state: RootState) => state.alerts.restockAlerts
  );
  const reorderAlerts = useSelector(
    (state: RootState) => state.alerts.reorderAlerts
  );
  const [openNewAlertDrawer, setOpenNewAlertDrawer] = useState(false);
  const [openEditAlertDrawer, setOpenEditAlertDrawer] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [active, setActive] = useState<number>(0);
  const [alert, setAlert] = useState<AlertItemModel>({});
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log(search);
  }, [search]);

  const toggleNewAlertDrawer = () => {
    setOpenNewAlertDrawer(!openNewAlertDrawer);
  };

  const toggleEditAlertDrawer = () => {
    setOpenEditAlertDrawer(!openEditAlertDrawer);
  };

  const handleEdit = (alert: AlertItemModel) => {
    setAlert(alert);
    toggleEditAlertDrawer();
  };

  const handleDelete = (alert: AlertItemModel) => {
    setAlert(alert);
    setOpen(true);
  };

  const handleSubmit = (values: AlertItemModel, state: "ADD" | "UPDATE") => {
    if (state === "ADD") {
      createAlert(values);
    } else {
      const payload: AlertItemModel = {
        threshold_quantity: values.threshold_quantity,
      };
      updateAlert(payload);
    }
  };

  const createAlert = (payload: AlertItemModel) => {
    dispatch(CreateAlert(payload))
      .then(async (res: AlertsResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Alert submited"
            message={res?.message}
          />,
          true
        );
        toggleNewAlertDrawer();
        dispatch(FetchRestockAlerts());
        dispatch(FetchReorderAlerts());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const updateAlert = (payload: AlertItemModel) => {
    dispatch(UpdateAlert(payload, alert.id ?? ""))
      .then(async (res: AlertsResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Alert updated"
            message={res?.message}
          />,
          true
        );
        toggleEditAlertDrawer();
        dispatch(FetchRestockAlerts());
        dispatch(FetchReorderAlerts());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const deleteAlert = () => {
    dispatch(DeleteAlert(alert.id ?? ""))
      .then(async (res: AlertsResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Alert deleted"
            message={res?.message}
          />,
          true
        );
        dispatch(FetchRestockAlerts());
        dispatch(FetchReorderAlerts());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchRestockAlerts());
    dispatch(FetchReorderAlerts());
  }, [dispatch]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={true}
        title={"Alerts"}
        subtitle={"Here are your alert types"}
        placeholder={"Search"}
        buttonText={"Set new alert"}
        getSearchValue={setSearch}
        handleClick={toggleNewAlertDrawer}
      />
      <div className="default-page-height w-full">
        <Loader isLoading={isLoading} />
        {restockAlerts.length || reorderAlerts.length ? (
          <AllAlerts
            active={active}
            setActive={setActive}
            edit={handleEdit}
            del={handleDelete}
          />
        ) : (
          <EmptyContent
            label={"Seems you haven’t set any alert yet. Want to set one?"}
            component={
              <img
                src={QuicstockSupplier}
                alt={"QuicstockSupplier"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Add new alert"}
            handlePrimaryButtonClick={toggleNewAlertDrawer}
          />
        )}
      </div>

      {/* New alert */}
      <AppDrawer
        open={openNewAlertDrawer}
        toggle={toggleNewAlertDrawer}
        title={"Set new alert"}
        subtitle={"Restock into a location"}
      >
        <AlertForm submit={handleSubmit} cancel={toggleNewAlertDrawer} />
      </AppDrawer>
      {/* New alert */}

      {/* Edit alert */}
      <AppDrawer
        open={openEditAlertDrawer}
        toggle={toggleEditAlertDrawer}
        title={"Edit"}
        subtitle={"Restock into a location"}
      >
        <AlertForm
          submit={handleSubmit}
          cancel={toggleEditAlertDrawer}
          alert={alert}
        />
      </AppDrawer>
      {/* Edit alert */}

      <Confirm
        title="Delete Alert"
        confirmButtonText="Delete"
        open={open}
        cancel={() => setOpen(false)}
        confirm={deleteAlert}
      />
    </div>
  );
};

export default Alerts;
