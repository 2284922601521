import React, { FC } from "react";
import "../styles.scss";
import { IAppDrawer } from "../model";
import { Drawer } from "antd";
import { ReactComponent as QuicstockModalClose } from "../../assets/svgs/quicstock-modal-close.svg";

const AppDrawer: FC<IAppDrawer> = ({
  open,
  toggle,
  children,
  title,
  subtitle,
}) => {
  const hanleClose = () => {
    toggle();
  };

  return (
    <Drawer
      onClose={hanleClose}
      open={open}
      closable={false}
      className={"app-drawer"}
      destroyOnClose={true}
    >
      <div className="flex w-full justify-between items-center">
        <div>
          <p className="text-x-sm-headline font-medium">{title}</p>
          {subtitle && (
            <p className="text-gray-300 text-sm-regular">{subtitle}</p>
          )}
        </div>
        <button onClick={hanleClose}>
          <QuicstockModalClose />
        </button>
      </div>
      <div className="mt-5">{children}</div>
    </Drawer>
  );
};

export default AppDrawer;
