import React, { useState } from "react";
import { Form, Input } from "antd";
import { AuthHeading } from "../components/AuthHeading";
import { Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Input/FIeldContainer";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { ButtonState } from "../../../components/enum";
import { isValidPhoneNumber } from "react-phone-number-input";
import PasswordField from "../../../components/Input/PasswordField";
import { AuthBottomNode } from "../components/AuthBottomNode";

const CreateAccount = () => {
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [values] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    bvn: "",
    password: "",
  });

  const create = (values: any) => {
    navigate(`/otp?mode=PHONE&phone=${values?.phoneNumber}`);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="px-4 sm:px-32 md:px-48 lg:px-16 xl:px-32 xl:max-w-2xl w-full">
      <div className="text-left gap-y-6 flex flex-col">
        <AuthHeading subHeading={"Create Account"} />
        <Formik
          initialValues={values}
          onSubmit={create}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"firstName"}
                    help={
                      touched.firstName && errors.firstName
                        ? errors.firstName
                        : ""
                    }
                    validateStatus={
                      touched.firstName && errors.firstName
                        ? "error"
                        : "success"
                    }
                  >
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.firstName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="First Name"
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"lastName"}
                    help={
                      touched.lastName && errors.lastName ? errors.lastName : ""
                    }
                    validateStatus={
                      touched.lastName && errors.lastName ? "error" : "success"
                    }
                  >
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.lastName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"phoneNumber"}
                    help={
                      touched.phoneNumber && errors.phoneNumber
                        ? errors.phoneNumber
                        : ""
                    }
                    validateStatus={
                      touched.phoneNumber && errors.phoneNumber
                        ? "error"
                        : "success"
                    }
                  >
                    <Input
                      type={"tel"}
                      className={"form-field_input_2"}
                      value={values?.phoneNumber || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"bvn"}
                    help={touched.bvn && errors.bvn ? errors.bvn : ""}
                    validateStatus={
                      touched.bvn && errors.bvn ? "error" : "success"
                    }
                  >
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.bvn || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="BVN"
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <PasswordField
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}
                >
                  <Form.Item
                    name={"password"}
                    help={
                      touched.password && errors.password ? errors.password : ""
                    }
                    validateStatus={
                      touched.password && errors.password ? "error" : "success"
                    }
                  >
                    <Input
                      type={showPassword ? "text" : "password"}
                      className={"form-field_input_2"}
                      value={values?.password || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Password"
                    />
                  </Form.Item>
                </PasswordField>
              </div>
              <div className={"flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={ButtonState.PRIMARY}
                  text={"Create Account"}
                  type={"submit"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <AuthBottomNode
        label={"Already have an account?"}
        route={"/login"}
        routeLabel={"Sign In"}
      />
    </div>
  );
};
const validator = yup.object().shape({
  firstName: yup
    .string()
    .min(3, "First Name should be minimum of 3 characters")
    .required("First Name is required"),
  lastName: yup
    .string()
    .min(3, "Last Name should be minimum of 3 characters")
    .required("Last Name is required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .test("is-valid-phone", "Phone Number is invalid", (value) => {
      return isValidPhoneNumber(value || "");
    }),
  bvn: yup
    .string()
    .min(11, "BVN should be 11 characters")
    .required("BVN is required"),
  password: yup
    .string()
    .min(8, "Password should be a minimum of 8 characters")
    .required("Password is required"),
});

export default CreateAccount;
