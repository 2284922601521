/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import "../../styles.scss";
import { IAlertForm } from "../../model";
import { Field, Formik } from "formik";
import {
  AutoComplete,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Radio,
  Spin,
} from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import "react-phone-number-input/style.css";
import { ApiDropdownModel } from "../../../../model/base.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { AppDispatch } from "../../../../redux/store";
import { debounce } from "lodash";
import {
  FetchRestockLocations,
  SearchProducts,
} from "../../../../redux/actions/products.action";
import { SelectedAlertProduct } from "./SelectedAlertProduct";
import { ReactComponent as QuicstockChevronDown } from "../../../../assets/svgs/quicstock-chevron-down.svg";
import { ReactComponent as QuicstockDot } from "../../../../assets/svgs/quicstock-dot.svg";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";
import { ProductItemModel } from "../../../../model/product.model";
import { formatString } from "../../../../utils/constants";
import { AlertItemModel } from "../../../../model/alerts.model";

const AlertForm: FC<IAlertForm> = ({ submit, cancel, alert }) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedProductName, setSelectedProductName] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<ProductItemModel>({});
  const [type, setType] = useState<string>("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const [items, setItems] = useState<MenuProps["items"]>([]);
  const [error, setError] = useState<string>("");
  const [formPresetCompleted, setFormPresetCompleted] = useState<boolean>(true);
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const isRestockLocationLoading = useSelector(
    (state: RootState) => state.products.isRestockLocationLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );
  const restockLocations = useSelector(
    (state: RootState) => state.products.restockLocations
  );
  const isAlertsActionLoading = useSelector(
    (state: RootState) => state.alerts.isAlertsActionLoading
  );
  const [pLocations, setPLocations] = useState<ProductItemModel[]>([]);
  const [values, setValues] = useState<AlertItemModel>({
    alert_type: "",
    threshold_quantity: "",
    product_id: "",
  });
  const [form] = Form.useForm();
  const handleOnSubmit = (values: any, { resetForm }: any) => {
    if (values.alert_type === "restock" && pLocations.length === 0) {
      setError("Please select at least one location");
    } else {
      const location_id = pLocations.map((pl) => {
        return pl.location_id;
      });
      const payload: AlertItemModel = {
        type: values?.alert_type,
        product_id: values?.product_id,
        threshold_quantity: parseInt(values?.threshold_quantity),
        location_id,
      };
      if (alert) {
        submit(payload, "UPDATE");
      } else {
        submit(payload, "ADD");
      }
    }
  };

  useEffect(() => {
    if (alert) {
      setFormPresetCompleted(false);
      setValues({
        ...alert,
      });
      if (alert.product_name) {
        handleSearch(alert.product_name);
        setSelectedProductName(alert.product_name ?? "");
      }
      if (alert.alert_type) {
        setType(alert.alert_type);
      }
    }
  }, [alert]);

  const debouncedSearch = debounce(
    async (value, setOptions, dispatch, setSelectedProductName) => {
      if (value) {
        dispatch(SearchProducts(value));
      } else {
        setOptions([]);
        setSelectedProductName("");
      }
    },
    300
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value, setOptions, dispatch, setSelectedProductName);
    },
    [setOptions, dispatch, setSelectedProductName]
  );

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
          data: product,
        })
      );
      setOptions(suggestions);

      if (alert && !formPresetCompleted) {
        const pp = suggestions.find((s) => s.value === alert.product_id);
        setSelectedProduct(pp.data);
      }
    }
  }, [productSearchResult]);

  useEffect(() => {
    if (restockLocations.length > 0) {
      const _items: MenuProps["items"] = restockLocations.map((rs) => {
        return {
          label: (
            <div
              className={`flex items-center gap-x-2 bg-white border ${isSelected(rs) ? "border-link-green" : "border-[#efefef]"} px-[10px] py-[8px]`}
            >
              <img
                src={QuicstockNoImage}
                alt={"QuicstockNoImage"}
                className={"xl:h-[36px] h-[28px] rounded-full"}
              />
              <p className="text-sm-regular font-medium text-black">
                {rs?.product_name}
              </p>
              <div className="ml-auto flex items-center gap-x-4">
                <p className="text-sm-regular font-medium text-black">
                  {formatString(rs?.location_type ?? "")}
                </p>
                <p className="text-sm-regular font-medium text-primary-500">
                  {rs?.location_name}
                </p>
                <QuicstockDot className="product-properties-separator" />
                <p className="text-sm-regular font-semibold text-black">
                  {rs?.quantity}
                </p>
              </div>
            </div>
          ),
          key: rs.location_id ?? "",
          className: "money-dd",
          onClick: () => {
            const exist = pLocations.find(
              (p) => p.location_id === rs.location_id
            );
            if (!exist) {
              setPLocations([...pLocations, rs]);
            } else {
              setPLocations(
                pLocations.filter((p) => p.location_id !== rs.location_id)
              );
            }
          },
        };
      });
      setItems(_items);
    }

    if (alert && !formPresetCompleted) {
      const alertLocations: ProductItemModel[] = [];
      for (let i = 0; i < restockLocations.length; i++) {
        if (
          alert.location_quantities?.find(
            (a) => a.location_id === restockLocations[i].location_id
          )
        ) {
          alertLocations.push(restockLocations[i]);
        }
      }
      setPLocations(alertLocations);
      setFormPresetCompleted(true);
    }
  }, [restockLocations, pLocations]);

  useEffect(() => {
    if (selectedProduct && selectedProduct.id && type === "restock")
      dispatch(FetchRestockLocations(selectedProduct.id ?? ""));
  }, [selectedProduct]);

  const isSelected = (rs: ProductItemModel): boolean => {
    const exist = pLocations.find((p) => p.location_id === rs.location_id);
    return exist ? true : false;
  };

  useEffect(() => {
    if (type === "restock") {
      if (type === "restock" && pLocations.length) {
        setError("");
      } else {
        setError("Please select at least one location");
      }
    } else {
      setError("");
    }
  }, [pLocations]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={`w-full drawer-content-height-no-step`}>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName
                  name={"What kind of alert is this?"}
                  required={true}
                />
                <Form.Item
                  name={"alert_type"}
                  help={
                    touched.alert_type && errors.alert_type
                      ? errors.alert_type
                      : ""
                  }
                  validateStatus={
                    touched.alert_type && errors.alert_type
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Radio.Group
                        style={{
                          fontSize: "16px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "16px",
                        }}
                        value={values?.alert_type || ""}
                        onChange={(e) => {
                          setFieldValue("alert_type", e.target.value);
                          setType(e.target.value);
                          setSelectedProduct({});
                          setPLocations([]);
                          setFieldValue("product_id", "");
                          setSelectedProductName("");
                        }}
                        disabled={alert !== null && alert !== undefined}
                      >
                        <Radio value="restock">Restock alert</Radio>
                        <Radio value="reorder">Reorder alert</Radio>
                      </Radio.Group>
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <p className="text-sm-headline text-center">For</p>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Product"} required />
                <Form.Item
                  name={"product_id"}
                  help={
                    touched.product_id && errors.product_id
                      ? errors.product_id
                      : ""
                  }
                  validateStatus={
                    touched.product_id && errors.product_id
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <AutoComplete
                        disabled={alert !== null && alert !== undefined}
                        options={options}
                        onSearch={handleSearch}
                        onSelect={(value, option) => {
                          setFieldValue("product_id", value);
                          setSelectedProductName(option.name ?? "");
                          setSelectedProduct(option.data);
                          setPLocations([]);
                        }}
                        onChange={(value) => {
                          setSelectedProductName(value);
                          setFieldValue("product_id", "");
                          if (!value) {
                            setOptions([]);
                            setSelectedProduct({});
                            setPLocations([]);
                          }
                        }}
                        value={selectedProductName || values.product_id}
                        notFoundContent={
                          isLoading ? <Spin size="small" /> : null
                        }
                        allowClear
                        style={{ width: "100%", height: "48px" }}
                      >
                        <input
                          type="text"
                          className="form-field_input_2"
                          placeholder="Search product"
                        />
                      </AutoComplete>
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            {values.alert_type === "restock" && selectedProduct?.id ? (
              <div className={"mb-5"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Location"} required />
                  <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    disabled={
                      isRestockLocationLoading ||
                      (alert !== null && alert !== undefined)
                    }
                  >
                    {pLocations.length > 0 ? (
                      <div
                        className={`flex items-center gap-x-2 ${isRestockLocationLoading || (alert !== null && alert !== undefined) ? "bg-[#fafbff] cursor-not-allowed" : "bg-white cursor-pointer"} border border-[#efefef] rounded-[10px] px-[12px] py-[10px] h-[48px] justify-between`}
                      >
                        <p className="text-labels text-[#555455] flex gap-x-2">
                          {pLocations.map((pl) => (
                            <span
                              key={pl.location_id}
                              className="bg-gray-800 text-black px-2 rounded-[10px]"
                            >
                              {pl.location_name}
                            </span>
                          ))}
                        </p>
                        <QuicstockChevronDown />
                      </div>
                    ) : (
                      <div className="flex items-center justify-between gap-x-2 bg-white border border-[#efefef] rounded-[10px] px-[12px] py-[10px] cursor-pointer h-[48px]">
                        <p className="text-sm-regular text-gray-300">
                          Select locations
                        </p>
                        {isRestockLocationLoading ? (
                          <Spin size="small" />
                        ) : (
                          <QuicstockChevronDown />
                        )}
                      </div>
                    )}
                  </Dropdown>
                  <span className="text-labels text-error-main">{error}</span>
                </FieldContainer>
              </div>
            ) : (
              <></>
            )}
            {values.alert_type === "reorder" && selectedProduct?.id ? (
              <div className="w-full mb-5">
                <SelectedAlertProduct product={selectedProduct} />
              </div>
            ) : (
              <></>
            )}
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"New stock alert"} required={true} />
                <Form.Item
                  name={"threshold_quantity"}
                  help={
                    touched.threshold_quantity && errors.threshold_quantity
                      ? errors.threshold_quantity
                      : ""
                  }
                  validateStatus={
                    touched.threshold_quantity && errors.threshold_quantity
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"number"}
                        className={"form-field_input_2"}
                        value={values?.threshold_quantity}
                        onChange={(e) => {
                          setFieldValue("threshold_quantity", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("threshold_quantity", e.target.value);
                        }}
                        placeholder="Enter stock alert"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Cancel"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !px-0 !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Save"}
                  type={"submit"}
                  isLoading={isAlertsActionLoading}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  alert_type: yup.string().required("Alert type is required"),
  product_id: yup.string().required("Product is required"),
  threshold_quantity: yup.number().required("Quantity is required"),
});

export { AlertForm };
