import React, { FC } from "react";
import "../../styles.scss";
import { IAddLocationProduct } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { LocationProductForm } from "./LocationProductForm";
import { LocationProductDetails } from "./LocationProductDetails";

const AddLocationProduct: FC<IAddLocationProduct> = ({
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
  product,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Add product"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <LocationProductForm submit={submit} cancel={cancel} />
        </div>
        <div hidden={activeStep === 0}>
          <LocationProductDetails
            product={product}
            goBack={goBack}
            complete={complete}
          />
        </div>
      </div>
    </div>
  );
};

export { AddLocationProduct };
