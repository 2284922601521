import React, { FC } from "react";
import "../styles.scss";
import { ITableActions } from "../model";
import { ReactComponent as QuicstockUploadDoc } from "../../assets/svgs/quicstock-upload-doc.svg";
import { ReactComponent as QuicstockFilter } from "../../assets/svgs/quicstock-filter.svg";
import { ReactComponent as QuicstockTrash } from "../../assets/svgs/quicstock-trash.svg";

const TableActions: FC<ITableActions> = ({
  label,
  handleImport,
  handleExport,
  handleFilter,
  handleDelete,
  disableImport,
  disableExport,
  disableFilter,
  disableDelete,
}) => {
  return (
    <>
      {label || handleImport || handleExport || handleFilter || handleDelete ? (
        <div className="flex flex-wrap w-full items-center mt-6 justify-between table-actions">
          {label && <div className="text-sm-regular">{label}</div>}
          <div className="flex lg:gap-x-5 gap-x-2 table-action-btns sm:mt-0 mt-4">
            {handleImport && (
              <button
                disabled={disableImport ?? false}
                className="text-sm-regular import-btn"
                onClick={handleImport}
              >
                <QuicstockUploadDoc />
                Import
              </button>
            )}

            {handleExport && (
              <button
                disabled={disableExport ?? false}
                className="text-sm-regular export-btn"
                onClick={handleExport}
              >
                <QuicstockUploadDoc />
                Export
              </button>
            )}

            {handleFilter && (
              <button
                disabled={disableFilter ?? false}
                className="text-sm-regular filter-btn"
                onClick={handleFilter}
              >
                <QuicstockFilter />
                Filter
              </button>
            )}
            {handleDelete && (
              <button
                disabled={disableDelete ?? false}
                className="text-sm-regular delete-btn"
                onClick={handleDelete}
              >
                <QuicstockTrash />
                Delete
              </button>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TableActions;
