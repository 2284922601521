import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockNote from "../../../assets/imgs/quicstock-note.png";
import { useNavigate } from "react-router-dom";
import { AllCustomProperties, EditCustomPropertyForm } from "./components";
import AppDrawer from "../../../components/AppDrawer";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  DeleteCustomProperty,
  FetchCustomProperties,
  UpdateCustomProperty,
} from "../../../redux/actions/products.action";
import { RootState } from "../../../redux/reducers";
import {
  CustomPropertyModel,
  DeleteCustomPropertyModel,
  ProductResponseModel,
} from "../../../model/product.model";
import Loader from "../../../components/Loader";
import Confirm from "../../../components/Confirm";

const CustomProperties = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const cp = useSelector((state: RootState) => state.products.cp);
  const [openEditCPDrawer, setOpenEditCPDrawer] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [customProperty, setCustomProperty] = useState<CustomPropertyModel>({
    is_global: true,
    property_name: "",
    property_type: "",
    property_value: "",
    value: "",
    image_url: "",
    product_name: "",
    po_numbers: [],
  });

  const addNewCustomProperty = () => {
    navigate("/dashboard/product/custom-properties/new");
  };

  const toggleEditCPDrawer = () => {
    setOpenEditCPDrawer(!openEditCPDrawer);
  };

  const handleEdit = (cp: CustomPropertyModel) => {
    setCustomProperty(cp);
    toggleEditCPDrawer();
  };

  const handleDelete = (cp: CustomPropertyModel) => {
    setCustomProperty(cp);
    setOpen(true);
  };

  const handleSubmit = (values: CustomPropertyModel) => {
    updateCustomProperty(values);
  };

  const updateCustomProperty = (values: CustomPropertyModel) => {
    dispatch(UpdateCustomProperty(customProperty.product_id ?? "", values))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Custom property updated"
            message={res?.message}
          />,
          true
        );
        toggleEditCPDrawer();
        dispatch(FetchCustomProperties());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const deleteCustomProperty = () => {
    const payload: DeleteCustomPropertyModel = {
      property_name: customProperty.property_name,
      property_type: customProperty.property_type,
    };
    dispatch(DeleteCustomProperty(customProperty.product_id ?? "", payload))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Custom property deleted"
            message={res?.message}
          />,
          true
        );
        dispatch(FetchCustomProperties());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchCustomProperties());
  }, [dispatch]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={false}
        title={"Custom properties"}
        subtitle={"Fill the details below to create a new custom property"}
        buttonText={"New property"}
        handleClick={addNewCustomProperty}
      />
      <div className="default-page-height w-full">
        <Loader isLoading={isLoading} />
        {cp?.date?.length || cp?.string?.length || cp?.number?.length ? (
          <AllCustomProperties
            editCustomProperty={handleEdit}
            deleteCustomProperty={handleDelete}
          />
        ) : (
          <EmptyContent
            label={"No custom properties here yet!"}
            note={"Why not add one or two?"}
            component={
              <img
                src={QuicstockNote}
                alt={"QuicstockNote"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Create new custom property"}
            handlePrimaryButtonClick={addNewCustomProperty}
          />
        )}
      </div>

      {/* Edit custom property */}
      <AppDrawer
        open={openEditCPDrawer}
        toggle={toggleEditCPDrawer}
        title={"Edit property"}
        subtitle={"View your product"}
      >
        <EditCustomPropertyForm
          submit={handleSubmit}
          cancel={toggleEditCPDrawer}
          customProperty={customProperty}
        />
      </AppDrawer>
      {/* Edit custom property */}

      <Confirm
        title="Delete Custom Property"
        confirmButtonText="Delete"
        open={open}
        cancel={() => setOpen(false)}
        confirm={deleteCustomProperty}
      />
    </div>
  );
};

export default CustomProperties;
