import React from "react";
import "../styles.scss";

const Overview = () => {
  return (
    <div className="w-full h-full">
      <div className="w-full flex justify-between gap-x-8">Overview</div>
    </div>
  );
};

export default Overview;
