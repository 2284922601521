import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ISupplierForm } from "../../model";
import { Field, Formik } from "formik";
import { Form, Input } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { SupplierItemModel } from "../../../../model/supplier.model";
import { parsePhoneNumber } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const SupplierForm: FC<ISupplierForm> = ({
  submit,
  cancel,
  supplier,
  state,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.suppliers.isSupplierActionLoading
  );
  const [values, setValues] = useState<SupplierItemModel>({
    name: "",
    phonenumber: "",
    email: "",
    address: "",
    state: "",
    city: "",
  });
  const [form] = Form.useForm();
  const handleOnSubmit = (values: any, { resetForm }: any) => {
    if (supplier) {
      submit(values, "UPDATE");
    } else {
      submit(values, "ADD");
    }
  };

  useEffect(() => {
    if (supplier) {
      setValues({
        ...supplier,
        phonenumber: parsePhoneNumber(supplier.phonenumber),
      });
    }
  }, [supplier]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div
            className={`w-full ${state === "ADD" ? "drawer-content-height" : "drawer-content-height-no-step"}`}
          >
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Supplier’s name"} required={true} />
                <Form.Item
                  name={"name"}
                  help={touched.name && errors.name ? errors.name : ""}
                  validateStatus={
                    touched.name && errors.name ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.name}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        placeholder="Enter full name"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Supplier’s phone number"} required={true} />
                <Form.Item
                  name={"phonenumber"}
                  help={
                    touched.phonenumber && errors.phonenumber
                      ? errors.phonenumber
                      : ""
                  }
                  validateStatus={
                    touched.phonenumber && errors.phonenumber
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <PhoneInput
                        defaultCountry="NG"
                        className={"form-field_input_2"}
                        placeholder="+234 0000 000 0000"
                        value={values?.phonenumber || ""}
                        onChange={(e) => {
                          setFieldValue("phonenumber", e);
                        }}
                        onBlur={(e: any) => {
                          setFieldValue("phonenumber", e.target.value);
                        }}
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Supplier’s email address "} />
                <Form.Item
                  name={"email"}
                  help={touched.email && errors.email ? errors.email : ""}
                  validateStatus={
                    touched.email && errors.email ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"email"}
                        className={"form-field_input_2"}
                        value={values?.email || ""}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        placeholder="Enter supplier’s email address"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Supplier’s location"} />
                <Form.Item
                  name={"address"}
                  help={touched.address && errors.address ? errors.address : ""}
                  validateStatus={
                    touched.address && errors.address ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.address || ""}
                        onChange={(e) => {
                          setFieldValue("address", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("address", e.target.value);
                        }}
                        placeholder="Enter supplier’s address"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Supplier’s state"} />
                <Form.Item
                  name={"city"}
                  help={touched.state && errors.state ? errors.state : ""}
                  validateStatus={
                    touched.state && errors.state ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.state || ""}
                        onChange={(e) => {
                          setFieldValue("state", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("state", e.target.value);
                        }}
                        placeholder="Enter supplier’s state"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Supplier’s city "} />
                <Form.Item
                  name={"city"}
                  help={touched.city && errors.city ? errors.city : ""}
                  validateStatus={
                    touched.city && errors.city ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.city || ""}
                        onChange={(e) => {
                          setFieldValue("city", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("city", e.target.value);
                        }}
                        placeholder="Enter supplier’s city"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={`${!supplier ? "Cancel" : "Go back"}`}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !px-0 !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={`${!supplier ? "Preview supplier" : "Save changes"}`}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  name: yup
    .string()
    .min(3, "Supplier’s name should be minimum of 6 characters")
    .required("Supplier’s name is required"),
  phonenumber: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-phone", "Phone number is invalid", (value) => {
      return isValidPhoneNumber(value || "");
    }),
  email: yup.string().email("Invalid email").required("Email is required"),
  address: yup.string().required("Location is required"),
  state: yup.string(),
  city: yup.string(),
});

export { SupplierForm };
