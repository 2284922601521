import React, { FC } from "react";
import "../../styles.scss";
import { ILocationBarcode } from "../../model";
import QuicstockBarcode from "../../../../assets/imgs/quicstock-barcode.png";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";

const LocationBarcode: FC<ILocationBarcode> = ({ submit, cancel }) => {
  return (
    <div className="w-full mt-10">
      <div className="barcode-in-drawer">
        <img
          src={QuicstockBarcode}
          alt={"QuicstockBarcode"}
          className={"h-[354px]"}
        />
      </div>
      <div className="my-10 border-[0.5px] border-[#EFEFEF]"></div>
      <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
        <div className={"col-1 sm:block hidden"}></div>
        <div className={"col-1"}>
          <Button
            css={
              "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
            }
            state={ButtonState.PRIMARY}
            text={"Go back"}
            type={"button"}
            onClick={cancel}
          />
        </div>
        <div className={"col-1"}>
          <Button
            css={"w-full !rounded-[6px]"}
            state={ButtonState.PRIMARY}
            text={"Print barcode"}
            type={"button"}
            onClick={submit}
          />
        </div>
      </div>
    </div>
  );
};

export { LocationBarcode };
