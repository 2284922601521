/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import "../../styles.scss";
import { ILocationProduct, ILocationProductForm } from "../../model";
import { Field, Formik } from "formik";
import { AutoComplete, Form, Input, Spin } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { AppDispatch } from "../../../../redux/store";
import { SearchProducts } from "../../../../redux/actions/products.action";
import { ApiDropdownModel } from "../../../../model/base.model";
import { debounce } from "lodash";

const LocationProductForm: FC<ILocationProductForm> = ({
  submit,
  cancel,
  product,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [values, setValues] = useState<ILocationProduct>({
    id: "",
    product: null,
    total_quantity: "",
  });
  const [selectedProductName, setSelectedProductName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const [form] = Form.useForm();
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    submit({
      ...values,
      product: productSearchResult.find((p) => p.id === values.id),
    });
  };

  useEffect(() => {
    if (product) {
      setValues(product);
    }
  }, [product]);

  const debouncedSearch = debounce(
    async (value, setOptions, dispatch, setSelectedProductName) => {
      if (value) {
        dispatch(SearchProducts(value));
      } else {
        setOptions([]);
        setSelectedProductName("");
      }
    },
    300
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value, setOptions, dispatch, setSelectedProductName);
    },
    [setOptions, dispatch, setSelectedProductName]
  );

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
        })
      );
      setOptions(suggestions);
    }
  }, [productSearchResult]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={"w-full drawer-content-height"}>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Product"} required />
                <Form.Item
                  name={"id"}
                  help={touched.id && errors.id ? errors.id : ""}
                  validateStatus={touched.id && errors.id ? "error" : "success"}
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <AutoComplete
                        options={options}
                        onSearch={handleSearch}
                        onSelect={(value, option) => {
                          setFieldValue("id", value);
                          setSelectedProductName(option.name ?? "");
                        }}
                        onChange={(value) => {
                          setSelectedProductName(value);
                          setFieldValue("id", "");
                          if (!value) {
                            setOptions([]);
                          }
                        }}
                        value={selectedProductName || values.id}
                        notFoundContent={
                          isLoading ? <Spin size="small" /> : null
                        }
                        allowClear
                        style={{ width: "100%", height: "48px" }}
                      >
                        <input
                          type="text"
                          className="form-field_input_2"
                          placeholder="Search product"
                        />
                      </AutoComplete>
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Quantity"} required />
                <Form.Item
                  name={"total_quantity"}
                  help={
                    touched.total_quantity && errors.total_quantity
                      ? errors.total_quantity
                      : ""
                  }
                  validateStatus={
                    touched.total_quantity && errors.total_quantity
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"number"}
                        className={"form-field_input_2"}
                        value={values?.total_quantity || ""}
                        onChange={(e) => {
                          setFieldValue("total_quantity", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("total_quantity", e.target.value);
                        }}
                        placeholder="Enter product quantity"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Cancel"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Preview changes"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  id: yup.string().required("Product name is required"),
  total_quantity: yup
    .number()
    .min(1, "Product quantity should be more than 0")
    .required("Product quantity is required"),
});

export { LocationProductForm };
