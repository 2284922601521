import React, { FC } from "react";
import "../../styles.scss";
import { IProductDetailsItem } from "../../model";

const ProductDetailsItem: FC<IProductDetailsItem> = ({
  label,
  value,
  badge,
  className,
}) => {
  return (
    <div
      className={`flex justify-between w-full mb-6 xl:text-sm-headline text-labels ${className}`}
    >
      <div className="w-1/2 flex items-center gap-x-4">
        <div className="text-left">{label}</div>
        {badge && (
          <div className="text-secondary-500 bg-secondary-100 rounded-[32px] py-1 px-3 xl:text-sm-regular text-labels font-medium">
            {badge}
          </div>
        )}
      </div>
      <div className="w-1/2 text-right">{value || "-"}</div>
    </div>
  );
};

export { ProductDetailsItem };
