import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IPurchaseSummary } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import { formatCurrency, NAIRA } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const PurchaseSummary: FC<IPurchaseSummary> = ({ values }) => {
  const isLoading = useSelector(
    (state: RootState) => state.pos.isPoActionLoading
  );
  const [subTotal, setSubTotal] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [shipping] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (values && values.items.length > 0) {
      let st = 0;
      let tx = 0;
      let tt = 0;
      for (let i = 0; i < values.items.length; i++) {
        if (values.items[i].total) {
          st = st + parseFloat(values.items[i].total.split(",").join(""));
        }
      }
      tx = (0 / 100) * st;
      // tx = (8 / 100) * st;
      tt = st + tx;
      setSubTotal(st);
      setTax(tx);
      setTotal(tt);
    } else {
      setSubTotal(0);
      setTax(0);
      setTotal(0);
    }
  }, [values]);

  return (
    <div className="w-full justify-center flex mt-10">
      <div className="sm:w-1/2 w-full">
        <div className="w-full">
          <div className="grid grid-cols-2">
            <div className="col">Subtotal:</div>
            <div className="col text-right text-sm-headline">
              {NAIRA}
              {formatCurrency(subTotal.toString())}
            </div>
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="col">Tax (8%):</div>
            <div className="col text-right text-sm-headline">
              {NAIRA}
              {formatCurrency(tax.toString())}
            </div>
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="col">Shipping:</div>
            <div className="col text-right text-sm-headline">
              {NAIRA}
              {formatCurrency(shipping.toString())}
            </div>
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="col">Total price:</div>
            <div className="col text-right text-sm-headline font-semibold">
              {NAIRA}
              {formatCurrency(total.toString())}
            </div>
          </div>
          <Button
            css={"w-full h-[42px] !px-10 !py-6 !rounded-[10px] mt-8"}
            state={ButtonState.SECONDARY}
            text={"Generate purchase order"}
            type={"submit"}
            disabled={values.items.length === 0}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export { PurchaseSummary };
