import React, { FC } from "react";
import "../styles.scss";
import { IEmptyContent } from "../model";
import Button from "../Button";
import { ButtonState } from "../enum";

const EmptyContent: FC<IEmptyContent> = ({
  component,
  label,
  note,
  showPrimaryButton,
  showSecondaryButton,
  primaryButtonLabel,
  secondaryButtonLabel,
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
}) => {
  return (
    <div className="h-full w-full flex items-center justify-center flex-col">
      {component}
      <p className="text-sm-regular mt-4">{label}</p>
      {note && <p className="text-labels mt-2 text-gray-300">{note}</p>}
      {showPrimaryButton && (
        <Button
          css={"w-[434px] h-[42px] !px-10 !py-6 !rounded-[10px] mt-8"}
          state={ButtonState.SECONDARY}
          text={primaryButtonLabel ?? "Button"}
          type={"button"}
          onClick={() => handlePrimaryButtonClick && handlePrimaryButtonClick()}
        />
      )}

      {showSecondaryButton && (
        <Button
          css={"w-[434px] h-[42px] !px-10 !py-6 !rounded-[10px] mt-3"}
          state={ButtonState.PRIMARY}
          text={secondaryButtonLabel ?? "Button"}
          type={"button"}
          bordered={true}
          onClick={() =>
            handleSecondaryButtonClick && handleSecondaryButtonClick()
          }
        />
      )}
    </div>
  );
};

export default EmptyContent;
