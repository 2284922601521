import React, { FC, useEffect } from "react";
import "../styles.scss";
import { Outlet, useNavigate } from "react-router-dom";
import QuicstockLogoWhite from "../../assets/imgs/quicstock-logo-white.png";

const AuthLayout: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("quicstock_app_token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gray-400 auth-main">
      <div className="lg:flex relative">
        <img
          className={"lg:h-6 h-4 absolute left-10 top-10"}
          src={QuicstockLogoWhite}
          alt={"Logo"}
        />
        <div className="flex-1 auth-container relative">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
