import React, { FC, useState } from "react";
import "../styles.scss";
import { ReactComponent as QuicstockUploadRing } from "../../assets/svgs/quicstock-upload-ring.svg";
import { IDraggableUpload } from "../model";
import { Upload } from "antd";

const { Dragger } = Upload;
const ERROR_CLASSES =
  "w-full border-[1px] border-dashed rounded-[20px] border-error-main h-[534px] flex flex-col items-center justify-center gap-y-2 cursor-pointer";
const DEFAULT_CLASSES =
  "w-full border-[1px] border-dashed rounded-[20px] border-[#CCCCCC] h-[534px] flex flex-col items-center justify-center gap-y-2 cursor-pointer";

const DraggableUpload: FC<IDraggableUpload> = ({
  onUpload,
  maxSize = 1024,
  name = "file",
  multiple = false,
  maxSizeText,
  supports = ["JPEG", "JPG", "PNG"],
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [error, setError] = useState<string>("");

  const props = {
    name,
    multiple,
    action: "",
    accept: `.${supports.join(",.")}`,
    fileList,
    showUploadList: false,
    customRequest: ({ onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload: (file: any) => {
      const isInValid = file.size / 1024 > maxSize;
      if (isInValid) {
        setError(`${file.name} is larger than ${maxSizeText}!`);
        return Upload.LIST_IGNORE;
      }
      setError("");
      onUpload(file, `${(file.size / 1024 / 1024).toFixed(5)}MB`);
      return true;
    },
    onChange(info: any) {
      setFileList(info.fileList.filter((file: any) => !file.error));
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props} className="drag-upload">
      <div className={error ? ERROR_CLASSES : DEFAULT_CLASSES}>
        <QuicstockUploadRing />
        <p className="text-md-headline font-medium mt-4">
          Drag and drop your document
        </p>
        <p className="text-x-sm-headline text-gray-300">
          or,{" "}
          <span className="text-primary-500 hover:underline">
            click to browse
          </span>
        </p>
        <p className="text-sm-regular">Supports: {supports.join(", ")}</p>
        <p className="text-labels text-gray-300 mt-4">
          Maximum Upload File Size: 25MB
        </p>
      </div>
      <p className="text-error-main">{error}</p>
    </Dragger>
  );
};

export { DraggableUpload };
