import moment from "moment";
import { ReactNode } from "react";
import { ApiPaginationModel, ApiValueType } from "../model/base.model";

const MONTHS = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Nov",
  "Dec",
];

export const flCapital = (s: string) =>
  s !== null ? s.charAt(0).toUpperCase() + s.slice(1) : "";

export const formatDate = (dateString: string) => {
  if (dateString) {
    const date = new Date(dateString);
    let _month = (date.getMonth() + 1).toString();
    let month = _month.length === 1 ? "0" + _month : _month;
    let _day = date.getDate().toString();
    let day = _day.length === 1 ? "0" + _day : _day;
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return day + "/" + month + "/" + date.getFullYear() + "  " + strTime;
  }
  return "";
};

export const dateFormat = (dateString: string) => {
  if (dateString) {
    const date = new Date(dateString);
    let month = date.getMonth() + 1;
    let _day = date.getDate().toString();
    let day = _day.length === 1 ? "0" + _day : _day;
    return day + " " + MONTHS[month] + ", " + date.getFullYear();
  }
  return "";
};

export const localISOTime = (date: any) => {
  let x = new Date().getTimezoneOffset() * 60000;
  let isoTime = new Date(date - x).toISOString().slice(0, -1);
  return isoTime.split("T").join(" ");
};

export const today = () => {
  let now = new Date(Date.now());
  let lastMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  let startDate = localISOTime(lastMidnight);
  let endDate = localISOTime(now);
  return { startDate, endDate };
};

export const yesterday = () => {
  let now = new Date(Date.now());
  let lastTwoMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1
  );
  let startDate = localISOTime(lastTwoMidnight);
  let endDate = localISOTime(lastTwoMidnight);
  return { startDate, endDate };
};

export const thisWeek = () => {
  let startDate = localISOTime(moment().startOf("week").toDate());
  let endDate = localISOTime(moment().endOf("week").toDate());
  return { startDate, endDate };
};

export const lastWeek = () => {
  let startDate = localISOTime(
    moment().subtract(1, "weeks").startOf("week").toDate()
  );
  let endDate = localISOTime(
    moment().subtract(1, "weeks").endOf("week").toDate()
  );
  return { startDate, endDate };
};

export const thisMonth = () => {
  const startDate = localISOTime(moment().startOf("month").toDate());
  let endDate = localISOTime(new Date(Date.now()));
  return { startDate, endDate };
};

export const lastMonth = () => {
  const startDate = localISOTime(
    moment().subtract(1, "months").startOf("month").toDate()
  );
  const endDate = localISOTime(
    moment().subtract(1, "months").endOf("month").toDate()
  );
  return { startDate, endDate };
};

export const thisYear = () => {
  const startDate = localISOTime(moment().startOf("year"));
  let endDate = localISOTime(new Date(Date.now()));
  return { startDate, endDate };
};

export const lastYear = () => {
  const startDate = localISOTime(moment().subtract(1, "years").startOf("year"));
  let endDate = localISOTime(moment().subtract(1, "years").endOf("year"));
  return { startDate, endDate };
};

export const numberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const download = (dataurl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = dataurl;
  link.target = "_blank";
  if (fileName) {
    link.download = fileName;
  }
  link.click();
};

export const goBack = () => {
  window.history.back();
};

export const maskEmail = (email: string): string => {
  if (email) {
    const emailName: string = email.split("@")[0];
    const emailService: string = email.split("@")[1];
    let masked = "";
    if (emailName.length > 2) {
      for (let i = 0; i < emailName.length; i++) {
        if (i <= 1) {
          masked = masked + emailName[i];
        } else {
          masked = masked + "*";
        }
      }
    } else {
      for (let i = 0; i < emailName.length; i++) {
        masked = masked + "*";
      }
    }
    return `${masked}@${emailService}`;
  }
  return "";
};

export const numberOnly = (
  e: React.KeyboardEvent<HTMLInputElement>
): boolean => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

export const itemRender = (
  _: any,
  type: string,
  originalElement: ReactNode,
  total: number,
  pageSize: number
) => {
  const totalPages = Math.ceil(total / pageSize);
  if (type === "prev") {
    const isDisabled = _ === 1;
    return (
      <button disabled={isDisabled} className={"app-pagination-btn prev"}>
        {"Previous"}
      </button>
    );
  }
  if (type === "next") {
    const isDisabled = _ === totalPages;
    return (
      <button disabled={isDisabled} className={"app-pagination-btn next"}>
        {"Next"}
      </button>
    );
  }
  return originalElement;
};

export const trimAll = (value: string): string => {
  return value.replace(/\s+/g, "");
};

export const normalizePhoneNumber = (value: string): string => {
  return trimAll(`0${value.split("+234").join("")}`);
};

export const parsePhoneNumber = (value: string): string => {
  return value.length === 11 ? `+234${value.substring(1)}` : value;
};

export const paginationFooter = (
  paginationData: ApiPaginationModel,
  total_count: number,
  total: number
): string => {
  return `Showing ${(paginationData.page - 1) * paginationData.itemsPerPage + 1} to ${paginationData.page * paginationData.itemsPerPage < total_count ? paginationData.page * paginationData.itemsPerPage : total_count} of ${total} entries`;
};

export const formatCurrency = (value: string | null | undefined) => {
  if (!value) return "";
  const cleanValue = value.replace(/[^\d.-]/g, "");
  const [integer, decimal] = cleanValue.split(".");
  const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return decimal !== undefined
    ? `${formattedInteger}.${decimal}`
    : formattedInteger;
};

export const increment = (
  callback: any,
  start = 0,
  interval = 50,
  target = 100
) => {
  let count = start;
  const increment = setInterval(() => {
    callback(count);
    count++;

    if (count > target) {
      clearInterval(increment);
    }
  }, interval);
  return () => clearInterval(increment);
};

export const NAIRA = "₦";

export const VALUE_TYPES: ApiValueType[] = [
  { value: "string", label: "Text" },
  { value: "number", label: "Number" },
  { value: "date", label: "Date" },
];

export const formatString = (input: string) => {
  return input
    .replace(/[^a-zA-Z0-9\s]/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
};
