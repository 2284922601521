import React, { FC } from "react";
import "../../styles.scss";
import { IViewDetails } from "../../model";

const ViewDetails: FC<IViewDetails> = ({ user }) => {
  return (
    <div className="w-full mt-12">
      <div className="w-full drawer-content-height">
        <div className="flex flex-col justify-center items-center">
          <UserInfo label={"Full name"} value={user?.name} />
          <UserInfo label={"Staff ID"} value={user?.staffId} />
          <UserInfo label={"Phone number"} value={user?.mobileNumber} />
          <UserInfo label={"Email"} value={user?.email} />
          <UserInfo label={"Department"} value={user?.department} />
          <UserInfo label={"Level"} value={user?.level} />
          <UserInfo label={"Role"} value={user?.role} />
          <UserInfo label={"Status"} value={user?.status} />
        </div>
      </div>
    </div>
  );
};

const UserInfo: FC<{ label: string; value?: string }> = ({ label, value }) => {
  return (
    <div className="flex justify-between w-full mb-6">
      <div className="w-1/2 text-left text-gray-300">{label}</div>
      {label !== "Status" ? (
        <div className="w-1/2 text-right">
          <React.Fragment>{value}</React.Fragment>
        </div>
      ) : (
        <div className="w-1/2 text-right flex justify-end">
          <span className={`status ${value?.toLowerCase()}`}>{value}</span>
        </div>
      )}
    </div>
  );
};

export { ViewDetails };
