import React, { FC } from "react";
import { Spin } from "antd";
import "../styles.scss";

const Preloader: FC = () => {
  return (
    <div className="preloader-container">
      <Spin size="large" />
    </div>
  );
};

export default Preloader;
