import React, { FC } from "react";
import "../styles.scss";
import { IFieldContainer } from "../model";

const FieldContainer: FC<IFieldContainer> = ({
  icon,
  icon2,
  children,
  iconPlacementCss,
}) => {
  return (
    <div className={`w-full relative ${icon ? "auth-input" : ""}`}>
      {icon ? (
        <img src={icon} alt={"Pre"} className={iconPlacementCss ?? ""} />
      ) : (
        <></>
      )}
      {children}
      {icon2 ? (
        <img src={icon2} alt={"Pre"} className={iconPlacementCss ?? ""} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default FieldContainer;
