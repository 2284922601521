import React, { useState } from "react";
import "../styles.scss";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockPackage from "../../../assets/imgs/quicstock-package.png";
import { IUser } from "../model";
import AppDrawer from "../../../components/AppDrawer";
import { AllUsers, CreateUser, UpdateUser, ViewDetails } from "./components";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import Confirm from "../../../components/Confirm";

const UserManagementTab = () => {
  const [openNewUserDrawer, setOpenNewUserDrawer] = useState(false);
  const [openEditUserDrawer, setOpenEditUserDrawer] = useState(false);
  const [openViewUserDrawer, setOpenViewUserDrawer] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [users] = useState<IUser[]>([
    { id: 1, name: "", staffId: "", mobileNumber: "", status: "", role: "" },
  ]);
  const [user, setUser] = useState<IUser>({
    name: "",
    staffId: "",
    mobileNumber: "",
    status: "",
    role: "",
  });

  const toggleNewUserDrawer = () => {
    setOpenNewUserDrawer(!openNewUserDrawer);
  };

  const toggleEditUserDrawer = () => {
    setOpenEditUserDrawer(!openEditUserDrawer);
  };

  const toggleViewUserDrawer = () => {
    setOpenViewUserDrawer(!openViewUserDrawer);
  };

  const handleSubmit = (values: IUser) => {
    setUser(values);
    setActiveStep(1);
  };

  const handleUpdateUser = (user: IUser) => {
    setUser(user);
    setActiveStep(0);
    toggleEditUserDrawer();
  };

  const handleDisableUser = (user: IUser) => {
    setOpen(true);
    setUser(user);
  };

  const handleDeleteUser = (id: any) => {};

  const handleComplete = (state: "ADD" | "UPDATE") => {
    if (state === "ADD") {
      openNotification(
        <NotificationMessage
          type="success"
          title="User successfully added"
          message="We’ll get this reviewed and get back to you. Enjoy!"
        />,
        true
      );
      toggleNewUserDrawer();
      setActiveStep(0);
    } else {
      openNotification(
        <NotificationMessage
          type="success"
          title="User successfully updated"
          message="We’ll get this reviewed and get back to you. Enjoy!"
        />,
        true
      );
      toggleEditUserDrawer();
      setActiveStep(0);
    }
  };

  const handleUserView = (user: IUser) => {
    setUser(user);
    toggleViewUserDrawer();
  };

  const disableUser = () => {
    setOpen(false);
    openNotification(
      <NotificationMessage
        type="success"
        title={
          user.status === "Active"
            ? "User disabled successfully"
            : "User enabled successfully"
        }
        message="We’ll get this reviewed and get back to you. Enjoy!"
      />,
      true
    );
  };

  return (
    <div className="w-full h-full">
      <div className="default-page-height w-full">
        {users.length ? (
          <AllUsers
            viewUser={handleUserView}
            updateUser={handleUpdateUser}
            disableUser={handleDisableUser}
            deleteUser={handleDeleteUser}
          />
        ) : (
          <EmptyContent
            label={"You have no users yet"}
            component={
              <img
                src={QuicstockPackage}
                alt={"QuicstockPackage"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Create"}
            handlePrimaryButtonClick={() => {
              setActiveStep(0);
              toggleNewUserDrawer();
            }}
          />
        )}
      </div>

      {/* Create user */}
      <AppDrawer
        open={openNewUserDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleNewUserDrawer();
        }}
        title={"Add user"}
        subtitle={"Add a new user"}
      >
        <CreateUser
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleNewUserDrawer}
          activeStep={activeStep}
        />
      </AppDrawer>
      {/* Create user */}

      {/* Update user */}
      <AppDrawer
        open={openEditUserDrawer}
        toggle={toggleEditUserDrawer}
        title={"Edit user's details"}
        subtitle={user.name}
      >
        <UpdateUser
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleEditUserDrawer}
          activeStep={activeStep}
          user={user}
        />
      </AppDrawer>
      {/* Update user */}

      {/* View user details */}
      <AppDrawer
        open={openViewUserDrawer}
        toggle={toggleViewUserDrawer}
        title={user?.name ?? ""}
        subtitle={"Details"}
      >
        <ViewDetails user={user} />
      </AppDrawer>
      {/* View user details */}

      <Confirm
        title={user.status === "Active" ? "Disable User" : "Enable User"}
        confirmButtonText="Disable"
        open={open}
        cancel={() => setOpen(false)}
        confirm={disableUser}
      />
    </div>
  );
};

export default UserManagementTab;
