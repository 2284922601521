import Http from "../../lib/api";
import { AlertItemModel } from "../../model/alerts.model";
import { LoginModel } from "../../model/auth.model";
import { ApiPaginationModel } from "../../model/base.model";
import {
  FetchLocationParamsModel,
  LocationItemModel,
  LocationProductItemModel,
  RemoveProductFromLocationModel,
  TransferProductToAnotherLocationModel,
} from "../../model/location.model";
import {
  CreatePoRequestModel,
  PoComfirmationModel,
} from "../../model/po.model";
import {
  CustomPropertyModel,
  DeleteCustomPropertyModel,
  SellingRetailPriceRequestModel,
} from "../../model/product.model";
import { SupplierItemModel } from "../../model/supplier.model";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// authentication
export const login = (req: LoginModel) => {
  return Http.post("/auth/signin", req);
};

// location
export const createLocation = (req: LocationItemModel) => {
  return Http.post("/store/location", req);
};

export const getLocations = (params: FetchLocationParamsModel) => {
  return Http.get("/store/location", {
    params: { ...params, sort_order: "DESC" },
  });
};

export const updateLocation = (req: LocationItemModel) => {
  return Http.put(`/store/location/${req.id}`, req);
};

export const deleteLocations = (ids: string) => {
  return Http.delete(`/store/location?id=${ids}`);
};

export const getLocationProducts = (id: string) => {
  return Http.get(`/store/location/${id}/products`);
};

export const addProductToLocation = (
  id: string,
  req: LocationProductItemModel
) => {
  return Http.post(`/store/location/${id}/products`, req);
};

export const searchLocations = (searchText: string) => {
  return Http.get("/store/location/search", {
    params: { search_text: searchText },
  });
};

export const transferProduct = (req: TransferProductToAnotherLocationModel) => {
  return Http.post(`/store/location/move-product`, req);
};

export const removeProduct = (req: RemoveProductFromLocationModel) => {
  return Http.post(`/store/location/remove-product`, req);
};

// supplier
export const createSupplier = (req: SupplierItemModel) => {
  return Http.post("/store/supplier", req);
};

export const getSuppliers = (params: ApiPaginationModel) => {
  return Http.get("/store/supplier", {
    params: { ...params, sort_order: "DESC" },
  });
};

export const updateSupplier = (req: SupplierItemModel) => {
  return Http.put(`/store/supplier/${req.id}`, req);
};

export const deleteSuppliers = (ids: string) => {
  return Http.delete(`/store/supplier?id=${ids}`);
};

export const searchSuppliers = (searchText: string) => {
  return Http.get("/store/supplier", {
    params: { itemsPerPage: 100, page: 1, sort_order: "DESC" },
  });
};

// product
export const createProduct = (formData: any) => {
  return Http.post(`/store/product`, formData, config);
};

export const bulkProductUpload = (formData: any) => {
  return Http.post(`/store/product/bulk-upload`, formData, config);
};

export const getProducts = (params: ApiPaginationModel) => {
  return Http.get("/store/product", {
    params: { ...params, sort_order: "DESC" },
  });
};

export const searchProducts = (searchText: string) => {
  return Http.get("/store/product/search", {
    params: { search_text: searchText },
  });
};

export const getProduct = (id: string) => {
  return Http.get(`/store/product/${id}`);
};

export const setRetailPrice = (
  id: string,
  req: SellingRetailPriceRequestModel
) => {
  return Http.put(`/store/product/${id}/set-retail-price`, req);
};

export const createCustomProperty = (
  productId: string,
  req: CustomPropertyModel
) => {
  return Http.post(`/store/product/${productId}/custom-properties`, req);
};

export const getCustomProperties = () => {
  return Http.get(`/store/product/custom-properties`);
};

export const updateCustomProperty = (
  productId: string,
  req: CustomPropertyModel
) => {
  return Http.put(`/store/product/${productId}/custom-properties`, req);
};

export const deleteCustomProperty = (
  productId: string,
  req: DeleteCustomPropertyModel
) => {
  return Http.delete(`/store/product/${productId}/custom-properties`, {
    data: req,
  });
};

export const getProductPOs = (productId: string) => {
  return Http.get(`/store/product/${productId}/purchase-orders`);
};

export const getRestockLocations = (productId: string) => {
  return Http.get(`/store/product/${productId}/restock-locations`);
};

// purchase
export const createPo = (req: CreatePoRequestModel) => {
  return Http.post(`/store/po`, req);
};

export const getPo = (id: string) => {
  return Http.get(`/store/po/${id}`);
};

export const confirmPo = (req: PoComfirmationModel) => {
  return Http.post(`/store/po/confirm-po`, req);
};

export const getPos = (params: ApiPaginationModel) => {
  return Http.get("/store/po", {
    params: { ...params, sort_order: "DESC" },
  });
};

// alerts
export const createAlert = (payload: AlertItemModel) => {
  return Http.post(`/store/alerts`, payload);
};

export const getRestockAlerts = () => {
  return Http.get(`/store/alerts/restock-alerts`);
};

export const getReorderAlerts = () => {
  return Http.get(`/store/alerts/reorder-alerts`);
};

export const updateAlert = (payload: AlertItemModel, alertId: string) => {
  return Http.put(`/store/alerts/${alertId}`, payload);
};

export const deleteAlert = (alertId: string) => {
  return Http.delete(`/store/alerts/${alertId}`);
};
