import React, { FC } from "react";
import "../../styles.scss";
import { IUpdateUser } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { UserDetails, UserForm } from ".";

const UpdateUser: FC<IUpdateUser> = ({
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
  user,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"User Details"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <UserForm user={user} submit={submit} cancel={cancel} />
        </div>
        <div hidden={activeStep === 0}>
          <UserDetails state={"UPDATE"} user={user} goBack={goBack} complete={complete} />
        </div>
      </div>
    </div>
  );
};

export { UpdateUser };
