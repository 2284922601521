import React, { FC } from "react";
import "../../styles.scss";
import { IEditSupplier } from "../../model";
import { SupplierForm } from "./SupplierForm";

const EditSupplier: FC<IEditSupplier> = ({ submit, cancel, supplier }) => {
  return (
    <div className="w-full mt-10">
      <SupplierForm
        submit={submit}
        cancel={cancel}
        supplier={supplier}
        state={"UPDATE"}
      />
    </div>
  );
};

export { EditSupplier };
