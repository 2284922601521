import { ThunkAction } from "redux-thunk";
import { LoginModel } from "../../model/auth.model";
import { login } from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";

export const Types = {
  AUTH_START: "AUTH_START",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_END: "AUTH_END",
};

export const LoginUser = (
  req: LoginModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.AUTH_START });
    return new Promise((resolve, reject) => {
      login(req)
        .then(({ data }) => {
          dispatch({ type: Types.AUTH_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.AUTH_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};
